export const DEFAULT_RICH_TEXT_TOOLBAR = [
  [
    {
      header: 1,
    },
    {
      header: 2,
    },
    {
      header: [3,4,5],
    },
    'bold',
    'italic',
    'underline',
  ],
  ['link', 'image'],
  ['clean'],
];
