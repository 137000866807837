import { AppTheme } from '@/themes/defaultThemes';
import React, { FC } from 'react';
import get from 'lodash/get';
import styled, { StyledComponent } from 'styled-components';
import { ViewProps } from '..';
import { RedeemWidget } from './types';

const TextViewContainer: StyledComponent<'div', AppTheme> = styled.div`
  background-color: ${(props: any) => props.backgroundColor};
  padding: 8px 16px;
  img {
    width: 100%;
  }
`;

export interface RedeemViewProps extends ViewProps<RedeemWidget> {}

const RedeemView: FC<RedeemViewProps> = ({ widget: { payload: { mobileImage } } }) => {
  return <TextViewContainer>
    {mobileImage ? <img src={get(mobileImage, '[0].url')} /> : 'Redeem Widget'}
  </TextViewContainer>;
};

export default RedeemView;
