import {
  Campaign,
  CampaignFormData,
  CampaignsRequest,
  CampaignsRequestData,
  CreateCampaignRequest,
  UpdateCampaignRequest,
} from '@/infras/campaign/typing';
import { ListProps } from '@/typings/list';
import { UploadFile } from 'antd/lib/upload/interface';
import { isString } from 'lodash';
import { prefixCampaignUrl } from './util';

export const mapMetaImageToImgFile = (campaign: Campaign): UploadFile => {
  return {
    uid: '-1',
    name: campaign.name,
    status: 'done',
    url: campaign.metaImage,
    type: '',
    size: 200,
  };
};
export const mapDataToCreateCampaignReq = (campaign: CampaignFormData): CreateCampaignRequest => {
  return {
    name: campaign.name,
    type: campaign?.type || null,
    metaTitle: campaign.metaTitle,
    metaDescription: campaign.metaDescription,
    metaImage: campaign?.metaImage,
    url: campaign.url,
    createdBy: campaign.createdBy!,
  };
};
export const mapDataCampaignsReq = (request: CampaignsRequestData): CampaignsRequest => {
  if (!request) return { limit: 10, page: 1 };
  const filter = request?.filter
    ? { [request.filter.key]: request.filter.value.replace(prefixCampaignUrl, '') }
    : {};
  return {
    limit: request.limit,
    page: request.page,
    ...filter,
  };
};

export const mapDataToUpdateCampaignReq = (campaign: CampaignFormData): UpdateCampaignRequest => {
  return {
    ...campaign,
    metaImage: isString(campaign.metaImage) ? campaign.metaImage : campaign.metaImage?.[0],
  };
};
export const mapApiDataToCampaignsRes = (data: any): ListProps<any> => {
  return {
    data: data.items,
    total: data.meta.totalItems,
  };
};
