import Box from '@/components/Box';
import Delete from '@/components/CustomIcon/Delete';
import { NgonQuickLinkFormData } from '@/infras/ngon-quick-link/typing';
import { deleteNgonQuickLinkHook } from '@/infras/ngon-quick-link/useCase';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import UpdateForm from '../QuickLinkForm/UpdateForm';
import DeleteModalContent from './DeleteModalContent';

interface IThemeOperating {
  theme: NgonQuickLinkFormData;
}

const ThemeOperating = ({ theme }: IThemeOperating | any) => {
  const { id, _id } = theme;

  const [deleteModalVisible, handleDeleteModalVisible] = useState(false);

  const { fetch: deleteNgonQuickLink } = deleteNgonQuickLinkHook();

  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false);

  const openUpdateModal = useCallback(() => {
    handleUpdateModalVisible(true);
  }, []);
  const handleVisibleModal = useCallback((visible) => {
    handleUpdateModalVisible(visible);
  }, []);

  const openDeleteModal = () => {
    handleDeleteModalVisible(true);
  };

  const handleDelete = () => {
    deleteNgonQuickLink({ id: (id as string | number) || _id });
    handleDeleteModalVisible(false);
  };

  const closeDeleteModal = () => {
    handleDeleteModalVisible(false);
  };

  return (
    <Box display="flex">
      <Box mr="5px">
        <Tooltip title="Chỉnh sửa">
          <Button shape="circle" icon={<EditOutlined />} onClick={openUpdateModal} />
          <UpdateForm
            theme={theme}
            modalVisible={updateModalVisible}
            handleVisibleModal={handleVisibleModal}
          />
        </Tooltip>
      </Box>

      <Box>
        <Tooltip title="Xóa">
          <Button
            onClick={openDeleteModal}
            icon={<Delete />}
            style={{ borderRadius: '50%', borderColor: '#FF424F' }}
          />
        </Tooltip>
      </Box>

      <Modal
        key="delete"
        visible={deleteModalVisible}
        centered
        width={400}
        bodyStyle={{ borderRadius: 8, padding: 16 }}
        footer={null}
        onCancel={closeDeleteModal}
        closable={false}
      >
        <DeleteModalContent onCancel={closeDeleteModal} onConfirm={handleDelete} />
      </Modal>
    </Box>
  );
};

export default ThemeOperating;
