import Config from '@/config';
import { Campaign } from '@/infras/campaign/typing';

export const prefixCampaignUrl = `${Config.TikiFrontend}/`;

export const getCampaignUrl = (campaign: Campaign, pageId?: string, preview?: boolean) => {
  if (!campaign?.url) {
    return '';
  }
  return `${prefixCampaignUrl}${campaign?.url}${preview ? `?preview=1&page_id=${pageId}` : ''}`;
};

export const typeOf = (value: any) => Object.prototype.toString.call(value).slice(8, -1);
export const getSortQueryMapper = (sort: any) => {
  const sortQuery = typeOf(sort) === 'String' ? [sort] : sort;

  return (sortQuery || []).reduce((res: {}, s: string) => {
    const [key, value] = (s || '').split(',');
    res[key] = value;
    return res;
  }, {});
};
