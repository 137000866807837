import {
  CreateStoreCollectionRequest,
  StoreCollection,
  StoreCollectionRequest,
  UpdateStoreCollectionRequest,
} from '@/infras/store/typing';
import { apiRequest } from '@/utils/request';
import request from 'umi-request';
import Config from '@/config';

export async function getStoreCollections(req: any): Promise<any> {
  const filter: any = {
    page: req?.filter?.page || 1,
    limit: req?.filter?.limit || 10,
  };
  if (req?.filter?.key) {
    filter[req.filter.key] = req.filter.value;
  }
  const data: any = await apiRequest(`/store-collections`, {
    params: filter,
  });
  return data;
}

export async function createStoreCollection(
  req: CreateStoreCollectionRequest,
): Promise<StoreCollection> {
  const data: any = await apiRequest(`/store-collections`, {
    method: 'POST',
    data: req,
  });

  return data;
}

export async function updateStoreCollection(category: UpdateStoreCollectionRequest) {
  return apiRequest(`/store-collections/${category.id}`, {
    method: 'PUT',
    data: category,
  });
}

export async function getStoreCollection(req: StoreCollectionRequest): Promise<any> {
  const data: any = await apiRequest(`/store-collections/${req.storeCollectionId}`);

  return data;
}

export function fetchNgonCategories({ parentId = 2 }) {
  return request.get<{ data: any[] }>(`${Config.TikiAPI}/categories`, {
    params: {
      limit: 100,
      parent_id: parentId,
      is_active: 1,
    },
  });
}
