import { configsContext } from '@/infras/config/constants';
import {
  Config,
  ConfigFormData,
  ConfigsRequestData,
  ConfigsResponse,
  ConfigState,
} from '@/infras/config/typing';
import {
  createConfigAction,
  getConfigAction,
  getConfigsAction,
  resetConfigRequestAction,
  updateConfigAction,
} from '@/infras/config/useCase';
import configsServices from '@/services/config';
import { ACTION_STATUS } from '@/typings/redux';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';

const initState = {
  configMap: {},
  configList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: 'name',
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, configsContext);

builder
  .immer(getConfigAction.success, (state: ConfigState, { request, response }: ConfigsResponse) => {
    state.configMap = { ...state.configMap, [response?.id]: response };

    if (request) {
      state.request = { ...state.request, ...request };
    }
    return state;
  })
  .takeLatest<any>(getConfigAction.execute, function* (
    request: ConfigsRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(configsServices.getConfig, request);
      console.log(response, 'response');
      yield put(getConfigAction.success({ request, response }));
    } catch (error) {
      yield put(getConfigAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createConfigAction.execute, function* (
    payload: ConfigFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      yield call(configsServices.createConfig, payload);
      yield put(createConfigAction.success());
      yield put.resolve(getConfigsAction.execute());
    } catch (err) {
      yield put(createConfigAction.fail(err));
    }
  })
  .immer(createConfigAction.execute, (state: ConfigState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })
  .immer(createConfigAction.success, (state: ConfigState) => {
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(createConfigAction.fail, (state: ConfigState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

builder.immer(resetConfigRequestAction, (state: ConfigState) => {
  state.status = ACTION_STATUS.INITIAL;
  state.error = null;
  return state;
});

builder

  .takeLatest<any>(updateConfigAction.execute, function* (
    payload: ConfigFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(configsServices.updateConfig, payload);
      yield put(updateConfigAction.success(resp));
    } catch (error) {
      yield put(updateConfigAction.fail(error));
    }
  })
  .immer(updateConfigAction.success, (state: ConfigState, config: Config) => {
    const { id } = config;
    state.configMap = { ...state.configMap, [id]: config };
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(updateConfigAction.execute, (state: ConfigState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })

  .immer(updateConfigAction.fail, (state: ConfigState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

export default builder.build();
