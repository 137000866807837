import { useCallback, useEffect, useState } from 'react';
import { isMultipleSelect } from '@/utils';

export default () => {
  const [showDeleteMultiConfirm, setShowDeleteMultiConfirm] = useState<boolean>(false);
  const [selectedDraggingIds, setSelectedDraggingIds] = useState<any[]>([]);
  const [draggingId, setDraggingId] = useState<any>(null);
  const unSelectAll = useCallback(() => {
    setSelectedDraggingIds([]);
  }, []);

  const selectSingleItem = useCallback((id: any) => {
    setSelectedDraggingIds([id]);
  }, []);

  const selectMultiItems = useCallback((id: any) => {
    setSelectedDraggingIds((prevState) => {
      if (!prevState.includes(id)) {
        return [...prevState, id];
      }
      return prevState.filter((i) => i !== id);
    });
  }, []);

  const onWindowKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === 'Escape') {
        unSelectAll();
      }

      if (selectedDraggingIds.length > 1 && (event.key === 'Delete' || event.key === 'Backspace')) {
        setShowDeleteMultiConfirm(true);
      }
    },
    [selectedDraggingIds, unSelectAll],
  );

  const onWindowClick = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      if (isMultipleSelect(event)) {
        return;
      }
      unSelectAll();
    },
    [unSelectAll],
  );

  const onWindowTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      unSelectAll();
    },
    [unSelectAll],
  );

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
    return () => {
      // @ts-ignore
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  });

  return {
    selectedDraggingIds,
    setSelectedDraggingIds,
    selectSingleItem,
    selectMultiItems,
    draggingId,
    setDraggingId,
    showDeleteMultiConfirm,
    setShowDeleteMultiConfirm,
  };
};
