import { message } from 'antd';
import { isEmpty } from 'lodash';

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const resizeImg = (img: string, width: number) => {
  const imageReplaced = img.replace('http://uat.cdn.tiki.services', 'https://uat.tikicdn.com');
  if (imageReplaced.indexOf('/ts/') !== -1 && imageReplaced.indexOf('.jpg') !== -1) {
    const arr = imageReplaced.split('/ts/');
    return `${arr[0]}/cache/w${width}/ts/${arr[1]}`;
  }
  return imageReplaced;
};

export function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = url;
  });
}

const validateImageDimension = (file: any, dimensionRule?: [number, number]) => {
  const _URL = window.URL || window.webkitURL;
  const objectUrl = _URL.createObjectURL(file);

  const promise = new Promise((resolve, reject) => {
    if (!isEmpty(dimensionRule)) {
      const [width, height]: any = dimensionRule;
      if (width && height) {
        const img = new Image();
        img.onload = () => {
          const naturalWidth = img.naturalWidth;
          const naturalHeight = img.naturalHeight;
          if (naturalWidth / naturalHeight !== width / height) {
            message.error(`Hình không đúng với kích thước đề xuất: ${width}px x ${height}px`);
            resolve(false);
          } else {
            resolve(true);
          }

          _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
    } else {
      resolve(true);
    }
  });

  return promise;
};

export const validateImageFile = async (
  file: any,
  dimensionRule?: [number, number],
  fileTypeRule?: string[],
) => {
  const isJpgOrPng = fileTypeRule?.length
    ? fileTypeRule.includes(file.type)
    : file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif';
  if (!isJpgOrPng) {
    if (fileTypeRule?.length) {
      message.error(
        `You can only upload ${
          fileTypeRule.length === 1 ? fileTypeRule[0] : fileTypeRule.join('/')
        } file!`,
      );
      return false;
    }
    message.error('You can only upload JPG/PNG/GIF file!');
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
    return false;
  }

  const isValidDimension = await validateImageDimension(file, dimensionRule);

  return isValidDimension;
};
