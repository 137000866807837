import { apiRequest } from '@/utils/request';
import { CollectionForm } from '@/infras/collection/typing';
import { CollectionPage } from '@/infras/collection-page/typing';
import { CollectionPageType } from '@/infras/collection-page/constants';

export async function createCollection({
  collection,
  collectionPage,
  skus,
  skusFileName,
}: {
  collection: CollectionForm;
  collectionPage: CollectionPage;
  skus: any;
  skusFileName: any;
}) {
  const { name, description } = collection;
  const { slug, ogImage, og_image } = collectionPage;
  const collectionPageId = collectionPage.id;

  const params: any = {};
  if (collectionPage.pageType === CollectionPageType.TikiNGON) {
    params.isFresh = true;
  }

  return apiRequest(`/collections/${collectionPageId}`, {
    method: 'POST',
    params,
    data: {
      name,
      description,
      url: slug || collectionPage.url,
      status: 1,
      thumbnail: ogImage || og_image,
      sellerId: 0,
      skus,
      skusFileName,
    },
  });
}

export async function updateCollection({
  collection,
  collectionPage,
}: {
  collection: CollectionForm;
  collectionPage: CollectionPage;
  // skusFileName: any;
}) {
  const { name, description, skus } = collection;
  const { slug, ogImage, og_image } = collectionPage;
  const { id, cid } = collectionPage;
  const payload: any = {
    name,
    description,
    url: slug || collectionPage.url,
    status: 1,
    thumbnail: ogImage || og_image,
    sellerId: 0,
  };
  if (skus?.length) {
    payload.skus = skus;
  }
  const params: any = {};
  if (collectionPage.pageType === CollectionPageType.TikiNGON) {
    params.isFresh = true;
  }

  return apiRequest(`/collections/${id}/collection/${cid}`, {
    method: 'PUT',
    params,
    data: payload,
  });
}

export async function addProductsToCollection({
  collectionPage,
}: {
  collectionPage: CollectionPage;
}): Promise<any> {
  const data = await apiRequest(`/collections/${collectionPage.id}/cid/${collectionPage.cid}`, {
    method: 'POST',
    data: { product_skus: collectionPage?.skus },
  });
  return data;
}

export async function getCollection(req: {
  campaignId: string;
  collectionId: string;
}): Promise<any> {
  const data = await apiRequest(`/collections/${req.collectionId}`);
  return data;
}
