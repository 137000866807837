import { PREFIX_SLUG } from '@/components/SlugInput/constants';
import Config from '@/config';
import { NgonThemeFormData } from '@/infras/ngon-theme/typing';

export const getThemeCollectionCount = (collectionIds: string) => {
  try {
    if (!collectionIds || !collectionIds.trim()) return 0;

    return collectionIds.split(',').length;
  } catch (err) {
    return 0;
  }
};

export const getThemeUrl = (theme: NgonThemeFormData) => {
  const { slug } = theme;

  return `${Config.TikiFrontend}/${PREFIX_SLUG.NGON_THEME}/${slug}`;
};
