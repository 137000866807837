import Config from '@/config';
import cookieService from '@/services/cookie';
import nanoid from '@/utils/nanoid';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { isUrl } from '@umijs/route-utils/dist/transformRoute/transformRoute';
import { message, Modal, Tooltip, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib/upload';
import update from 'immutability-helper';
import { filter, isEmpty, isString, map, noop } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { DragAndDropProvider } from '../DragAndDrop';
import { getBase64 } from './util';

interface ImagesUploadProps extends UploadProps {
  value?: string[] | any[];
  onChange?: (value: any) => void;
  onImgClick?: (value: any) => void;
  maxImage?: number;
  canDragSort?: boolean;
  isReturnUrl?: boolean;
  handleImageSelected?: (uid: string) => boolean;
}
export const mapUrlImageToImgFile = (serverData: any): UploadFile => {
  return {
    uid: serverData?.uid || nanoid(),
    name: serverData?.name || serverData?.originalName || '',
    status: 'done',
    url: isUrl(serverData?.url) ? serverData?.url : '',
    type: '',
    size: 200,
  };
};

const StyledImageUploadItem = styled.div<{ isActive: boolean }>`
  & .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
    padding: 0;
  }

  &&.ant-upload-list-item {
    border: 1px solid #d9d9d9;
    border-color: ${({ isActive }) => (isActive ? '#40a9ff' : '#d9d9d9')};
    transition: all 0.3s ease;
    box-shadow: ${({ isActive }) => isActive && '0 0 0 2px rgba(24, 144, 255, 0.08)'};
    cursor: move;
  }
`;

const type = 'DraggableUploadList';

const DraggableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
  handleImageSelected = noop,
  onClick = noop,
}: any) => {
  const ref: any = React.useRef();
  const index = fileList.indexOf(file);
  const [, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error" getPopupContainer={() => document.body}>
      {originNode.props.children}
    </Tooltip>
  );
  const imageIsActive = handleImageSelected(file.uid);
  const onItemClick = useCallback(() => {
    onClick(file);
  }, [onClick, file]);
  return (
    <StyledImageUploadItem
      ref={ref}
      className="ant-upload-list-item"
      isActive={imageIsActive}
      onClick={onItemClick}
    >
      {file.status === 'error' ? errorNode : originNode}
    </StyledImageUploadItem>
  );
};

const token = cookieService.getUserToken();
const formatImages = (value: any) => {
  if (isEmpty(value)) return [];
  return isString(value)
    ? [mapUrlImageToImgFile({ url: value })]
    : map(value, (img: string) => mapUrlImageToImgFile(isString(img) ? { url: img || '' } : img));
};

const ImagesUpload: FC<ImagesUploadProps> = ({
  value,
  onChange = noop,
  maxImage = 1,
  action: actionProps = '',
  isReturnUrl = false,
  handleImageSelected,
  onImgClick = noop,
  ...props
}) => {
  const [images, setImages] = useState<any>(formatImages(value));
  const [loading, setLoading] = useState(false);
  const [previewInfo, setPreviewInfo] = useState({
    visible: false,
    previewImage: '',
    previewTitle: '',
  });

  React.useEffect(() => {
    setImages(formatImages(value));
  }, [value]);

  const handleChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    if (file.status === 'uploading') {
      setLoading(true);
      // add file list.= to fix  will only trigger when the file is in the list
      const newImages = map(fileList, (fileItem: any) =>
        mapUrlImageToImgFile({ ...(fileItem?.response ?? fileItem) }),
      ).slice(0, maxImage);
      setImages(newImages);
      return;
    }
    if (file.status === 'done') {
      const newImages = map(fileList, (fileItem: any) =>
        mapUrlImageToImgFile({ ...(fileItem?.response ?? fileItem) }),
      ).slice(0, maxImage);
      // Get this url from response in real world.
      setLoading(false);
      if (!file || !file?.response?.url) {
        setImages(newImages);
        onChange(newImages);
        return;
      }

      if (file?.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }

      setImages(newImages);
      onChange(isReturnUrl ? map(newImages, 'url') : newImages);
    }
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewInfo({
      visible: true,
      previewImage: file.url || file.preview,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return false;
    }

    return isJpgOrPng;
  };
  const handleCancelReview = () => {
    setPreviewInfo({
      visible: false,
      previewImage: '',
      previewTitle: '',
    });
  };
  const uploadButton = () => (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onRemove = (file: UploadFile) => {
    // Get this url from response in real world.

    setLoading(false);
    const newImages = filter(images, (fileItem) => file.uid !== fileItem.uid);
    setImages(newImages);
    onChange(newImages);
    return true;
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = images[dragIndex];
      const data = update(images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setImages(data);
      onChange(data);
    },
    [images, onChange],
  );

  return (
    <DragAndDropProvider>
      <Upload
        action={actionProps || `${Config.BrickAPI}/upload`}
        listType="picture-card"
        headers={{ Authorization: `Basic ${token}` }}
        fileList={images}
        onChange={handleChange}
        multiple={maxImage > 1}
        onPreview={handlePreview}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
        itemRender={(originNode, file, currFileList) => {
          return (
            <DraggableUploadListItem
              originNode={originNode}
              onClick={onImgClick}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
              handleImageSelected={handleImageSelected}
            />
          );
        }}
        {...props}
      >
        {images.length < maxImage && uploadButton()}
      </Upload>
      <Modal
        visible={previewInfo.visible}
        title={previewInfo.previewTitle}
        footer={null}
        onCancel={handleCancelReview}
      >
        <img
          alt="example"
          style={{ width: '100%', display: 'flex', margin: 'auto' }}
          src={previewInfo.previewImage}
        />
      </Modal>
    </DragAndDropProvider>
  );
};
export default ImagesUpload;
