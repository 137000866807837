import QuillTextInput from '@/components/quill/QuillTextInput2';
import { Form } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { EditSettingsProps } from '..';
import NameFieldItemSettings from '../shared/NameFieldItem';
import TightFormItem from '../shared/TightFormItem.styled';
import { CmsRichTextWidget } from './types';

const QuillFormItem = styled(Form.Item)`
  flex-flow: column nowrap;

  [contenteditable] {
    caret-color: black;
  }

  .ql-container {
    height: 270px;
  }

  .ql-editor {
    * {
      color: inherit;
    }
  }
  .ql-snow .ql-editor {
    a {
      color: #40a9ff;
      text-decoration: underline;

      &:hover {
        color: #40a9ff;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      height: unset !important;
      max-width: 100%;
    }
  }
`;

const RichTextWidgetSettings: FC<EditSettingsProps<CmsRichTextWidget>> = ({ widget }) => {
  return (
    <TightFormItem labelAlign="left">
      <h2>Rich Text</h2>
      <NameFieldItemSettings widgetId={widget.id || ''} />
      <QuillFormItem
        name="text"
        label="Nội dung"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <QuillTextInput />
      </QuillFormItem>
    </TightFormItem>
  );
};

export default RichTextWidgetSettings;
