import Box from '@/components/Box';
import Copy from '@/components/CustomIcon/Copy';
import Success from '@/components/CustomIcon/Success';
import { NgonThemeFormData } from '@/infras/ngon-theme/typing';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { Link } from 'umi';
import { getThemeUrl } from '../utils';

interface IThemeInfo {
  theme: NgonThemeFormData;
}

const ThemeInfo = ({ theme }: IThemeInfo) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" maxWidth={600} wordBreak="break-all">
        <Link
          to={`/ngon/${theme.id}`}
          style={{ fontSize: 14, lineHeight: '20px', color: '#0B74E5' }}
        >
          {theme.name}
        </Link>
        <Paragraph copyable={{ icon: [<Copy />, <Success />], tooltips: 'Sao chép' }}>
          {getThemeUrl(theme)}
        </Paragraph>
      </Box>
    </Box>
  );
};

export default ThemeInfo;
