import { apiRequest } from '@/utils/request';
import { Widget } from '@/infras/widget/typing';

async function createWidget(widget: Widget) {
  return apiRequest(`/widgets`, {
    method: 'POST',
    data: { ...widget, payload: JSON.stringify(widget.payload) },
  });
}

async function updateWidget(widget: Widget) {
  await apiRequest(`/widgets/${widget.id}`, {
    method: 'PUT',
    data: { ...widget, payload: JSON.stringify(widget.payload) },
  });
  return widget;
}

async function deleteWidget(id: string) {
  return apiRequest(`/widgets/${id}`, {
    method: 'DELETE',
  });
}

async function deleteMultiWidget(ids: string[]) {
  return apiRequest(`/widgets/multi`, {
    method: 'DELETE',
    data: ids,
  });
}

async function getWidget(req: { campaignId: string; widgetId: string }): Promise<any> {
  const data = await apiRequest(`/widgets/${req.widgetId}`);
  return data;
}
export default {
  createWidget,
  updateWidget,
  deleteWidget,
  getWidget,
  deleteMultiWidget,
};
