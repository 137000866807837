import {
  CategoriesRequestData,
  CategoriesResponse,
  CategoriesState,
  Category,
  CategoryFormData,
  CategoryRequest,
  CategoryUpdateFormData,
} from '@/infras/category/typing';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { CategoriesContext } from '@/infras/category/constants';
import {
  createCategoryAction,
  getCategoriesAction,
  getCategoryAction,
  updateCategoryAction,
} from '@/infras/category/useCase';
import { getListMapData } from '@/utils';
import categoryService from '@/services/categories';
import {
  mapDataCategoryReq,
  mapDataToCreateCategoryReq,
  mapDataToUpdateCategoryReq,
} from '@/pages/categories/mapper';

import { getPages } from '@/infras/page/useCase';

const initState: CategoriesState = {
  categoryMap: {},
  categoryList: {
    data: [],
    total: 0,
  },
  request: {
    page: 1,
    limit: 10,
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, CategoriesContext);

builder
  .immer(
    getCategoriesAction.success,
    (state: CategoriesState, { request, response }: CategoriesResponse) => {
      const { mapData: categoryMap, list: categoryList } = getListMapData(response.data);
      state.categoryMap = { ...state.categoryMap, ...categoryMap };
      state.categoryList = {
        data: categoryList,
        total: response.total,
      };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getCategoriesAction.execute, function* (
    request: CategoriesRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(categoryService.getCategories, mapDataCategoryReq(request));
      yield put(getCategoriesAction.success({ request, response }));
    } catch (error) {
      yield put(getCategoriesAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createCategoryAction.execute, function* (
    payload: CategoryFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      console.log(payload, 'payload payload');
      const response = yield call(
        categoryService.createCategory,
        mapDataToCreateCategoryReq(payload),
      );
      yield put(createCategoryAction.success(response));
      yield put.resolve(getCategoriesAction.execute(payload as any));
      return response;
    } catch (err) {
      yield put(createCategoryAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createCategoryAction.execute, (state: CategoriesState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createCategoryAction.success, (state: CategoriesState) => {
    state.status = 'resolved';
    return state;
  })
  .immer(createCategoryAction.fail, (state: CategoriesState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder
  .immer(updateCategoryAction.success, (state: CategoriesState, category: Category) => {
    const { id } = category;
    state.categoryMap = { ...state.categoryMap, [id]: category };

    return state;
  })
  .takeLatest<any>(updateCategoryAction.execute, function* (
    payload: CategoryUpdateFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(categoryService.updateCategory, mapDataToUpdateCategoryReq(payload));
      yield put(updateCategoryAction.success(resp));
    } catch (error) {
      yield put(updateCategoryAction.fail(error));
    }
  });

builder
  .immer(
    getCategoryAction.success,
    (
      state: CategoriesState,
      { request, response }: { request: CategoryRequest; response: Category },
    ) => {
      state.categoryMap[request.categoryId] = response;

      return state;
    },
  )
  .takeLatest<any>(getCategoryAction.execute, function* (
    request: CategoryRequest,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const category = yield call(categoryService.getCategory, request);
      const { mapData: pageMap, list: pageList } = getListMapData(category.pages);

      yield put(getPages({ response: pageMap }));
      yield put(getCategoryAction.success({ request, response: { ...category, pages: pageList } }));
    } catch (err) {
      yield put(getCategoryAction.fail(err));
    }
  });

export default builder.build();
