import { RuntimeConfig } from './types';

const devConfig: RuntimeConfig = {
  TikiFrontend: 'https://beta.tala.xyz',
  OAuthEndpoint: 'https://api.tala.xyz/oauth2',
  BrickAPI: 'https://api.tala.xyz/brickv2/api',
  SpectrumAPI: 'https://api.tiki.vn/spectrum',
  SOAPI: 'http://localhost:4000',
  SOPath: '',
  BrickAppDomain: 'localhost',
  TikiAPI: 'https://tiki.vn/api/v2',
  APIGateway: 'https://api.tala.xyz',
  TequilaAPI: 'https://api.tala.xyz/tequila/v1/consumer',
  OAuthBasicToken: 'dGlraS1idWlsZGVyLXVpOmN0SjRYNGV5enFaUQ==',
  SentryDSN: 'https://ec5572c3181b495ea817a545f029f2fe@o209760.ingest.sentry.io/5985826',
  NewAPIUrl: 'https://api.tala.xyz',
};

export default devConfig;
