import {
  handleExecuteCreateCPage,
  handleExecuteGetListCPages,
  handleExecuteGetOneCPage,
  handleExecuteUpdateOneCPage,
} from '@/infras/widget-collection-page/handlers';
import {
  changeIndexWidgetCollectionPageAction,
  changeStatusWidgetCollectionPageAction,
  createWidgetCollectionPageAction,
  deleteWidgetCollectionPageAction,
  getWidgetCollectionPageAction,
  getWidgetCollectionPagesAction,
  resetWidgetCollectionPageRequestAction,
  updateWidgetCollectionPageAction,
} from '@/infras/widget-collection-page/useCase';
import { FILTER_KEY } from '@/pages/widget-collection/components/CollectionPageFilter';
import {
  changeStatusWidgetCollectionPage,
  deleteWidgetCollectionPage,
  updateWidgetCollectionPageIndex,
} from '@/services/widgetCollectionPage';
import { getListMapData } from '@/utils';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { collectionPagesContext } from '../infras/widget-collection-page/constants';
import {
  WidgetCollectionPage,
  WidgetCollectionPageRequest,
  WidgetCollectionPagesResponse,
  WidgetCollectionPageState,
} from '../infras/widget-collection-page/typing';

const initState: WidgetCollectionPageState = {
  collectionPageMap: {},
  collectionPageList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 20,
    filter: {
      key: FILTER_KEY.NAME,
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, collectionPagesContext);

builder
  // Handle get list collection pages
  .takeLatest<any>(getWidgetCollectionPagesAction.execute, handleExecuteGetListCPages)
  .immer(
    getWidgetCollectionPagesAction.success,
    (state: WidgetCollectionPageState, { request, response }: WidgetCollectionPagesResponse) => {
      const { mapData: collectionPageMap, list: collectionPageList } = getListMapData(
        response.data,
      );
      state.collectionPageMap = { ...state.collectionPageMap, ...collectionPageMap };
      state.collectionPageList = { data: collectionPageList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  );

builder
  // Handle create one collection page
  .takeLatest<any>(createWidgetCollectionPageAction.execute, handleExecuteCreateCPage)
  .immer(createWidgetCollectionPageAction.execute, (state: WidgetCollectionPageState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createWidgetCollectionPageAction.success, (state: WidgetCollectionPageState) => {
    state.status = 'resolved';
    return state;
  })
  .immer(
    createWidgetCollectionPageAction.fail,
    (state: WidgetCollectionPageState, error: Error) => {
      state.status = 'rejected';
      state.error = error;
      return state;
    },
  );

builder.immer(resetWidgetCollectionPageRequestAction, (state: WidgetCollectionPageState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  // Handle update one collection page
  .takeLatest<any>(updateWidgetCollectionPageAction.execute, handleExecuteUpdateOneCPage)
  .immer(
    updateWidgetCollectionPageAction.success,
    (state: WidgetCollectionPageState, collectionPage: WidgetCollectionPage) => {
      const { id } = collectionPage;
      state.collectionPageMap = { ...state.collectionPageMap, [id]: collectionPage };

      return state;
    },
  );

builder
  // Handle update one collection page
  .takeLatest<any>(changeStatusWidgetCollectionPageAction.execute, function* (
    { id, status }: { id: string; status: 0 | 1 },
    { call, put }: EffectsCommandMap,
  ) {
    try {
      yield call(changeStatusWidgetCollectionPage, id, status);

      yield put(changeStatusWidgetCollectionPageAction.success({ id, status }));
    } catch (error) {
      yield put(changeStatusWidgetCollectionPageAction.fail(error));
    }
  })
  .immer(
    changeStatusWidgetCollectionPageAction.success,
    (state: WidgetCollectionPageState, collectionPage: { id: string; status: 0 | 1 }) => {
      console.log(collectionPage, 'collectionPage');
      const { id, status } = collectionPage;
      state.collectionPageMap = {
        ...state.collectionPageMap,
        [id]: { ...state.collectionPageMap[id], status },
      };

      return state;
    },
  );

builder
  // Handle update one collection page
  .takeLatest<any>(changeIndexWidgetCollectionPageAction.execute, function* (
    req: any,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      yield call(updateWidgetCollectionPageIndex, req);

      yield put(changeIndexWidgetCollectionPageAction.success());
    } catch (error) {
      yield put(changeIndexWidgetCollectionPageAction.fail(error));
    }
  });

builder
  // Handle get one collection page
  .takeLatest<any>(getWidgetCollectionPageAction.execute, handleExecuteGetOneCPage)
  .immer(
    getWidgetCollectionPageAction.success,
    (
      state: WidgetCollectionPageState,
      {
        request,
        response,
      }: { request: WidgetCollectionPageRequest; response: WidgetCollectionPage },
    ) => {
      state.collectionPageMap[request.collectionPageId] = response;

      return state;
    },
  );

builder.takeLatest<any>(deleteWidgetCollectionPageAction.execute, function* (
  { id }: { id: string; pageId: string; widgets: string[] },
  { call, put }: EffectsCommandMap,
) {
  try {
    yield call(deleteWidgetCollectionPage, id);
    // update widgets index after deletion

    yield put(deleteWidgetCollectionPageAction.success());
    yield put(getWidgetCollectionPagesAction.execute());
  } catch (error) {
    yield put(deleteWidgetCollectionPageAction.fail(error));
  }
});
export default builder.build();
