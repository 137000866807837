import Quill from 'quill';
import { apiRequest } from '../../utils/request';

export async function handleUpload(image: File) {
  const data = new FormData();
  data.append('file', image);

  return apiRequest('/upload', {
    method: 'POST',
    data,
  }).then((resp: any) => {
    return resp.url;
  });
}

const _URL = window.URL || window.webkitURL;
export async function handleSize(image: File) {
  return new Promise((resolve, reject) => {
    const imgEle = new Image();
    const objectUrl = _URL.createObjectURL(image);
    imgEle.onload = () => {
      // Compute ratio based on image width & height
      const ratio = imgEle.height / imgEle.width;

      // clip width at max width on desktop
      const width = Math.min(imgEle.width, 1240);
      const height = Math.round(width * ratio);
      resolve({
        width,
        height,
      });

      _URL.revokeObjectURL(objectUrl);
    };
    imgEle.onerror = reject;

    imgEle.src = objectUrl;
  });
}

export function imageHandler(this: any) {
  let fileInput: HTMLInputElement | null = this.container.querySelector(
    'input.ql-image[type=file]',
  );

  if (fileInput == null) {
    fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
    fileInput.classList.add('ql-image');
    fileInput.addEventListener('change', async () => {
      if (!fileInput) return;
      const { files } = fileInput;
      const range = this.quill.getSelection(true);

      if (!files || !files.length) {
        // No file selected, return
        return;
      }

      const formData = new FormData();
      formData.append('file', files[0]);

      this.quill.enable(false);

      try {
        const [size, url]: any = await Promise.all([handleSize(files[0]), handleUpload(files[0])]);

        this.quill.enable(true);
        this.quill.editor.insertEmbed(range.index, 'image', url, 'api');
        this.quill.formatText(range.index, range.index + 1, 'width', `${size.width}px`);
        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
        this.quill.emitter.emit('text-change');
        if (fileInput) {
          fileInput.value = '';
        }
      } catch (err) {
        console.error(err);
        this.quill.enable(true);
      }
    });
    this.container.appendChild(fileInput);
  }
  fileInput.click();
}
