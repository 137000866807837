import { BannerSettings, BannerType } from '@/components/widgets/banner/types';
import { CouponHubType } from '@/components/widgets/coupon-hub/types';
import { CouponSettings } from '@/components/widgets/coupon/types';
import { ASACouponSettings } from '@/components/widgets/asa-coupon/types';
import { SellerCouponSettings } from '@/components/widgets/seller-coupon/types';
import { FlashDealSettings } from '@/components/widgets/flash-deal/types';
import { FormType } from '@/components/widgets/form/types';
import { StickyPosition } from '@/components/widgets/navigation/types';
import { NormalDealSettings } from '@/components/widgets/normal-deal/types';
import { PersonalizeLayout, PersonalizeType } from '@/components/widgets/personalize/constants';
import { PersonalizeSettings } from '@/components/widgets/personalize/types';
import { PopupSettings } from '@/components/widgets/popup/types';
import { ProductSettings, ProductType } from '@/components/widgets/product/types';
import { TabTitle, TabType } from '@/components/widgets/shared/product-normal-deal/types';
import { SliderSettings, SliderType } from '@/components/widgets/slider/types';
import { TimerSettings } from '@/components/widgets/timer/types';
import { VideoSettings } from '@/components/widgets/video/types';
import { WayPointSettings } from '@/components/widgets/way-point/types';
import redeemDefinition from '@/components/widgets/redeem';
import categoryBrandDefinition from '@/components/widgets/category-brand';
import textWidgetDefinition from '@/components/widgets/text';
import categorySliderDefinition from '@/components/widgets/category-collection';
import richTextWidget from '@/components/widgets/rich-text';
import cmsRichTextWidget from '@/components/widgets/cms-rich-text';
import { WidgetType } from '@/infras/widget/constants';

export const widgetProps: any = {
  [WidgetType.PRODUCT]: {
    type: WidgetType.PRODUCT,
    payload: {
      tabTitleType: TabTitle.IMG,
      productType: ProductType.PRODUCT_LISTING,
      type: TabType.SINGLE,
      color: 'black',
      row: 1,
      tabs: [],
      backgroundColor: 'transparent',
    } as Partial<ProductSettings>,
  },
  [WidgetType.NORMAL_DEAL]: {
    type: WidgetType.NORMAL_DEAL,
    payload: {
      backgroundColor: 'transparent',
      color: 'black',
      tabTitleType: TabTitle.IMG,
      type: TabType.SINGLE,
      row: 1,
      tabs: [],
    } as Partial<NormalDealSettings>,
  },
  [WidgetType.FLASH_DEAL]: {
    type: WidgetType.FLASH_DEAL,
    payload: {
      skus: [],
      tag: '',
      dealType: 'tag',
      backgroundColor: 'transparent',
    } as Partial<FlashDealSettings>,
  },
  [WidgetType.BANNER]: {
    type: WidgetType.BANNER,
    payload: {
      column: {
        count: 1,
        images: [],
        minImage: 1,
        maxImage: 1,
        type: BannerType.ONE_ROW_ONE_COLUMN,
      },
      images: [],
      backgroundColor: 'transparent',
    } as Partial<BannerSettings>,
  },
  [WidgetType.SLIDER]: {
    type: WidgetType.SLIDER,
    payload: {
      backgroundColor: 'transparent',
      column: {
        count: 1,
        images: [],
        minImage: 2,
        maxImage: 8,
        type: SliderType.ARROW,
      },
      images: [],
    } as Partial<SliderSettings>,
  },
  [WidgetType.TEXT]: textWidgetDefinition.default,
  [WidgetType.RICH_TEXT]: richTextWidget.default,
  [WidgetType.CMS_RICH_TEXT]: cmsRichTextWidget.default,
  [WidgetType.COUPON]: {
    type: WidgetType.COUPON,
    payload: {
      grid: 'one',
      codes: [],
    } as Partial<CouponSettings>,
  },
  [WidgetType.TIMER]: {},
  [WidgetType.POPUP]: {
    type: WidgetType.POPUP,
    payload: {
      buttonEnabled: false,
    } as Partial<PopupSettings>,
  },
  [WidgetType.VIDEO]: {
    type: WidgetType.VIDEO,
    payload: {
      url: '',
      padding: false,
      backgroundColor: 'transparent',
    } as Partial<VideoSettings>,
  },
  [WidgetType.TIMER]: {
    type: WidgetType.TIMER,
    payload: {
      slots: [],
    } as Partial<TimerSettings>,
  },
  [WidgetType.WAY_POINT]: {
    type: WidgetType.WAY_POINT,
    payload: {
      anchorId: '',
      backgroundColor: 'transparent',
    } as Partial<WayPointSettings>,
  },
  [WidgetType.NAVIGATION]: {
    type: WidgetType.NAVIGATION,
    payload: {
      tabs: [],
      sticky: false,
      stickyPosition: StickyPosition.TOP,
      color: 'black',
      backgroundColor: 'white',
    } as Partial<WayPointSettings>,
  },
  [WidgetType.COUPON_HUB]: {
    type: WidgetType.COUPON_HUB,
    payload: {
      type: CouponHubType.TOP_SELLER,
      categoriesFontColor: 'rgba(0, 0, 0, 0.85)',
    },
  },
  [WidgetType.FORM]: {
    type: WidgetType.FORM,
    payload: {
      fields: [],
      type: FormType.PARTNERSHIP,
      backgroundColor: 'white',
      submitButtonColor: '#0d5cb6',
      showSubscriber: false,
    },
  },
  [WidgetType.PERSONALIZE]: {
    type: WidgetType.PERSONALIZE,
    payload: {
      type: PersonalizeType.PRODUCTS,
      layout: PersonalizeLayout.ONE,
      row: 2,
      url: '',
      padding: false,
      backgroundColor: 'transparent',
    } as Partial<PersonalizeSettings>,
  },
  [WidgetType.VOTING]: {
    type: WidgetType.VOTING,
    payload: {
      id: String,
    },
  },
  [WidgetType.REDEEM]: redeemDefinition.default,
  [WidgetType.SLOT_MACHINE]: {
    type: WidgetType.SLOT_MACHINE,
    payload: {
      id: String,
    },
  },
  [WidgetType.CATEGORY_BRAND]: categoryBrandDefinition.default,
  [WidgetType.CATEGORY_SLIDER]: categorySliderDefinition.default,
  [WidgetType.ASA_COUPON]: categorySliderDefinition.default,
  [WidgetType.ASA_COUPON]: {
    type: WidgetType.ASA_COUPON,
    payload: {
      grid: 'one',
      codes: [],
    } as Partial<ASACouponSettings>,
  },
  [WidgetType.SELLER_COUPON]: {
    type: WidgetType.SELLER_COUPON,
    payload: {
      grid: 'one',
      couponType: 'SELLER_ID',
      codeSellerIds: [],
      codeRuleIds: [],
    } as Partial<SellerCouponSettings>,
  },
};

export const CAMPAIGN_WIDGETS_LIST = [
  WidgetType.PRODUCT,
  WidgetType.FLASH_DEAL,
  WidgetType.NORMAL_DEAL,
  WidgetType.BANNER,
  WidgetType.SLIDER,
  WidgetType.TEXT,
  WidgetType.RICH_TEXT,
  WidgetType.COUPON,
  WidgetType.TIMER,
  WidgetType.POPUP,
  WidgetType.VIDEO,
  WidgetType.WAY_POINT,
  WidgetType.NAVIGATION,
  WidgetType.COUPON_HUB,
  WidgetType.FORM,
  WidgetType.VOTING,
  WidgetType.REDEEM,
  WidgetType.SLOT_MACHINE,
  WidgetType.PERSONALIZE,
  WidgetType.ASA_COUPON,
  WidgetType.SELLER_COUPON,
];

export const CATEGORY_WIDGETS_LIST = [
  WidgetType.CATEGORY_BRAND,
  WidgetType.CATEGORY_SLIDER,
  WidgetType.CMS_RICH_TEXT,
];

export const CHILD_WIDGETS_LIST = [
  WidgetType.PRODUCT,
  WidgetType.FLASH_DEAL,
  WidgetType.NORMAL_DEAL,
  WidgetType.BANNER,
  WidgetType.TEXT,
  WidgetType.RICH_TEXT,
  WidgetType.COUPON,
  WidgetType.VIDEO,
  WidgetType.SLIDER,
  WidgetType.COUPON_HUB,
  WidgetType.FORM,
  WidgetType.PERSONALIZE,
];

export const STRICT_WIDGETS: { [key: string]: number } = {
  [WidgetType.POPUP]: 1,
  [WidgetType.TIMER]: 1,
  [WidgetType.NAVIGATION]: 1,
  [WidgetType.CATEGORY_BRAND]: 1,
  [WidgetType.CATEGORY_SLIDER]: 3,
  [WidgetType.CMS_RICH_TEXT]: 1,
};
