import { WidgetType } from '@/infras/widget/constants';
import RedeemDraggable from './RedeemDraggable';
import RedeemWidgetSettings from './RedeemSetting';
import RedeemView from './RedeemView';
import { RedeemSettings } from './types';
const widget = {
  type: WidgetType.REDEEM,
  View: RedeemView,
  Settings: RedeemWidgetSettings,
  Draggable: RedeemDraggable,
  default: {
    type: WidgetType.REDEEM,
    payload: {
      mobileImage: '',
      desktopImage: ''
    } as RedeemSettings,
  },
};

export { WIDGET_TYPE } from './types';
export default widget;
