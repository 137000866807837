import React, { FC } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { AppTheme } from '@/themes/defaultThemes';

import { TextSettings, TextWidget } from './types';
import { ViewProps } from '..';

export interface TextViewProps extends ViewProps<TextWidget> {}

const TextViewContainer: StyledComponent<'div', AppTheme> = styled.div`
  background-color: ${(props: TextSettings) => props.backgroundColor};
  padding: 8px 16px;
`;

const Heading: StyledComponent<'h2', AppTheme> = styled.h2`
  color: ${(props: TextSettings['heading']) => props.color};
  font-size: ${(props: TextSettings['heading']) => (props.size === 'large' ? '32px' : '28px')};
  text-align: ${(props: TextSettings['heading']) => props.align};
  margin-bottom: 0;
`;
const Paragraph = styled.p`
  color: ${(props: TextSettings['paragraph']) => props.color};
  text-align: ${(props: TextSettings['paragraph']) => props.align};
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
  white-space: pre-wrap;
`;

const Views: any = {
  heading: Heading,
  paragraph: Paragraph,
};

const TextView: FC<TextViewProps> = ({ widget: { payload } }) => {
  const View = Views[payload.textType];
  if (!View) {
    return null;
  }
  const { text, ...other } = payload[payload.textType] || {};

  return (
    <TextViewContainer {...payload}>
      <View {...other}>{text || ''}</View>
    </TextViewContainer>
  );
};

export default TextView;
