import websocket from '@/components/webSocket/webSocket';
import { requestPageAction } from '@/infras/page/useCase';
import { socketContext } from '@/infras/socket/constants';
import { connectSocket, disConnectSocket } from '@/infras/socket/useCase';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { Modal } from 'antd';
import { history } from 'umi';
import { PagesState } from '../infras/page/typing';

const { info } = Modal;

const initState: PagesState = {};

const builder = new DvaModelBuilder(initState, socketContext);

builder.takeLatest(connectSocket, function* (query: any, { call, take }: EffectsCommandMap) {
  const {
    payload: {
      response: { isEditingBy, campaign },
    },
  } = yield take(requestPageAction.success);
  if (!isEditingBy || isEditingBy === query.email) {
    yield call(websocket.connect, query);
  } else {
    info({
      title: 'Thông báo',
      content: `Trang đang được chỉnh sửa bởi ${isEditingBy}`,
      onOk: () => {
        history.replace(`/campaigns/${campaign.id}`);
      },
    });
  }
});

builder.takeLatest(disConnectSocket, function* (payload: any, { call }: EffectsCommandMap) {
  yield call(websocket.disconnect);
});

export default builder.build();
