import React, { FC } from 'react';
import styled from 'styled-components';
import { ViewProps } from '..';
import { TitleWrapper } from '../category-collection/CollectionView.styled';
import { CmsRichTextWidget } from './types';

export interface RichTextViewProps extends ViewProps<CmsRichTextWidget> {}

const RichTextContainer = styled.div`
  display: flex;

  .group {
    margin-bottom: 16px;
    border-radius: 4px;
    background-color: #ffffff;

    .content {
      padding: 0 16px 16px 16px;
      display: inline-block;

      &__column {
        display: flex;
        justify-content: space-between;
        & > div {
          flex: 0 0 calc(50% - 15px);
        }
      }

      color: #242424;
      text-align: justify;

      border-radius: 4px;

      iframe {
        border: 0;
        display: table;
        margin: auto;
      }

      h5 {
        margin-top: 20px;
        margin-bottom: 10px;

        font-size: 20px;
      }

      p {
        margin-top: 5px;
        margin-bottom: 12px;
        line-height: 21px;
      }

      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
      iframe {
        width: 100%;
      }

      table {
        width: 100% !important;
        max-width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        line-height: 21px;

        tr {
          font-size: 13px;
          border-bottom: 0;

          &:nth-child(even) {
            background-color: #fafafa;
          }

          td {
            padding: 10px 15px;

            &:first-child {
              width: 220px;
              vertical-align: top;
              color: #4f4f4f;
              font-size: 13px;
              font-weight: 500;
              background: #efefef;
            }

            &:last-child {
              flex: 1;
              border-right: 0;
            }

            ul {
              margin: 0;
              padding-left: 20px;
            }
          }
        }
      }

      .view-more {
        position: relative;

        .gradient {
          position: absolute;
          top: -205px;
          left: 0;
          width: 100%;
          height: 200px;
          background-image: linear-gradient(to bottom, transparent, #ffffff);
        }

        p {
          margin-bottom: 20px;
        }

        .btn-more {
          cursor: pointer;

          display: block;

          width: 229px;
          height: 39px;
          margin: 10px auto 0;

          color: #189eff;
          font-size: 13px;
          font-weight: 400;
          line-height: 39px;
          text-align: center;

          border: 1px solid #189eff;
          border-radius: 4px;

          &:hover {
            color: #ffffff;
            background-color: #189eff;
          }
        }
      }
    }
  }
`;

const RichTextView: FC<RichTextViewProps> = ({ widget: { payload } }) => {
  if (!payload?.text) return null;
  return (
    <RichTextContainer className="rich-text-container">
      <div className="group">
        <TitleWrapper>Thông tin danh mục</TitleWrapper>{' '}
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: payload?.text }}></div>
        </div>
      </div>
    </RichTextContainer>
  );
};

export default RichTextView;
