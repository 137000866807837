import {
  StoreCollectionsRequestData,
  StoreCollectionsResponse,
  StoreCollectionsState,
  StoreCollection,
  StoreCollectionFormData,
  StoreCollectionRequest,
  StoreCollectionUpdateFormData,
} from '@/infras/store/typing';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { StoreCollectionsContext } from '@/infras/store/constants';
import {
  createStoreCollectionAction,
  getStoreCollectionsAction,
  getStoreCollectionAction,
  updateStoreCollectionAction,
} from '@/infras/store/useCase';
import * as storeCollectionService from '@/services/store-collections';

import { ACTION_STATUS } from '@/typings/redux';
import { mapObjectsById } from '@/utils/mapObjects';
import {
  mapApiDataToStoreCollectionsRes,
  mapDataToCreateStoreCollectionReq,
  mapDataToUpdateStoreCollectionReq,
} from '@/pages/stores/mapper';

const initState: StoreCollectionsState = {
  map: new Map<number | string, StoreCollection>(),
  list: {
    data: [],
    total: 0,
  },
  request: {
    page: 1,
    limit: 10,
  },
  status: ACTION_STATUS.INITIAL,
};

const builder = new DvaModelBuilder(initState, StoreCollectionsContext);

builder
  .immer(
    getStoreCollectionsAction.success,
    (state: StoreCollectionsState, { request, response }: StoreCollectionsResponse) => {
      state.list = response;
      state.map = mapObjectsById(response.data);
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getStoreCollectionsAction.execute, function* (
    request: StoreCollectionsRequestData,
    { call, put, select }: EffectsCommandMap,
  ) {
    try {
      if (!request) {
        request = yield select((state: any) => {
          console.log('select state', state);
          return (state['store-collections'] as any)?.request;
        });
      }
      const reqParams: any = {
        ...request,
      };
      if (request?.filter?.key) {
        reqParams[request.filter?.key] = request.filter.value;
      }
      const resp: any = yield call(storeCollectionService.getStoreCollections, reqParams);
      const data = mapApiDataToStoreCollectionsRes(resp);
      yield put(getStoreCollectionsAction.success({ request, response: data }));
    } catch (error) {
      yield put(getStoreCollectionsAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createStoreCollectionAction.execute, function* (
    payload: StoreCollectionFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response: any = yield call(
        storeCollectionService.createStoreCollection,
        mapDataToCreateStoreCollectionReq(payload),
      );
      yield put(createStoreCollectionAction.success(response));
      yield put(getStoreCollectionsAction.execute());
      return response;
    } catch (err) {
      yield put(createStoreCollectionAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createStoreCollectionAction.execute, (state: StoreCollectionsState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = undefined;
    return state;
  })
  .immer(createStoreCollectionAction.success, (state: StoreCollectionsState) => {
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(createStoreCollectionAction.fail, (state: StoreCollectionsState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

builder
  .immer(
    updateStoreCollectionAction.success,
    (state: StoreCollectionsState, storeCollection: StoreCollection) => {
      const { id } = storeCollection;
      state.list.data = state.list.data.map((item) => {
        if (item.id === id) {
          return storeCollection;
        }
        return item;
      });

      return state;
    },
  )
  .takeLatest<any>(updateStoreCollectionAction.execute, function* (
    payload: StoreCollectionUpdateFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(
        storeCollectionService.updateStoreCollection,
        mapDataToUpdateStoreCollectionReq(payload),
      );
      yield put(updateStoreCollectionAction.success(resp));
      return resp;
    } catch (error) {
      yield put(updateStoreCollectionAction.fail(error));
    }
  });

builder
  .immer(
    getStoreCollectionAction.success,
    (
      state: StoreCollectionsState,
      { request, response }: { request: StoreCollectionRequest; response: StoreCollection },
    ) => {
      state.map.set(request.storeCollectionId, response);

      return state;
    },
  )
  .takeLatest<any>(getStoreCollectionAction.execute, function* (
    request: StoreCollectionRequest,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const storeCollection: any = yield call(storeCollectionService.getStoreCollection, request);

      yield put(
        getStoreCollectionAction.success({
          request,
          response: storeCollection,
        }),
      );
    } catch (err) {
      yield put(getStoreCollectionAction.fail(err));
    }
  });

export default builder.build();
