import { requestPageAction, updateWidgetsIndex } from '@/infras/page/useCase';
import {
  cloneWidget,
  createWidgetAction,
  deleteMultiWidget,
  deleteWidget,
  getChildWidgetsAction,
  getWidgets,
  updateWidgetAction,
} from '@/infras/widget/useCase';
import { widgetProps } from '@/components/Editor/constant';
import childWidgetsServices from '@/services/widgets/child-widgets';
import widgetsServices from '@/services/widgets/widgets';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { parseJSONPayload } from '@/utils/parseWidgetPayload';
import { widgetsContext } from '@/infras/widget/constants';
import { PageWidgetsState, Widget } from '@/infras/widget/typing';

const initState: PageWidgetsState = {};

const builder = new DvaModelBuilder(initState, widgetsContext);
builder.immer(getWidgets, (state: PageWidgetsState, { response }: any) => {
  return { ...response };
});

builder.takeLatest<any>(
  createWidgetAction.execute,
  function* (payload: any, { call, put }: EffectsCommandMap) {
    try {
      yield call(widgetsServices.createWidget, payload);
      yield put.resolve(
        requestPageAction.execute({
          pageId: payload.pageId,
        }),
      );
      yield put(createWidgetAction.success());
    } catch (error) {
      yield put(createWidgetAction.fail(error));
    }
  },
);

builder.takeLatest<any>(
  deleteWidget.execute,
  function* (
    { id, pageId, widgets }: { id: string; pageId: string; widgets: string[] },
    { call, put }: EffectsCommandMap,
  ) {
    try {
      yield call(widgetsServices.deleteWidget, id);
      // update widgets index after deletion
      const newWidgets = widgets.filter((widgetId) => widgetId !== id);
      yield put.resolve(updateWidgetsIndex({ pageId, widgets: newWidgets }));

      yield put(deleteWidget.success());
    } catch (error) {
      yield put(deleteWidget.fail(error));
    }
  },
);

builder.takeLatest<any>(
  deleteMultiWidget.execute,
  function* (
    { ids, pageId, widgets }: { ids: string[]; pageId: string; widgets: string[] },
    { call, put }: EffectsCommandMap,
  ) {
    try {
      yield call(widgetsServices.deleteMultiWidget, ids);
      // update widgets index after deletion
      const newWidgets = widgets.filter((widgetId) => !ids.includes(widgetId));
      yield put.resolve(updateWidgetsIndex({ pageId, widgets: newWidgets }));

      yield put(deleteMultiWidget.success());
    } catch (error) {
      yield put(deleteMultiWidget.fail(error));
    }
  },
);

builder.takeLatest<any>(
  cloneWidget.execute,
  function* (
    { pageId, widget, widgets }: { widget: Widget; widgets: string[]; pageId: string },
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const newWidget = yield call(widgetsServices.createWidget, widget);
      const newWidgets = [
        ...widgets.slice(0, widget.index),
        newWidget.id,
        ...widgets.slice(widget.index, widgets.length),
      ];
      yield put.resolve(updateWidgetsIndex({ pageId, widgets: newWidgets }));
      yield put(cloneWidget.success());
    } catch (error) {
      yield put(cloneWidget.fail(error));
    }
  },
);

builder
  .immer(
    updateWidgetAction.success,
    (state: PageWidgetsState, { response }: { response: Widget }) => {
      const { id } = response;
      state = { ...state, [id]: response };

      return state;
    },
  )
  .takeLatest<any>(
    updateWidgetAction.execute,
    function* (request: Widget, { call, put }: EffectsCommandMap) {
      try {
        const response = yield call(widgetsServices.updateWidget, request);
        yield put(updateWidgetAction.success({ response }));
      } catch (error) {
        yield put(updateWidgetAction.fail(error));
      }
    },
  );

builder
  .immer(getChildWidgetsAction.success, (state, widgets) => {
    widgets.forEach((widget) => {
      widget.payload = parseJSONPayload(widget.payload, widgetProps[widget.type]);
      state[widget.id] = widget;
    });

    return state;
  })
  .takeLatest(getChildWidgetsAction.execute, function* ({ parent }, { call, put }) {
    try {
      const widgets = yield call(childWidgetsServices.getChildWidgets, parent);
      for (let i = 0; i < widgets.length; i += 1) {
        widgets[i].parent_id = parent.id;
      }

      yield put(getChildWidgetsAction.success(widgets));
    } catch (err) {
      yield put(getChildWidgetsAction.fail(err));
    }
  });

export default builder.build();
