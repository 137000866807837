import {
  handleExecuteCreateCollection,
  handleExecuteDeleteCollection,
  handleExecuteUpdateCollection,
  handleFetchMoreCollectionItems,
} from '@/infras/collection/handlers';
import {
  createCollectionAction,
  deleteCollection,
  fetchMoreCollectionItemsAction,
  setCollections,
  resetErrorCollections,
  updateCollectionAction,
} from '@/infras/collection/useCase';
import { DvaModelBuilder } from '@/utils/dva-model-creator';
import { collectionsContext } from '../infras/collection/constants';
import { CollectionsState } from '../infras/collection/typing';

const initState: CollectionsState = { error: null };

const builder = new DvaModelBuilder(initState, collectionsContext);

builder.immer(setCollections, (state: CollectionsState, { response: { id, payload } }: any) => {
  state[id] = payload;
  return state;
});

builder.immer(resetErrorCollections, (state: CollectionsState) => {
  return { ...state, error: null };
});

builder
  // Handle create one collection
  .takeLatest<any>(createCollectionAction.execute, handleExecuteCreateCollection)
  .immer(createCollectionAction.execute, (state: CollectionsState) => {
    state.error = null;
    return state;
  })
  .immer(createCollectionAction.fail, (state: CollectionsState, { error }: any) => {
    state.error = error;

    return state;
  });

builder.takeLatest<any>(deleteCollection.execute, handleExecuteDeleteCollection);

builder
  // Handle Update one collection
  .takeLatest<any>(updateCollectionAction.execute, handleExecuteUpdateCollection)
  .immer(updateCollectionAction.fail, (state: CollectionsState, { error }: any) => {
    state.error = error;

    return state;
  })
  .immer(updateCollectionAction.execute, (state: CollectionsState) => {
    state.error = null;

    return state;
  });

builder
  // Handle fetch more items on collection
  .takeLatest(fetchMoreCollectionItemsAction.execute, handleFetchMoreCollectionItems)
  .immer(fetchMoreCollectionItemsAction.success, (state, payload) => {
    const { collectionId, data, isLastPage } = payload;
    const { uiData } = state[collectionId];
    uiData.productList.push(...data);
    uiData.page += 1;
    uiData.isLastPage = isLastPage;
    return state;
  });

export default builder.build();
