import Box from '@/components/Box';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { debounce, get } from 'lodash';
import Quill from 'quill';
import Delta from 'quill-delta';
import Op from 'quill-delta/dist/Op';
import { DEFAULT_RICH_TEXT_TOOLBAR } from './constants';
import EditImageModal from './EditImageModal';
import { QuillImage } from './types';

interface Props {
  maxLength?: number;
  value?: any;
  onChange?: (value: any) => void;
  customToolbar?: any[];
  returnInHTML?: boolean;
  placeholder?: string;
}

const QuillTextInput: FC<Props> = ({
  maxLength,
  onChange,
  value,
  customToolbar,
  returnInHTML = false,
  placeholder = 'Nhập vào nội dung...',
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<any | null>(null);
  const [editingImage, setEditingImage] = useState<QuillImage | null>(null);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (!editorRef.current && containerRef.current) {
      // And initialize Quill
      const quill = new Quill(containerRef.current, {
        theme: 'snow',
        placeholder,
        bounds: containerRef.current,
        modules: {
          toolbar: {
            container: customToolbar || DEFAULT_RICH_TEXT_TOOLBAR,
            handlers: {
              image: () => {
                const range = quill.getSelection(true);
                if (range.length) {
                  // When user selected some text, check the images
                  const selectedContents = quill.getContents(range.index, range.length);

                  // Get image from contents
                  let img: QuillImage | null = null;
                  const op: any = selectedContents.ops[0] || {};
                  if (op.insert?.image) {
                    img = {
                      url: op.insert.image || '',
                      alt: get(op.attributes, 'alt') || '',
                      width: parseInt(get(op.attributes, 'width'), 10),
                      index: range.index,
                      attributes: {
                        ...op.attributes,
                      },
                    };
                  }
                  if (img) {
                    setEditingImage(img);
                    return;
                  }
                }
                // When user isn't select, just open the upload with empty
                setEditingImage({
                  url: '',
                  alt: '',
                  width: 0,
                  height: 0,
                });
              },
            },
          },
        },
      });
      let quillContent = value || [];
      if (returnInHTML) {
        quillContent = quill.clipboard.convert(value);
      }
      quill.setContents(quillContent);

      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: Delta) => {
        // New match remove color for pasted text
        delta.ops = delta.ops.filter((op: Op) => {
          if (op.attributes) {
            delete op.attributes.color;
            delete op.attributes.background;
            delete op.attributes.backgroundColor;
          }
          const inserted = op.insert as any;

          // Exclude image & video from pasted data
          if (inserted?.image || inserted.video) {
            return false;
          }
          return true;
        });
        return delta;
      });

      // Assign editor instance
      editorRef.current = quill;
    }

    return () => {
      if (containerElement) {
        // Destroy container childs
        containerElement.innerHTML = '';
      }
    };
  }, []);

  const handleSubmitImage = useCallback((img: QuillImage) => {
    if (editorRef.current) {
      const quill = editorRef.current;
      if (typeof img.index === 'number') {
        // Replace old embled
        quill.updateContents(
          new Delta()
            .retain(img.index) // Keep before image
            .delete(1) // Delete old image
            .insert(
              // Insert new image
              {
                image: img.url,
              },
              {
                ...img.attributes, // retain other attributes, such as link
                width: `${img.width}px`,
                height: `${img.height}px`,
                alt: img.alt,
              },
            ), // Apply bold to exclamation mark
        );
        quill.setSelection(img.index + 1, Quill.sources.SILENT);
      } else {
        const range = quill.getSelection(true);
        quill.updateContents(
          new Delta()
            .retain(range.index) // Keep before image
            .insert(
              // Insert new image
              {
                image: img.url,
              },
              {
                width: `${img.width}px`,
                height: `${img.height}px`,
                alt: img.alt,
              },
            ), // Apply bold to exclamation mark
        );
        quill.setSelection(range.index + 1, Quill.sources.SILENT);
      }

      // Close
      setEditingImage(null);
    }
  }, []);
  const handleClose = useCallback(() => setEditingImage(null), []);

  useEffect(() => {
    const debouncedSetContents = debounce(() => {
      if (editorRef.current) {
        const next = editorRef.current.getContents();
        if (onChange) {
          if (returnInHTML) {
            onChange(editorRef.current?.root.innerHTML);
          } else {
            onChange({
              ops: next.ops,
              length: editorRef.current.getLength() - 1,
            });
          }
        }
      }
    }, 300);

    editorRef.current?.on('text-change', debouncedSetContents);

    return () => {
      editorRef.current?.off('text-change', debouncedSetContents);
    };
  }, [onChange, returnInHTML]);

  return (
    <Box my="8px">
      {maxLength ? (
        <Box
          my="8px"
          style={{ fontSize: 12, textAlign: 'end' }}
          color={value && value.length > maxLength ? '#f5222d' : 'inherit'}
        >{`Độ dài ${value?.length || 0}/${maxLength}`}</Box>
      ) : null}
      <div ref={containerRef} onDragOver={(e) => e.preventDefault()} />

      <EditImageModal
        visible={Boolean(editingImage)}
        image={editingImage}
        onConfirm={handleSubmitImage}
        onCancel={handleClose}
      />
    </Box>
  );
};

export default memo(QuillTextInput);
