import { IProductSKU } from '@/components/controls/inputs/types';
import { Widget } from '@/infras/widget/typing';
import { TabTitle, TabType } from '../shared/product-normal-deal/types';

export enum ProductType {
  PRODUCT_LISTING = 'category',
  COMBO = 'combo',
  NORMAL = 'normal',
  SSKU = 'ssku',
  NEW_SSKU = 'newSSKU',
}
export interface ProductTab {
  id: string;
  tabTitleContent: string;
  color?: string;
  newSSKU?: IProductSKU[];
  productTypeInput: string;
  productType: ProductType;
  seeMoreLink?: string;
  brand?: string;
  isTikingon?: boolean;
}
export type ProductSettings = {
  backgroundColor: string;
  color: string;
  tabTitleType: TabTitle;
  type: TabType;
  row: number;
  tabs: ProductTab[];
  name?: string;
};

export interface ProductGeneralSettings {
  backgroundColor: string;
  layout: {
    type: TabType;
    rowCount: number;
  };
}

export type ProductWidget = Widget<ProductSettings>;

export const WIDGET_TYPE = 'product';
