import {
  createCampaignAction,
  getCampaignAction,
  getCampaignsAction,
  resetCampaignRequestAction,
  updateCampaignAction,
} from '@/infras/campaign/useCase';
import { getPages } from '@/infras/page/useCase';
import { FILTER_KEY } from '@/pages/campaigns/components/CampaignFilter';
import {
  mapDataCampaignsReq,
  mapDataToCreateCampaignReq,
  mapDataToUpdateCampaignReq,
} from '@/pages/campaigns/mapper';
import campaignsServices from '@/services/campaigns';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { getListMapData } from '@/utils';
import { campaignsContext } from '../infras/campaign/constants';
import {
  Campaign,
  CampaignsRequestData,
  CampaignsResponse,
  CampaignState,
  CampaignFormData,
  CampaignRequest,
} from '../infras/campaign/typing';

const initState: CampaignState = {
  campaignMap: {},
  campaignList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: FILTER_KEY.NAME,
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, campaignsContext);

builder
  .immer(
    getCampaignsAction.success,
    (state: CampaignState, { request, response }: CampaignsResponse) => {
      const { mapData: campaignMap, list: campaignList } = getListMapData(response.data);
      state.campaignMap = { ...state.campaignMap, ...campaignMap };
      state.campaignList = { data: campaignList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getCampaignsAction.execute, function* (
    request: CampaignsRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(campaignsServices.getCampaigns, mapDataCampaignsReq(request));
      yield put(getCampaignsAction.success({ request, response }));
    } catch (error) {
      yield put(getCampaignsAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createCampaignAction.execute, function* (
    payload: CampaignFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(
        campaignsServices.createCampaign,
        mapDataToCreateCampaignReq(payload),
      );
      yield put(createCampaignAction.success(response));
      yield put.resolve(getCampaignsAction.execute());
      return response;
    } catch (err) {
      yield put(createCampaignAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createCampaignAction.execute, (state: CampaignState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createCampaignAction.success, (state: CampaignState) => {
    state.status = 'resolved';
    return state;
  })
  .immer(createCampaignAction.fail, (state: CampaignState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder.immer(resetCampaignRequestAction, (state: CampaignState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  .immer(updateCampaignAction.success, (state: CampaignState, campaign: Campaign) => {
    const { id } = campaign;
    state.campaignMap = { ...state.campaignMap, [id]: campaign };

    return state;
  })
  .takeLatest<any>(updateCampaignAction.execute, function* (
    payload: CampaignFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(
        campaignsServices.updateCampaign,
        mapDataToUpdateCampaignReq(payload),
      );
      yield put(updateCampaignAction.success(resp));
    } catch (error) {
      yield put(updateCampaignAction.fail(error));
    }
  });

builder
  .immer(
    getCampaignAction.success,
    (
      state: CampaignState,
      { request, response }: { request: CampaignRequest; response: Campaign },
    ) => {
      state.campaignMap[request.campaignId] = response;

      return state;
    },
  )
  .takeLatest<any>(getCampaignAction.execute, function* (
    request: CampaignRequest,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const campaign = yield call(campaignsServices.getCampaign, request);
      const { mapData: pageMap, list: pageList } = getListMapData(campaign.pages);

      yield put(getPages({ response: pageMap }));
      yield put(getCampaignAction.success({ request, response: { ...campaign, pages: pageList } }));
    } catch (err) {
      yield put(getCampaignAction.fail(err));
    }
  });

export default builder.build();
