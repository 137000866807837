import React, { FC, useCallback, useEffect, useRef } from 'react';
import Input, { InputProps } from 'antd/es/input';

interface NumberInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: number) => void;
}

const NumberInput: FC<NumberInputProps> = ({ value, onChange, ...props }) => {
  const inputRef = useRef<any>();
  const handleChangeNumber = useCallback(
    (e) => {
      const { value } = e.target;
      const num = parseInt(value, 10) || 0;
      if (num && onChange) {
        onChange(num);
      }
    },
    [onChange],
  );
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.setValue(value);
    }
  }, [value]);
  return <Input ref={inputRef} onBlur={handleChangeNumber} {...props} />;
};

export default NumberInput;
