import { PermissionManageMapping, PermissionType } from '@/infras/permission/constants';
import { Role } from '@/infras/role/typing';
import { getUserPermissionsFromRoles } from '@/pages/collections/util';
import React from 'react';
import PromiseRender from './PromiseRender';
import { CURRENT } from './renderAuthorize';

export type IAuthorityType =
  | undefined
  | string
  | string[]
  | Promise<boolean>
  | ((currentAuthority: string | string[]) => IAuthorityType);

/**
 * 通用权限检查方法
 * Common check permissions method
 * @param { 权限判定 | Permission judgment } authority
 * @param { 你的权限 | Your permission description } currentAuthority
 * @param { 通过的组件 | Passing components } target
 * @param { 未通过的组件 | no pass components } Exception
 */
const checkPermissions = <T, K>(
  authority: IAuthorityType,
  currentAuthority: string | string[],
  target: T,
  Exception: K,
): T | K | React.ReactNode | boolean => {
  // 没有判定权限.默认查看所有
  // Retirement authority, return target;
  if (!authority) {
    return target;
  }

  // 数组处理
  if (Array.isArray(authority)) {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some((item) => authority.includes(item))) {
        return target;
      }
    } else if (authority.includes(currentAuthority)) {
      return target;
    }

    return Exception;
  }
  // string 处理
  if (typeof authority === 'string') {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some((item) => authority === item)) {
        return target;
      }
    } else if (authority === currentAuthority) {
      return target;
    }
    return Exception;
  }
  // Promise 处理
  if (authority instanceof Promise) {
    return <PromiseRender<T, K> ok={target} error={Exception} promise={authority} />;
  }
  // Function 处理
  if (typeof authority === 'function') {
    const bool = authority(currentAuthority);
    // 函数执行后返回值是 Promise
    if (bool instanceof Promise) {
      return <PromiseRender<T, K> ok={target} error={Exception} promise={bool} />;
    }
    if (bool) {
      return target;
    }
    return Exception;
  }
  throw new Error('unsupported parameters');
};

export { checkPermissions };

function check<T, K>(
  authority: IAuthorityType,
  target: T,
  Exception: K,
): T | K | React.ReactNode | boolean {
  return checkPermissions<T, K>(authority, CURRENT, target, Exception);
}

// check if current user has authority specified, returns boolean
export function hasAuthority(authority: IAuthorityType) {
  return checkPermissions(authority, CURRENT, true, false);
}

export function hasUpdateCampaignAuthority() {
  return hasAuthority([PermissionType.ALL, PermissionType.UPDATE_CAMPAIGN]);
}

export function hasUpdateCategoryAuthority() {
  return hasAuthority([PermissionType.ALL, PermissionType.UPDATE_CATEGORY]);
}

export function hasUpdateCollectionAuthority() {
  return hasAuthority([PermissionType.ALL, PermissionType.UPDATE_COLLECTION]);
}

export function hasUpdateThemeCollectionAuthority() {
  return hasAuthority([PermissionType.ALL, PermissionType.UPDATE_THEME]);
}

// check if selected user's info can be edited by current user (based on mapping specified in permission/constants)
export function isUserEditable(userRoles: Role[]) {
  const currentUserPermissions = typeof CURRENT === 'string' ? [CURRENT] : CURRENT;

  const selectedUserPermissions = getUserPermissionsFromRoles(userRoles);
  // admin user can edit all user
  const currentUserIsAdmin = currentUserPermissions.includes(PermissionType.ALL);
  const selectedUserIsAdmin = selectedUserPermissions.includes(PermissionType.ALL);
  // current admin user can edit all
  if (currentUserIsAdmin) {
    return true;
  }
  // can only edit other admin users if current user is admin
  if (selectedUserIsAdmin) {
    return currentUserIsAdmin;
  }

  /**
   * eg: if current user has permissions [MANAGE_CAMPAIGN_PERMISSION, MANAGE_COLLECTION_PERMISSION]
   * [
   *   {MANAGE_CAMPAIGN_PERMISSION: true, READ_CAMPAIGN: true, UPDATE_CAMPAIGN: true},
   *   {MANAGE_COLLECTION_PERMISSION: true, READ_COLLECTION: true, UPDATE_COLLECTION: true}
   * ]
   */
  const managePermissions = currentUserPermissions
    .map((permission) => PermissionManageMapping[permission])
    .filter(Boolean);
  const hasManagePermissions = Boolean(managePermissions.length);
  /**
   * eg: flatten
   * {
   *   MANAGE_CAMPAIGN_PERMISSION: true,
   *   READ_CAMPAIGN: true,
   *   UPDATE_CAMPAIGN: true,
   *   MANAGE_COLLECTION_PERMISSION: true,
   *   READ_COLLECTION: true,
   *   UPDATE_COLLECTION: true
   * }
   */
  const flatManagePermissionsMap = Object.assign({}, ...managePermissions);

  if (hasManagePermissions) {
    const isEditable = selectedUserPermissions.every(
      (selectedUserPermission) => flatManagePermissionsMap[selectedUserPermission],
    );
    return isEditable;
  }

  return false;
}

export default check;
