import { Widget } from '@/infras/widget/typing';

export enum BannerType {
  ONE_ROW_ONE_COLUMN = 1,
  ONE_ROW_TWO_COLUMN = 2,
  ONE_ROW_THREE_COLUMN = 3,
  TWO_ROW_TWO_COLUMN = 4,
  TWO_ROW_THREE_COLUMN = 5,
  THREE_ROW_THREE_COLUMN = 6,
}

export interface ImageSettings {
  url: string;
  name: string;
  status: string;
  index: number;
  uid: string;
  urlLink: string;
  anchorId: string;
  height?: number;
  width?: number;
  dominantColor?: string;
}
export interface ColumnSettings<T = any> {
  type: T;
  count: number;
  minImage: number;
  maxImage: number;
  images: ImageSettings[] | any[];
}

export interface BannerSettings {
  column: ColumnSettings<BannerType>;
  backgroundColor: string;
  borderRadius?: boolean;
  padding?: boolean;
  outOfStockImage?:string;
  name?: string;
}

export type BannerWidget = Widget<BannerSettings>;
