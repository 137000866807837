import {
  handleExecuteGetOneCPage,
  handleExecuteGetListCPages,
  handleExecuteCreateCPage,
  handleExecuteUpdateOneCPage,
} from '@/infras/collection-page/handlers';
import {
  createCollectionPageAction,
  getCollectionPageAction,
  getCollectionPagesAction,
  resetCollectionPageRequestAction,
  updateCollectionPageAction,
} from '@/infras/collection-page/useCase';
import { FILTER_KEY } from '@/pages/collections/components/CollectionPageFilter';
import { DvaModelBuilder } from '@/utils/dva-model-creator';
import { getListMapData } from '@/utils';
import { collectionPagesContext } from '../infras/collection-page/constants';
import {
  CollectionPage,
  CollectionPageRequest,
  CollectionPagesResponse,
  CollectionPageState,
} from '../infras/collection-page/typing';

const initState: CollectionPageState = {
  collectionPageMap: {},
  collectionPageList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: FILTER_KEY.NAME,
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, collectionPagesContext);

builder
  // Handle get list collection pages
  .takeLatest<any>(getCollectionPagesAction.execute, handleExecuteGetListCPages)
  .immer(
    getCollectionPagesAction.success,
    (state: CollectionPageState, { request, response }: CollectionPagesResponse) => {
      const { mapData: collectionPageMap, list: collectionPageList } = getListMapData(
        response.data,
      );
      state.collectionPageMap = { ...state.collectionPageMap, ...collectionPageMap };
      state.collectionPageList = { data: collectionPageList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  );

builder
  // Handle create one collection page
  .takeLatest<any>(createCollectionPageAction.execute, handleExecuteCreateCPage)
  .immer(createCollectionPageAction.execute, (state: CollectionPageState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createCollectionPageAction.success, (state: CollectionPageState) => {
    state.status = 'resolved';
    return state;
  })
  .immer(createCollectionPageAction.fail, (state: CollectionPageState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder.immer(resetCollectionPageRequestAction, (state: CollectionPageState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  // Handle update one collection page
  .takeLatest<any>(updateCollectionPageAction.execute, handleExecuteUpdateOneCPage)
  .immer(
    updateCollectionPageAction.success,
    (state: CollectionPageState, collectionPage: CollectionPage) => {
      const { id } = collectionPage;
      state.collectionPageMap = { ...state.collectionPageMap, [id]: collectionPage };

      return state;
    },
  );

builder
    // Handle get one collection page
  .takeLatest<any>(getCollectionPageAction.execute, handleExecuteGetOneCPage)
  .immer(
    getCollectionPageAction.success,
    (
      state: CollectionPageState,
      { request, response }: { request: CollectionPageRequest; response: CollectionPage },
    ) => {
      state.collectionPageMap[request.collectionPageId] = response;

      return state;
    },
  );

export default builder.build();
