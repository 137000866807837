import Box from '@/components/Box';

import { Button } from 'antd';

import { flatten } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DragDropContext, DragStart, Droppable } from 'react-beautiful-dnd';
import useSelectedDragThemeCollections from './hooks/useSelectedDragThemeCollections';
import ThemeOperation from './ThemeOperation';

interface ReorderThemeProps {
  themes: any[];
  pageId: string;
  droppableId?: string;
  type?: string;
  enableFooter?: boolean;
  afterDragEnd?: (themeAfterOrder: any[]) => void;
  enableClone?: boolean;
  renderItem?: (data: any, index: number) => void;
}

const reorder = (
  list: any[],
  startIndex: number,
  endIndex: number,
  selectedIds: number[] = [],
): any[] => {
  let result = Array.from(list);
  if (selectedIds.length <= 1) {
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  /**
   * Example: list = [1,2,3,4,5], startIndex = 2, endIndex = 3, selectedIds = [5,3,2]
   * First, we get the selected ids by current order in list -> selectedByOrder = [2,3,5]
   * Currently, we can only drag one for all -> remove the id at the start index -> result = [1,2,4,5]
   * End index is calculated by lib after remove the item at the start index
   * Then, we add the Array of dragging ids to the right position -> result = [1,2,4,[2,3,5],5]
   * then filter out the dragged ids. -> result = [1,4,[2,3,5]]
   * Finally flatten the array to get the result -> result = [1,4,2,3,5]
   */
  const selectedByOrder = result.filter((i) => selectedIds.includes(i));
  result.splice(startIndex, 1);
  result.splice(endIndex, 0, selectedByOrder);
  result = result.filter((i) => !selectedIds.includes(i));
  return flatten(result);
};

const ReorderTheme: FunctionComponent<ReorderThemeProps | any> = ({
  value,
  onCancel,
  onConfirm,
}) => {
  const {
    selectedDraggingIds,
    selectSingleItem,
    draggingId,
    setDraggingId,
  } = useSelectedDragThemeCollections();

  const [themes, setThemes] = useState<any>(value || []);

  const onDragStart = useCallback(
    (start: DragStart) => {
      const id = Number(start.draggableId);
      const selected = selectedDraggingIds.includes(id);

      // if dragging an item that is not selected - unselect all items
      if (!selected) {
        selectSingleItem(id);
      }
      setDraggingId(id);
    },
    [selectSingleItem, selectedDraggingIds, setDraggingId],
  );

  const onDragEnd = useCallback(
    (result: any) => {
      setDraggingId(null);
      if (!result.destination) {
        return;
      }

      const newTheme: string[] = reorder(
        themes,
        result.source.index,
        result.destination.index,
        selectedDraggingIds,
      );

      // updateIndex({ pageId, themes: newTheme });
      setThemes(newTheme);
    },
    [setDraggingId, themes, selectedDraggingIds],
  );

  const renderThemeCollection = (theme: any, idx: any) => {
    return (
      <ThemeOperation
        key={theme.id}
        draggingId={draggingId}
        selectedDraggingIds={selectedDraggingIds}
        selectSingleItem={selectSingleItem}
        id={theme.id}
        index={idx}
        theme={theme}
      />
    );
  };

  return (
    <Box bg="white" display="flex" flex={1} flexDirection="column" height="100%" m={0}>
      <Box as="h2" pb="16px" m={0}>
        Chỉnh sửa vị trí chủ đề
      </Box>
      <Box pb="8px" m={0}>
        Giữ và kéo lên/xuống để thay đổi vị trí chủ đề{' '}
      </Box>

      {themes.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Box display="flex" height="100%" overflowY="scroll" flexDirection="column" flex={1}>
            <Box display="flex" flex={1} flexDirection="column" alignItems="stretch" mb="16px">
              <Droppable droppableId="list" type="theme-collection">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {themes.map(renderThemeCollection)}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </Box>
        </DragDropContext>
      )}
      <Box display="flex" pt="16px">
        <Button
          type="ghost"
          style={{
            margin: '0 8px 0  auto',
            background: 'white',
            color: '#40a9ff',
            borderColor: '#40a9ff',
            display: 'flex',
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 44,
          }}
          onClick={onCancel}
        >
          Đóng
        </Button>
        <Button
          type="primary"
          onClick={() => onConfirm(themes)}
          style={{
            margin: '0 0 0 auto',
            display: 'flex',
            flex: 1,
            textAlign: 'center',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: 44,
          }}
        >
          Lưu
        </Button>
      </Box>
    </Box>
  );
};

export default ReorderTheme;
