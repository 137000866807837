import { TimerWidget, TimeSlot } from '@/components/widgets/timer/types';
import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { Widget } from '../widget/typing';
import { widgetMetaContext } from './constants';
import { WidgetMetaData, WidgetMetaState } from './typing';

export const { action: setWidgetMetaAction, hook: setWidgetMetaHook } = createUseCase<{
  widget: Widget;
  meta: WidgetMetaData;
}>(widgetMetaContext, 'setWidgetMeta');

export const { action: createChildWidgetAction, hook: createChildWidgetHook } = createAsyncUseCase<
  { widget: Widget; parent: Widget },
  Widget,
  WidgetMetaState
>(widgetMetaContext, 'createChildWidgetAction');

export const { action: updateChildWidgetAction, hook: updateChildWidgetHook } = createAsyncUseCase<
  Widget,
  Widget,
  WidgetMetaState
>(widgetMetaContext, 'updateChildWidgetAction');

export const { action: removeChildWidgetAction, hook: removeChildWidgetHook } = createAsyncUseCase<
  { widget: Widget; parent: Widget },
  Widget,
  WidgetMetaState
>(widgetMetaContext, 'removeChildWidgetAction');

export const {
  action: updateChildWidgetsIndex,
  hook: updateChildWidgetsIndexHook,
} = createAsyncUseCase<
  { widget: TimerWidget; timeSlot: TimeSlot; childWidgetsIndexOrder: number[] },
  any
>(widgetMetaContext, 'updateChildWidgetsIndex');

export const { action: cloneChildWidgetAction, hook: cloneChildWidgetHook } = createAsyncUseCase<
  { widget: Widget; parent: Widget },
  Widget,
  WidgetMetaState
>(widgetMetaContext, 'cloneChildWidgetAction');
