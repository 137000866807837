export const collectionsContext = 'collection';

export const collectionBackgroundImages = [
  'https://salt.tikicdn.com/ts/brickv2og/8a/b9/cc/533b72fbb0be6f08a89a78694de2a9c2.png',
  'https://salt.tikicdn.com/ts/brickv2og/48/41/74/c9fe58b85eee6ba0516da3af0d99f530.png',
  'https://salt.tikicdn.com/ts/brickv2og/d6/af/c6/11be919f6712ba96a6954900acbe21e6.png',
  'https://salt.tikicdn.com/ts/brickv2og/dd/1b/4d/64163c06b42a91720bffed80b7d001f5.png',
  'https://salt.tikicdn.com/ts/brickv2og/34/f8/78/95a77e10802720f0a10c2a3d011b321e.png',
  'https://salt.tikicdn.com/ts/brickv2og/3c/ce/05/b3452561a6f3fcbb08e27a97c859a06d.png',
  'https://salt.tikicdn.com/ts/brickv2og/6f/a8/96/8b7d1079490ad25aa03bf2c91449ff6b.png',
];

export const collectionBackgroundTikiImages = {
  'https://salt.tikicdn.com/ts/brickv2og/8a/b9/cc/533b72fbb0be6f08a89a78694de2a9c2.png':
    'https://salt.tikicdn.com/ts/brickv2og/4d/f9/67/89fb729186f0cffbd3a7230fe5a2c913.png',
  'https://salt.tikicdn.com/ts/brickv2og/48/41/74/c9fe58b85eee6ba0516da3af0d99f530.png':
    'https://salt.tikicdn.com/ts/brickv2og/33/d1/a2/15ccc6f7f3011913617e6af3eb09c107.png',
  'https://salt.tikicdn.com/ts/brickv2og/d6/af/c6/11be919f6712ba96a6954900acbe21e6.png':
    'https://salt.tikicdn.com/ts/brickv2og/63/00/2d/80cfc866b9deb6129774483bc7a98f7a.png',
  'https://salt.tikicdn.com/ts/brickv2og/dd/1b/4d/64163c06b42a91720bffed80b7d001f5.png':
    'https://salt.tikicdn.com/ts/brickv2og/47/93/30/3351a4a1680f1c6c385148c27d05687d.png',
  'https://salt.tikicdn.com/ts/brickv2og/34/f8/78/95a77e10802720f0a10c2a3d011b321e.png':
    'https://salt.tikicdn.com/ts/brickv2og/05/ca/e5/7dd4258ea5bff7490f75956adf97b029.png',
  'https://salt.tikicdn.com/ts/brickv2og/3c/ce/05/b3452561a6f3fcbb08e27a97c859a06d.png':
    'https://salt.tikicdn.com/ts/brickv2og/c9/82/a4/a1f36310e9e482b41390510b62458082.png',
  'https://salt.tikicdn.com/ts/brickv2og/6f/a8/96/8b7d1079490ad25aa03bf2c91449ff6b.png':
    'https://salt.tikicdn.com/ts/brickv2og/17/73/14/5e695c727abfd05d3ad82632cff02d8a.png',
};

export const tikiNGONBackgroundImages = [
  'https://salt.tikicdn.com/ts/upload/01/ff/54/51fc1ab8454a060a71ffe94d7440cd6d.jpg',
  'https://salt.tikicdn.com/ts/upload/ea/c3/ae/93a8cc3285c673cf8ec90b9c05936b10.jpg',
  'https://salt.tikicdn.com/ts/upload/ae/18/8d/46a78b605187bc7cf9fcf260eda7b6d2.jpg',
];

export const tikiNGONCollectionBackgroundImages = {
  'https://salt.tikicdn.com/ts/upload/01/ff/54/51fc1ab8454a060a71ffe94d7440cd6d.jpg':
    'https://salt.tikicdn.com/ts/upload/01/ff/54/51fc1ab8454a060a71ffe94d7440cd6d.jpg',
  'https://salt.tikicdn.com/ts/upload/ea/c3/ae/93a8cc3285c673cf8ec90b9c05936b10.jpg':
    'https://salt.tikicdn.com/ts/upload/ea/c3/ae/93a8cc3285c673cf8ec90b9c05936b10.jpg',
  'https://salt.tikicdn.com/ts/upload/ae/18/8d/46a78b605187bc7cf9fcf260eda7b6d2.jpg':
    'https://salt.tikicdn.com/ts/upload/ae/18/8d/46a78b605187bc7cf9fcf260eda7b6d2.jpg',
};
