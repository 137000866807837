// import ColorPicker from '@/components/core/ColorPicker';
import { useEditorContext } from '@/infras/brick-editor/useEditorContext';
import useBoolean from '@/hooks/useBoolean';
import { Button, Form, Input, Space } from 'antd';
import React, { FC, useCallback } from 'react';
import NumberInput from '@/components/controls/inputs/NumberInput';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { EditSettingsProps } from '..';
import BrandItemsSettings from './fields/BrandItemSettings';
import { CategoryBrandWidget } from './types';
import NameFieldItemSettings from '../shared/NameFieldItem';
import { MIN_SHOW_MAX } from './constants';
import { MIN_ITEMS } from '../category-collection/constants';

const horizontalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 10px;
  }
`;

// const ColorPickerFormItem = styled(Form.Item)`
//   &.ant-form-item {
//     margin-bottom: 0;
//   }

//   .ant-form-item-label > label {
//     padding-top: 10px;
//   }
// `;

const BrandSettings: FC<EditSettingsProps<CategoryBrandWidget>> = ({ widget, form }) => {
  // const [editingState, setEditingState] = useState<CategoryBrandSettings>({ ...widget.payload });
  const [show, actions] = useBoolean(false);
  const { fieldSettingContainer, toggleFieldSettings } = useEditorContext();

  const handleShowItemsSettings = useCallback(() => {
    toggleFieldSettings(true);
    actions.on();
  }, []);

  const validateShowMax = useCallback(
    async (data, input) => {
      if (!input) {
        return Promise.reject(new Error(`Vui lòng nhập Số lượng tối đa trên 1 dòng`));
      }
      if (input < MIN_SHOW_MAX) {
        return Promise.reject(new Error(`Số lượng tối đa trên 1 dòng phải lớn hơn ${MIN_ITEMS}`));
      }
      // Try to open upload if number of items is less than MIN_ITEMS
      const items = form.getFieldValue('items') || [];
      if (items?.length < MIN_ITEMS) {
        handleShowItemsSettings();
        return Promise.reject(new Error(`Vui lòng upload hình!`));
      }
      return Promise.resolve();
    },
    [handleShowItemsSettings, form],
  );

  let fieldSetting;
  if (fieldSettingContainer && show) {
    fieldSetting = createPortal(
      <BrandItemsSettings onClose={toggleFieldSettings} form={form} />,
      fieldSettingContainer,
    );
  }

  return (
    <div className="brand-settings">
      <h2>Category Brand</h2>
      <NameFieldItemSettings widgetId={widget.id || ''} />
      <StyledFormItem {...horizontalLayout} name="title" label="Tiêu đề">
        <Input type="text" placeholder="Nhập vào tiêu đề" />
      </StyledFormItem>
      <StyledFormItem
        {...horizontalLayout}
        name="showMax"
        label="Tối đa trên 1 dòng"
        rules={[
          {
            validator: validateShowMax,
          },
        ]}
      >
        <NumberInput type="number" placeholder="Nhập vào số lượng tối đa xuất hiện trên 1 dòng" />
      </StyledFormItem>
      <Space>
        <Button className="" onClick={handleShowItemsSettings}>
          Upload hình
        </Button>
      </Space>
      {/* <StyledFormItem {...horizontalLayout} name="padding" label="Padding" valuePropName="checked">
        <Switch />
      </StyledFormItem>
      <StyledFormItem
        {...horizontalLayout}
        name="borderRadius"
        label="Border Radius"
        valuePropName="checked"
      >
        <Switch />
      </StyledFormItem>
      <ColorPickerFormItem {...horizontalLayout} name="backgroundColor" label="Màu background">
        <ColorPicker />
      </ColorPickerFormItem> */}
      {fieldSetting}
    </div>
  );
};

export default BrandSettings;
