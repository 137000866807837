import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { collectionPagesContext } from './constants';
import {
  CollectionPage,
  CollectionPageFormData,
  CollectionPageRequest,
  CollectionPagesRequestData,
  CollectionPagesResponse,
  CollectionPageState,
} from './typing';

export const {
  action: getCollectionPagesAction,
  hook: getCollectionPagesHook,
} = createAsyncUseCase<CollectionPagesRequestData, CollectionPagesResponse, CollectionPageState>(
  collectionPagesContext,
  'getCollectionPages',
);

export const { action: getCollectionPageAction, hook: getCollectionPageHook } = createAsyncUseCase<
  CollectionPageRequest,
  { request: CollectionPageRequest; response: CollectionPage },
  CollectionPageState
>(collectionPagesContext, 'getCollectionPage');

export const {
  action: createCollectionPageAction,
  hook: createCollectionPageHook,
} = createAsyncUseCase<CollectionPageFormData, CollectionPage, CollectionPageState>(
  collectionPagesContext,
  'createCollectionPageAction',
  {
    readableName: 'Tạo page Collection mới',
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: updateCollectionPageAction,
  hook: updateCollectionPageHook,
} = createAsyncUseCase<CollectionPageFormData, CollectionPage, CollectionPageState>(
  collectionPagesContext,
  'updateCollectionPageAction',
  {
    readableName: 'Lưu thay đổi page Collection',
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: resetCollectionPageRequestAction,
  hook: resetCollectionPageRequestHook,
} = createUseCase(collectionPagesContext, 'resetRequest');
