import {
  CurrentUserRequest,
  User,
  UserFormData,
  UserRequest,
  UsersRequest,
} from '@/infras/user/typing';
import { apiRequest } from '@/utils/request';
import { ListProps } from 'antd/lib/list';
import { stringify } from 'qs';

async function getUsers(req: UsersRequest): Promise<ListProps<any>> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/users?${qs}`);
  return { ...data, data: data.items, total: data.meta.totalItems };
}

async function createUser(user: UserFormData) {
  const customHandle = true;
  const data = await apiRequest(
    `/users`,
    {
      method: 'POST',
      data: user,
    },
    customHandle,
  );
  return data;
}

async function updateUser(user: UserFormData) {
  return apiRequest(`/users/${user.email}`, {
    method: 'PUT',
    data: { ...user, status: user?.status ? 1 : 0 },
  });
}

async function getUser(req: UserRequest): Promise<any> {
  const data: any = await apiRequest(`/users/${req.email}`);

  return data;
}
async function checkCurrentUser(req: CurrentUserRequest): Promise<User> {
  const data: any = await apiRequest('/users/check', {
    method: 'POST',
    data: req,
  });

  return data;
}

export default { getUsers, createUser, updateUser, getUser, checkCurrentUser };
