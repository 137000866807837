import { useMemo, useState } from 'react';

/**
 * Returns a stateful boolean value and an object with the following function to update it:
 * on: () => void - set boolean value to true
 * off: () => void - set boolean value to false
 * toggle: () => void - negate boolean state
 * @param initial Initial state
 */
function useBoolean(initial: boolean) {
  const [truthy, setTruthy] = useState<boolean>(initial);

  const actions = useMemo(
    () => ({
      on() {
        setTruthy(true);
      },
      off() {
        setTruthy(false);
      },
      toggle() {
        setTruthy((prev) => !prev);
      },
      set(isTruthy: boolean) {
        setTruthy(isTruthy);
      },
    }),
    [],
  );
  return [truthy, actions] as const;
}

export default useBoolean;
