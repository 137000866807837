import { NgonThemeFormData } from '@/infras/ngon-theme/typing';

import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IThemeStatus {
  theme: NgonThemeFormData;
}

const CustomWrapper = styled.div`
  .ant-switch-checked {
    background-color: #0b74e5;
  }
`;

const ThemeStatus = ({ theme }: IThemeStatus) => {
  const { isDisplayed, status } = theme;

  return (
    <CustomWrapper>
      <Tag color={isDisplayed ? 'blue' : 'default'}>
        {isDisplayed ? 'Đang chạy' : status ? 'Tạm dừng' : 'Nháp'}
      </Tag>
    </CustomWrapper>
  );
};

export default ThemeStatus;
