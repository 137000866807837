import {
  createNgonThemeAction,
  deleteNgonThemeAction,
  getNgonThemeAction,
  getNgonThemesAction,
  orderNgonThemeAction,
  getOrderNgonThemeAction,
  resetNgonThemeRequestAction,
  updateNgonThemeAction,
} from '@/infras/ngon-theme/useCase';
import { mapCreateDataNgonThemesReq } from '@/pages/create-ngon-theme/mapper';
import { FILTER_KEY } from '@/pages/ngon-themes/components/ThemeFilter';
import { getCollection } from '@/services/collections';
import ngonThemesServices from '@/services/ngonTheme';
import * as storeCollectionService from '@/services/store-collections';
import { getListMapData } from '@/utils';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { message } from 'antd';
import { ngonThemesContext, ThemeCollectionTypeName } from '../infras/ngon-theme/constants';
import {
  NgonTheme,
  NgonThemeFormData,
  NgonThemeRequest,
  NgonThemesRequestData,
  NgonThemesResponse,
  NgonThemeState,
} from '../infras/ngon-theme/typing';

const initState: NgonThemeState = {
  ngonThemeMap: {},
  ngonThemeOrderList: [],
  ngonThemeList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: FILTER_KEY.NAME,
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, ngonThemesContext);

builder
  .immer(
    getNgonThemesAction.success,
    (state: NgonThemeState, { request, response }: NgonThemesResponse) => {
      const { mapData: ngonThemeMap, list: ngonThemeList } = getListMapData(response.data);
      state.ngonThemeMap = { ...state.ngonThemeMap, ...ngonThemeMap };
      state.ngonThemeList = { data: ngonThemeList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getNgonThemesAction.execute, function* (
    request: NgonThemesRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(ngonThemesServices.getNgonThemes, request);
      yield put(getNgonThemesAction.success({ request, response }));
    } catch (error) {
      yield put(getNgonThemesAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createNgonThemeAction.execute, function* (
    payload: NgonThemeFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(
        ngonThemesServices.createNgonTheme,
        mapCreateDataNgonThemesReq(payload) as any,
      );
      yield put(createNgonThemeAction.success(response));
      yield put.resolve(getNgonThemesAction.execute());
      return response;
    } catch (err) {
      yield put(createNgonThemeAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createNgonThemeAction.execute, (state: NgonThemeState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createNgonThemeAction.success, (state: NgonThemeState) => {
    state.status = 'resolved';
    message.success('Chủ đề đã được tạo thành công');
    return state;
  })
  .immer(createNgonThemeAction.fail, (state: NgonThemeState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder.immer(resetNgonThemeRequestAction, (state: NgonThemeState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  .immer(updateNgonThemeAction.success, (state: NgonThemeState, ngonTheme: NgonTheme) => {
    const { id } = ngonTheme;
    state.ngonThemeMap = { ...state.ngonThemeMap, [id]: ngonTheme };

    return state;
  })
  .takeLatest<any>(updateNgonThemeAction.execute, function* (
    payload: NgonThemeFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(ngonThemesServices.updateNgonTheme, payload as any);
      yield put(updateNgonThemeAction.success(resp));
      return resp;
    } catch (error) {
      yield put(updateNgonThemeAction.fail(error));
      return error;
    }
  });

builder
  .immer(
    getNgonThemeAction.success,
    (
      state: NgonThemeState,
      { request, response }: { request: NgonThemeRequest; response: NgonTheme },
    ) => {
      state.ngonThemeMap[request.ngonThemeId] = response;

      return state;
    },
  )
  .takeLatest<any>(getNgonThemeAction.execute, function* (
    request: NgonThemeRequest,
    { call, put, all }: EffectsCommandMap,
  ) {
    try {
      const ngonTheme = yield call(ngonThemesServices.getNgonTheme, request);
      let collectionsData = [];
      try {
        collectionsData = yield all(
          ngonTheme?.collections?.reduce((res: any, collection: any) => {
            if (!collection) return res;
            try {
              if (collection.collectionType === ThemeCollectionTypeName.Store) {
                res = [
                  ...res,
                  {
                    ...call(storeCollectionService.getStoreCollection, {
                      storeCollectionId: collection.collectionId,
                    } as any),
                  },
                ];
              }
              if (collection.collectionType === ThemeCollectionTypeName.Product) {
                res = [
                  ...res,
                  {
                    ...call(getCollection, {
                      collectionId: collection.collectionId,
                    } as any),
                  },
                ];
              }
              return res;
            } catch (error) {
              return res;
            }
          }, []),
        );
      } catch (error) {}

      const response = {
        ...ngonTheme,
        collections: collectionsData.map((data: any, index: any) => {
          if (ngonTheme?.collections[index].collectionType === ThemeCollectionTypeName.Store) {
            return {
              ...data,
              ...ngonTheme?.collections[index],
              fileCodes: {
                file: { name: `${data?.name} store report.xlsx` },
                codes: data.storeIds.split(','),
              },
              codes: data.storeIds.split(','),
            };
          }

          return {
            ...data.meta_data,
            ...data.collection_meta_data,
            ...ngonTheme?.collections[index],
            fileCodes: {
              file: { name: `${data?.name} store report.xlsx` },
              codes: data.meta_data.skus,
            },
            codes: data.meta_data.skus,
            slug: data.collection_meta_data?.url || data.meta_data?.url,
          };
        }),
      };
      yield put(getNgonThemeAction.success({ request, response }));
      return response;
    } catch (err) {
      console.log(err, 'err');
      yield put(getNgonThemeAction.fail(err));
    }
  });

builder
  .takeLatest<any>(deleteNgonThemeAction.execute, function* (
    payload,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const { id } = payload;
      yield call(ngonThemesServices.deleteNgonTheme, id);

      yield put(deleteNgonThemeAction.success({ id }));
    } catch (err) {
      yield put(deleteNgonThemeAction.fail(err));
    }
  })
  .immer(deleteNgonThemeAction.success, (state, { id }) => {
    state.ngonThemeList.data = state.ngonThemeList.data.filter((x) => x != id);
    delete state.ngonThemeMap[id];

    return state;
  });

builder.takeLatest<any>(orderNgonThemeAction.execute, function* (
  payload,
  { call, put }: EffectsCommandMap,
) {
  try {
    yield call(ngonThemesServices.orderNgonTheme, payload.data);

    yield put(orderNgonThemeAction.success());
    yield put.resolve(getNgonThemesAction.execute(payload?.request || {}));
  } catch (err) {
    yield put(orderNgonThemeAction.fail(err));
  }
});

builder
  .takeLatest<any>(getOrderNgonThemeAction.execute, function* (
    payload,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response: any = yield call(ngonThemesServices.getAllOrderNgonThemes);

      yield put(getOrderNgonThemeAction.success(response));
      return response;
    } catch (err) {
      yield put(getOrderNgonThemeAction.fail(err));
    }
  })
  .immer(getOrderNgonThemeAction.success, (state, response: any) => {
    state.ngonThemeOrderList = response;
    return state;
  })
  .immer(getOrderNgonThemeAction.fail, (state: NgonThemeState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

export default builder.build();
