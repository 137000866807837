import { ProColumns } from '@ant-design/pro-table';
import React from 'react';
import QuickLinkMoveIndex from './components/QuickLinkMoveIndex';
import ThemeInfo from './components/ThemeInfo';
import ThemeOperating from './components/ThemeOperating';
import ThemePosition from './components/ThemePosition';
import ThemeStatus from './components/ThemeStatus';
import { getSortQueryMapper } from './util';

export enum QUICK_LINK_TYPE {
  HCM = 'tikingon_quick_link_hcm',
  HN = 'tikingon_quick_link_hn',
}
export enum SORT_KEYS {
  NAME = 'name',
  POSITION = 'position',
  PRIORITY = 'priority',
  IS_DISPLAYED = 'isDisplayed',
}

export const sortOrderMapper = {
  descend: 'desc',
  desc: 'descend',
  ascend: 'asc',
  asc: 'ascend',
};
export const itemsPerPage = [10, 15, 25, 50, 100];
export const getColumns = (query: any): ProColumns<any>[] => {
  const sortMapper = getSortQueryMapper(query?.sort);

  return [
    {
      title: 'Quick link',

      render: (dom, theme) => {
        return <ThemeInfo theme={theme} />;
      },
    },

    {
      title: 'Vị trí',
      key: SORT_KEYS.PRIORITY,
      sorter: { multiple: 2 },
      sortOrder: sortOrderMapper[sortMapper?.[SORT_KEYS.PRIORITY]],
      render: (dom, theme) => {
        return <ThemePosition theme={theme} />;
      },
    },
    {
      title: 'Sắp xếp',
      align: 'center',
      dataIndex: 'sort',
      valueType: 'option',
      key: 'sort',
      render: (_, theme, index) => {
        return <QuickLinkMoveIndex theme={theme} index={index} />;
      },
    },

    {
      title: 'Trạng thái',
      key: SORT_KEYS.IS_DISPLAYED,
      sorter: { multiple: 3 },
      sortOrder: sortOrderMapper[sortMapper?.[SORT_KEYS.IS_DISPLAYED]],
      render: (dom, theme) => {
        return <ThemeStatus theme={theme} />;
      },
    },
    {
      title: 'Thao tác',
      render: (dom, theme) => {
        return <ThemeOperating theme={theme} />;
      },
    },
  ];
};
