import React from 'react';
import { resizeImageHasCache } from '@/utils/cachedImage';

export const parseQuillJsonToHTMLList = (ops: any[]) => {
  const list: React.ReactElement[] = [];

  for (let i = 0; i < ops.length; i += 1) {
    const attributes = ops[i].attributes || {};
    const insert = ops[i].insert || {};

    if (insert === '\n' && attributes?.header) {
      // Render heading, by pop last ele from list
      // and check if available, then override ele's tag
      const prev = list.pop();
      if (prev && prev.type === 'p') {
        const Tag: any = `h${attributes.header}`;
        list.push(<Tag key={i}>{prev.props.children}</Tag>);
      }
    } else {
      // Render other, wrap with paragraph
      let child: any = insert || '';
      if (insert.image) {
        let imgSrc: string = insert.image || '';
        if (imgSrc?.startsWith('http') && attributes?.width) {
          const width = parseInt(attributes.width, 10);
          const height = parseInt(attributes.height, 10);
          imgSrc = resizeImageHasCache(imgSrc, width);
          child = (
            <img
              className="ql-image"
              key={i}
              src={insert.image}
              alt={attributes.alt || ''}
              width={attributes.width}
              style={{
                aspectRatio: `1 / ${height / width}`,
              }}
            />
          );
        }
      }

      if (typeof child === 'string') {
        // Format spacing
        child = (
          <span
            dangerouslySetInnerHTML={{
              __html: child.replace(/ {2}/g, '&nbsp; ').split('\n').join('<br>'),
            }}
          />
        );
      }

      if (attributes.underline) {
        child = <u>{child}</u>;
      }
      if (attributes.italic) {
        child = <em>{child}</em>;
      }
      if (attributes.bold) {
        child = <strong>{child}</strong>;
      }
      if (attributes.link) {
        child = <a href={attributes.link}>{child}</a>;
      }
      list.push(<p key={i}>{child}</p>);
    }
  }
  return list;
};
