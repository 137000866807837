/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@/components/Box';
import ColorPicker from '@/components/core/ColorPicker';
import { AlignCenterOutlined, AlignLeftOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space } from 'antd';
import React, { FC, useCallback } from 'react';
import { ParagraphSettings } from '../types';

interface Props extends ParagraphSettings {
  onClose?: () => void;
  onChange: (settings: ParagraphSettings) => void;
}

const EditParagraphSettings: FC<Props> = ({ align, color, text, onChange, onClose }) => {
  const handleTextChange = useCallback(
    (e: any) => {
      onChange({
        text: e.target.value,
        color,
        align,
      });
    },
    [color, align],
  );

  const handleChangeColor = useCallback(
    (value: string) => {
      onChange({
        color: value,
        align,
        text,
      });
    },
    [align, text],
  );

  return (
    <div>
      <Box my="12px">
        <Box mb="8px">Đoạn văn</Box>
        <Input.TextArea placeholder="Nhập vào đoạn văn..." name="text" value={text} onChange={handleTextChange} />
      </Box>

      <Box my="12px">
        <Row>
          <Col span={8}>Canh lề</Col>
          <Col span={16}>
            <Space>
              <Button
                type={align === 'left' ? 'primary' : 'default'}
                value="left"
                onClick={() => onChange({ align: 'left', color, text })}
              >
                <AlignLeftOutlined />
              </Button>
              <Button
                type={align === 'center' ? 'primary' : 'default'}
                value="center"
                onClick={() => onChange({ align: 'center', color, text })}
              >
                <AlignCenterOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
      </Box>

      <Box my="12px">
        <Row>
          <Col span={8}>Màu chữ</Col>
          <Col span={16}>
            <ColorPicker value={color} onChange={handleChangeColor} />
          </Col>
        </Row>
      </Box>

      <Space>
        <Button onClick={onClose}>Đóng</Button>
      </Space>
    </div>
  );
};

export default EditParagraphSettings;
