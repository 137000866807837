import { requestPageHook } from '@/infras/page/useCase';
import { WidgetType } from '@/infras/widget/constants';
import { getWidgetsHook } from '@/infras/widget/useCase';
import { concat, each, reduce } from 'lodash';

export const useNameField = (pageId: string, widId?: string) => {
  const { state: pagesState } = requestPageHook();
  const { state: widgetsMap } = getWidgetsHook();

  const { widgets = [] } = pagesState[pageId] || {};

  const data = reduce(
    widgets,
    (res: any[], widgetId) => {
      if (widId === widgetId) return res;
      const widget = widgetsMap[widgetId];
      if (!widget) return res;

      if (widget.type === WidgetType.TIMER) {
        const childWidgetIds = reduce(
          widget.payload.slots,
          (dataIds: string[], slot) => {
            dataIds = concat(dataIds, slot?.widgets || []);
            return dataIds;
          },
          [],
        );
        each(childWidgetIds, (childWidgetId) => {
          const childWidget = widgetsMap[childWidgetId];
          res = [...res, { value: childWidget?.payload?.name }];
        });
      }
      res = [...res, { value: widget?.payload?.name }];
      return res;
    },
    [],
  );
  return data;
};
