import { cloneDeep } from 'lodash';

export function parseJSONPayload(jsonString: string, defaultValue: any): any {
  let widgetPayload: any = {};
  try {
    widgetPayload = JSON.parse(jsonString);
  } catch (err) {
    console.error('Parse jsonString error', err);
    widgetPayload = cloneDeep(defaultValue);
  }

  return widgetPayload;
}
