import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { usersContext } from './constants';
import {
  CurrentUserRequest,
  User,
  UserFormData,
  UserRequest,
  UsersRequestData,
  UsersResponse,
  UserState,
} from './typing';

export const { action: getUsersAction, hook: getUsersHook } = createAsyncUseCase<
  UsersRequestData,
  UsersResponse,
  UserState
>(usersContext, 'getUsers');

export const { action: getUserAction, hook: getUserHook } = createAsyncUseCase<
  UserRequest,
  { request: UserRequest; response: User },
  UserState
>(usersContext, 'getUser');

export const { action: checkCurrentUserAction, hook: checkCurrentUserHook } = createAsyncUseCase<
  CurrentUserRequest,
  User,
  UserState
>(usersContext, 'checkCurrentUser');

export const { action: createUserAction, hook: createUserHook } = createAsyncUseCase<
  UserFormData,
  User,
  UserState
>(usersContext, 'createUserAction', {
  readableName: 'Tạo user mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateUserAction, hook: updateUserHook } = createAsyncUseCase<
  User,
  User,
  UserState
>(usersContext, 'updateUserAction', {
  readableName: 'Lưu thay đổi user',
  messageSettings: { initial: true, success: true },
});

export const { action: resetUserRequestAction, hook: resetUserRequestHook } = createUseCase(
  usersContext,
  'resetRequest',
);
