import Box from '@/components/Box';
import { NgonQuickLinkFormData } from '@/infras/ngon-quick-link/typing';
import { orderNgonQuickLinkHook } from '@/infras/ngon-quick-link/useCase';

import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import ReorderTheme from '../ReorderTheme';

interface IThemePosition {
  theme: NgonQuickLinkFormData;
}

const StyledText = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 30px;
  color: #38383d;
`;

const ThemePosition = ({ theme }: IThemePosition) => {
  const [sortModalVisible, handleSortModalVisible] = useState<boolean>(false);
  const {
    fetch: orderNgonQuickLink,
    state: { request },
  } = orderNgonQuickLinkHook();

  const closeSortModal = () => {
    handleSortModalVisible(false);
  };

  const handleUpdateOrder = (data: any) => {
    const orderData = data.map((theme: any, index: number) => ({
      id: theme.id,
      priority: index,
    }));

    orderNgonQuickLink({ data: orderData, request });
    handleSortModalVisible(false);
  };
  return (
    <>
      <Box display="flex" alignItems="center" pr="8px">
        <StyledText>{theme?.index}</StyledText>
      </Box>

      <Modal
        key="order"
        visible={sortModalVisible}
        centered
        width={800}
        bodyStyle={{ borderRadius: 8, padding: '24px 32px', height: 400 }}
        footer={null}
        onCancel={closeSortModal}
        destroyOnClose
      >
        <ReorderTheme
          onCancel={closeSortModal}
          onConfirm={handleUpdateOrder}
          sortModalVisible={sortModalVisible}
        />
      </Modal>
    </>
  );
};

export default ThemePosition;
