import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { configsContext } from './constants';
import {
  Config,
  ConfigFormData,
  ConfigRequest,
  ConfigsRequestData,
  ConfigsResponse,
  ConfigState,
} from './typing';

export const { action: getConfigsAction, hook: getConfigsHook } = createAsyncUseCase<
  ConfigsRequestData,
  ConfigsResponse,
  ConfigState
>(configsContext, 'getConfigs');

export const { action: getConfigAction, hook: getConfigHook } = createAsyncUseCase<
  ConfigRequest,
  { request: ConfigRequest; response: any },
  any
>(configsContext, 'getConfig');

export const { action: createConfigAction, hook: createConfigHook } = createAsyncUseCase<
  ConfigFormData,
  Config,
  ConfigState
>(configsContext, 'createConfigAction', {
  readableName: 'Tạo config mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateConfigAction, hook: updateConfigHook } = createAsyncUseCase<
  ConfigFormData,
  Config,
  ConfigState
>(configsContext, 'updateConfigAction', {
  readableName: 'Lưu thay đổi config',
  messageSettings: { initial: true, success: true },
});

export const { action: resetConfigRequestAction, hook: resetConfigRequestHook } = createUseCase<
  any
>(configsContext, 'resetRequest');
