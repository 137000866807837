import Config from '@/config';
import { checkCurrentUserAction } from '@/infras/user/useCase';
import cookieService from '@/services/cookie';
import gtagService from '@/services/gtag';
import { parseCurrentToken, postCheckAuthCode } from '@/services/oauth';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { createAsyncUseCase } from '@/utils/dva-model-creator/async';
// import { notification } from 'antd';
import { Draft } from 'immer';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import { Effect, Reducer } from 'umi';

export interface CurrentOauth {
  id: string;
  name: string;
  email: string;
  authority?: string;
  jti: string;
  iat: number;
  exp: number;
}

export interface OauthModelState {
  currentOauth?: CurrentOauth;
  authority?: string;
}
const oauthNameSpace = 'oauth';
const initState = {};

export interface OauthModelType {
  namespace: string;
  state: OauthModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
    fetchAccessToken: Effect;
  };
  reducers: {
    saveCurrentOauth: Reducer;
  };
}

export const { action: fetchCurrent, hook: useFetchCurrent } = createAsyncUseCase<
  undefined,
  CurrentOauth
>(oauthNameSpace, 'fetchCurrent');

export const { action: fetchAccessToken, hook: useFetchAccessToken } = createAsyncUseCase<
  undefined,
  CurrentOauth
>(oauthNameSpace, 'fetchAccessToken');

const oauthBuilder = new DvaModelBuilder(initState, oauthNameSpace);

oauthBuilder
  .immer(fetchCurrent.success, (state: Draft<OauthModelState>, payload) => {
    state.currentOauth = payload;
  })
  .takeLatest(fetchCurrent.success, function* (payload, { put }: EffectsCommandMap) {
    // check current user from brick db. if exists, returns current user info.
    // if not (when user logs in brick the first time, it will automatically create a user with same name and email and empty role)
    yield put(checkCurrentUserAction.execute({ name: payload.name, email: payload.email }));
  })
  .takeLatest(fetchCurrent.execute, function* (payload, { call, put }: EffectsCommandMap) {
    try {
      const currentOauth = yield call(parseCurrentToken);
      gtagService.setUserId(currentOauth.email);
      yield put(fetchCurrent.success(currentOauth));
    } catch (err) {
      // Error during query current oauth, redirect to OAuth
      const callbackUrl = window.location.origin;
      const state = Math.floor(Math.random() * 1000000000);
      const redirectUrl = `${Config.OAuthEndpoint}/auth?client_id=tiki-builder-ui&scope=user_email user_profile openid&response_type=code&redirect_uri=${callbackUrl}&state=${state}`;
      window.location.href = redirectUrl;
    }
  })
  .takeLatest(fetchAccessToken.execute, function* (payload: any, { call, put }: EffectsCommandMap) {
    try {
      const data = yield call(postCheckAuthCode, payload?.code || '', window.location.origin);
      const currentOauth: any = jwtDecode(get(data, 'id_token', ''));

      // if (!currentOauth.email.endsWith('@tiki.vn')) {
      //   // Not end with tiki vn
      //   notification.error({
      //     description: 'Email không hỗ trợ. Bạn phải là Tikier thì mới có quyền đăng nhập!',
      //     message: 'Email không hỗ trợ',
      //   });
      //   setTimeout(() => {
      //     // Redirect to app
      //     window.location.href = '/';
      //   }, 2000);
      //   return;
      // }

      cookieService.saveUserToken(get(data, 'id_token', ''), get(data, 'refresh_token', ''));
      gtagService.setUserId(currentOauth.email);

      yield put(fetchCurrent.success(currentOauth));
    } catch (err) {
      const callbackUrl = window.location.origin;

      const state = Math.floor(Math.random() * 1000000000);
      const redirectUrl = `${Config.OAuthEndpoint}/auth?client_id=tiki-builder-ui&scope=user_email user_profile openid&response_type=code&redirect_uri=${callbackUrl}&state=${state}`;

      window.location.href = redirectUrl;
    }
  });

export default oauthBuilder.build();
