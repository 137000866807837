/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import Config from '@/config';
import cookieService from '@/services/cookie';
import { postRefreshToken } from '@/services/oauth';
import { notification } from 'antd';
import { get } from 'lodash';
import baseRequest, { extend } from 'umi-request';
import jwtDecode from 'jwt-decode';
import gtagService from '@/services/gtag';

const codeMessage = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  204: 'No Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  406: 'Not Acceptable',
  410: 'Gone',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

/**
 * 异常处理程序
 */
const errorHandler = async (error: any) => {
  const { request, response } = error;
  const trueErrorMessage = get(error, 'data.errorMessage') || '';

  if (response && response.status) {
    const errorText = trueErrorMessage || codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if (status) console.error(`Response status for ${url}: ${status}`);
    if (status === 401) {
      try {
        const data = await postRefreshToken(window.location.href);

        const currentOauth: any = jwtDecode(get(data, 'id_token', ''));
        cookieService.saveUserToken(get(data, 'id_token', ''), get(data, 'refresh_token', ''));
        gtagService.setUserId(currentOauth.email);
        const wrapConfig = {
          headers: {
            Authorization: `Bearer ${get(data, 'id_token', '')}`,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          credentials: 'include',
          ...request.config,
        };
        return baseRequest(request.url, wrapConfig);
      } catch (error) {
        const callbackUrl = window.location.origin;
        const state = Math.floor(Math.random() * 1000000000);
        const redirectUrl = `${Config.OAuthEndpoint}/auth?client_id=tiki-builder-ui&scope=offline openid&response_type=code&redirect_uri=${callbackUrl}&state=${state}`;
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 1000);
      }
    }
    notification.error({
      message: `Đã có lỗi xảy ra`,
      description: errorText,
    });

    // forward error to dva effect handler -> global onError
    throw new Error(errorText);
  } else if (!response) {
    notification.error({
      description: 'Đã có lỗi xảy ra',
      message: 'Vui lòng kiểm tra lại đường truyền mạng',
    });
    // forward error to dva effect handler -> global onError
    throw new Error('Vui lòng kiểm tra lại đường truyền mạng');
  }
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
});

export const oauthRequest = (link: any, config?: any) => {
  const wrapConfig = {
    headers: {
      Authorization: `Basic ${Config.OAuthBasicToken}`,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    credentials: 'include',
    prefix: Config.OAuthEndpoint,
    ...config,
  };
  return request(link, wrapConfig);
};
export const apiRequest = (link: any, config?: any, customHandle: boolean = false) => {
  const token = cookieService.getUserToken();
  const canary = cookieService.getCanaryCookie();
  const wrapConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'same-origin',
    prefix: Config.BrickAPI,
    ...config,
  };
  if (canary && wrapConfig.headers) {
    wrapConfig.headers.canary = canary;
  }
  return customHandle ? baseRequest(link, wrapConfig) : request(link, wrapConfig);
};

export default request;
