import { UsersRequest, UsersRequestData } from '@/infras/user/typing';

export const mapDataUsersReq = (request: UsersRequestData): UsersRequest => {
  if (!request) return { limit: 10, page: 1 };
  const filter = request?.filter ? { [request.filter.key]: request.filter.value } : {};
  return {
    limit: request.limit,
    page: request.page,
    ...filter,
  };
};
