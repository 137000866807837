import Config from '@/config';
import {
  CategoriesRequest,
  CategoryRequest,
  CreateCategoryRequest,
  UpdateCategoryRequest,
} from '@/infras/category/typing';
import { Page } from '@/infras/page/typing';
import { TikiCategoryType } from '@/pages/categories/constants';
import { mapApiDataToCategoriesRes } from '@/pages/categories/mapper';
import { getAuthorSlug, getBrandSlug } from '@/pages/categories/util';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
import { get } from 'lodash';
import moment from 'moment';
import { stringify } from 'qs';
import request from 'umi-request';

async function getCategories(req: CategoriesRequest): Promise<ListProps<any>> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/tiki-categories?${qs}`);
  return mapApiDataToCategoriesRes(data);
}

async function createCategory(category: CreateCategoryRequest) {
  if (category.type === TikiCategoryType.BRAND) {
    const brandSlug = getBrandSlug(category?.url || '');

    const brandInfo = await request(
      `${Config.NewAPIUrl}/v2/brands?slug=${encodeURIComponent(brandSlug)}`,
    );
    const brand = get(brandInfo, 'data[0]');

    if (!brand) {
      throw new Error(`Không tìm thấy brand với id ${category.tikiId}`);
    }

    const customCategory = {
      ...category,
      name: brand.name,
      slug: brand.slug,
    };
    const data = await apiRequest(`/tiki-categories`, {
      method: 'POST',
      data: customCategory,
    });
    return data;
  }
  if (category.type === TikiCategoryType.AUTHOR) {
    const authorSlug = getAuthorSlug(category?.url || '');

    const authorInfo = await request(
      `${Config.NewAPIUrl}/v2/authors?slug=${encodeURIComponent(authorSlug)}`,
    );
    const author = get(authorInfo, 'data[0]');

    if (!author) {
      throw new Error(`Không tìm thấy tác giả với id ${category.tikiId}`);
    }

    const customCategory = {
      ...category,
      name: author.name,
      slug: author.slug,
    };
    const data = await apiRequest(`/tiki-categories`, {
      method: 'POST',
      data: customCategory,
    });
    return data;
  }
  if (category.type === TikiCategoryType.SEARCH) {
    const customCategory = {
      ...category,
      name: decodeURIComponent((category?.tikiId || '')?.toString()),
      slug: `search/q=${(category?.tikiId)}`,
    };

    const data = await apiRequest(`/tiki-categories`, {
      method: 'POST',
      data: customCategory,
    });
    return data;
  }
  const categoryDetail = await request(`https://api.tiki.vn/v2/categories/${category.tikiId}`);

  if (!categoryDetail) {
    throw new Error(`Không tìm thấy category với id ${category.tikiId}`);
  }

  const customCategory = {
    ...category,
    name: categoryDetail.name,
    slug: categoryDetail.url_key,
    level: categoryDetail.level,
  };
  const data = await apiRequest(`/tiki-categories`, {
    method: 'POST',
    data: customCategory,
  });
  return data;
}
async function updateCategory(category: UpdateCategoryRequest) {
  return apiRequest(`/tiki-categories/${category.id}`, {
    method: 'PUT',
    data: category,
  });
}

async function getCategory(req: CategoryRequest): Promise<any> {
  const data: any = await apiRequest(`/tiki-categories/${req.categoryId}`);

  const pages = (data.pages || [])
    .filter((page: Page) => page.status)
    .sort((a: Page, b: Page) => (moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1));

  return {
    ...data,
    pages,
  };
}

export default {
  getCategories,
  createCategory,
  updateCategory,
  getCategory,
};
