import Box from '@/components/Box';
import Delete from '@/components/CustomIcon/Delete';
import Edit from '@/components/CustomIcon/Edit';
import Preview from '@/components/CustomIcon/Preview';
import { NgonThemeFormData } from '@/infras/ngon-theme/typing';
import { deleteNgonThemeHook } from '@/infras/ngon-theme/useCase';
import { Button, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { history } from 'umi';
import DeleteModalContent from './DeleteModalContent';

interface IThemeOperating {
  theme: NgonThemeFormData;
}

const ThemeOperating = ({ theme }: IThemeOperating) => {
  const { id } = theme;
  const [deleteModalVisible, handleDeleteModalVisible] = useState(false);

  const { fetch: deleteNgonTheme } = deleteNgonThemeHook();

  const handlePreview = () => {
    history.push({
      pathname: `/ngon/${id}`,
      query: {
        view: 'mobile',
      },
    });
  };

  const handleEdit = () => {
    history.push({
      pathname: '/ngon/create-theme',
      query: {
        id,
      },
    });
  };

  const openDeleteModal = () => {
    handleDeleteModalVisible(true);
  };

  const handleDelete = () => {
    deleteNgonTheme({ id: id as string | number });
    handleDeleteModalVisible(false);
  };

  const closeDeleteModal = () => {
    handleDeleteModalVisible(false);
  };

  return (
    <Box display="flex">
      <Box mr="5px">
        <Tooltip title="Xem trước">
          <Button onClick={handlePreview} icon={<Preview />} style={{ borderRadius: '50%' }} />
        </Tooltip>
      </Box>

      <Box mr="5px">
        <Tooltip title="Chỉnh sửa">
          <Button onClick={handleEdit} icon={<Edit />} style={{ borderRadius: '50%' }} />
        </Tooltip>
      </Box>

      <Box>
        <Tooltip title="Xóa">
          <Button
            onClick={openDeleteModal}
            icon={<Delete />}
            style={{ borderRadius: '50%', borderColor: '#FF424F' }}
          />
        </Tooltip>
      </Box>

      <Modal
        key="delete"
        visible={deleteModalVisible}
        centered
        width={400}
        bodyStyle={{ borderRadius: 8, padding: 16 }}
        footer={null}
        onCancel={closeDeleteModal}
        closable={false}
      >
        <DeleteModalContent onCancel={closeDeleteModal} onConfirm={handleDelete} />
      </Modal>
    </Box>
  );
};

export default ThemeOperating;
