import { Form, Input } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImagesUpload from '@/components/ImagesUpload/ImagesUpload';
import { EditSettingsProps } from '..';
import TightFormItem from '../shared/TightFormItem.styled';
import NameFieldItemSettings from '../shared/NameFieldItem';
import { RedeemWidget } from './types';

const horizontalLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const StyledFormItem = styled(Form.Item)`
  > .ant-form-item-control {
    > .ant-form-item-control-input {
      > .ant-form-item-control-input-content {
        > .ant-radio-group {
          > .ant-radio-button-wrapper {
            width: 130px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

const RedeemWidgetSettings: FC<EditSettingsProps<RedeemWidget>> = ({ widget }) => {
  return (
    <TightFormItem labelAlign="left">
      <h2>Redeem</h2>

      <NameFieldItemSettings widgetId={widget.id || ''} />

      <StyledFormItem
        rules={[
          {
            required: true,
            message: 'Yêu cầu upload 1 hình ảnh'
          }
        ]}
        label="Mobile banner" name="mobileImage" {...horizontalLayout}>
        <ImagesUpload
          maxImage={1}
        />
      </StyledFormItem>

      <StyledFormItem
        rules={[
          {
            required: true,
            message: 'Yêu cầu upload 1 hình ảnh'
          }
        ]}
        label="Desktop banner"
        name="desktopImage" {...horizontalLayout}>
        <ImagesUpload
          maxImage={1}
        />
      </StyledFormItem>

      <StyledFormItem label="CTA url" name="ctaUrl" {...horizontalLayout}>
        <Input placeholder="Mặc định là https://tiki/vn?src=redeem-success" />
      </StyledFormItem>

    </TightFormItem>
  );
};

export default RedeemWidgetSettings;
