import { TimerWidget, TimeSlot } from '@/components/widgets/timer/types';
import { WidgetType } from '@/infras/widget/constants';
import { Widget } from '@/infras/widget/typing';
import { updateWidgetAction } from '@/infras/widget/useCase';
import {
  createChildWidgetAction,
  removeChildWidgetAction,
  setWidgetMetaAction,
  updateChildWidgetAction,
  updateChildWidgetsIndex,
  cloneChildWidgetAction,
} from '@/infras/widgetMeta/useCase';
import childServices from '@/services/widgets/child-widgets';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { widgetMetaContext } from '../infras/widgetMeta/constants';
import { WidgetMetaState } from '../infras/widgetMeta/typing';
import { ConnectState } from './connect';

const initState: WidgetMetaState = {};

const builder = new DvaModelBuilder(initState, widgetMetaContext);

builder.immer(setWidgetMetaAction, (state, { widget, meta }) => {
  state[widget.id] = meta;

  return state;
});

builder
  .takeLatest(createChildWidgetAction.execute, function* (
    { widget, parent },
    { select, call, put },
  ) {
    try {
      const metaData = yield select((state: ConnectState) => state.widgetMeta);
      const parentWidget: Widget = yield select(
        (state: ConnectState) => state.pageWidgets[parent.id],
      );
      if (parentWidget) {
        let resp = null;
        // After created success, call update parent widget
        if (parentWidget.type === WidgetType.TIMER) {
          // Handle side effect for timer widget
          const newParent: TimerWidget = cloneDeep(parentWidget);
          if (metaData[parentWidget.id].timeSlot) {
            const activeTimeSlot = metaData[parentWidget.id].timeSlot;
            const updateSlot = newParent.payload.slots.find((slot: TimeSlot) => {
              return slot.id === activeTimeSlot.id;
            });
            const updateSlotWidgets = updateSlot?.widgets;
            if (updateSlotWidgets) {
              resp = yield call(childServices.createChildWidget, parentWidget, {
                ...widget,
                index: updateSlotWidgets.length,
              });
              // Push new created widget to slot
              updateSlotWidgets.push(resp.id);
              yield put.resolve(updateWidgetAction.execute(newParent));

              // Then update meta data
              yield put(setWidgetMetaAction({ widget: newParent, meta: { timeSlot: updateSlot } }));
              yield put(createChildWidgetAction.success(resp));
            }
          }
        }
        if (!resp) {
          yield put(createChildWidgetAction.fail());
        }
      }
    } catch (err) {
      yield put(createChildWidgetAction.fail(err));
    }
  })
  .takeLatest(updateChildWidgetAction.execute, function* (widget, { select, call, put }) {
    try {
      const parent: Widget | undefined = yield select(
        (state: ConnectState) => state.pageWidgets[widget.parent_id],
      );

      if (parent) {
        const response = yield call(childServices.updateChildWidget, parent, widget);
        yield put(
          updateWidgetAction.success({
            response: { ...response, payload: JSON.parse(response.payload), parent_id: parent.id },
          }),
        );
        yield put(updateChildWidgetAction.success(response));
      }
    } catch (err) {
      yield put(updateChildWidgetAction.fail(err));
    }
  })
  .takeLatest(removeChildWidgetAction.execute, function* (
    { widget, parent },
    { select, call, put },
  ) {
    try {
      const metaData = yield select((state: ConnectState) => state.widgetMeta);

      if (parent) {
        const { timeSlot } = metaData[parent.id];
        const resp = yield call(childServices.removeChildWidget, parent, widget);

        if (parent.type === WidgetType.TIMER) {
          // Handle side effect for timer widget after delete child
          const newParent: TimerWidget = cloneDeep(parent);
          if (timeSlot) {
            const updatedSlot = find(newParent.payload.slots, { id: timeSlot.id });
            if (updatedSlot) {
              updatedSlot.widgets = updatedSlot.widgets.filter((idx) => idx !== resp.id);
            }
            // After that, also set update for meta

            yield put(setWidgetMetaAction({ widget: parent, meta: { timeSlot: updatedSlot } }));
            yield put.resolve(updateWidgetAction.execute(newParent));
          }
        }

        yield put(removeChildWidgetAction.success(resp));
      }
    } catch (err) {
      yield put(removeChildWidgetAction.fail(err));
    }
  });

builder.takeLatest(updateChildWidgetsIndex.execute, function* (
  { widget, childWidgetsIndexOrder, timeSlot },
  { call, put },
) {
  try {
    const newWidget: TimerWidget = cloneDeep(widget);
    const updateSlot = newWidget.payload.slots.find((slot: TimeSlot) => {
      return slot.id === timeSlot?.id;
    }) || { widgets: [] };
    updateSlot.widgets = childWidgetsIndexOrder;
    const childWidgetsIndexData = {};
    childWidgetsIndexOrder.forEach((id, index) => {
      childWidgetsIndexData[id] = index;
    });

    yield put.resolve(
      setWidgetMetaAction({
        widget,
        meta: { timeSlot: { ...updateSlot, widgets: childWidgetsIndexOrder } as any },
      }),
    );
    yield put.resolve(updateWidgetAction.execute(newWidget));
    yield call(childServices.updateChildWidgetsIndex, {
      parentWidgetId: widget.id,
      childWidgetsIndexData,
    });

    yield put(updateChildWidgetsIndex.success());
  } catch (error) {
    yield put(updateChildWidgetsIndex.fail(error));
  }
});

builder.takeLatest<any>(cloneChildWidgetAction.execute, function* (
  { widget, parent },
  { call, put, select }: EffectsCommandMap,
) {
  try {
    const metaData = yield select((state: ConnectState) => state.widgetMeta);
    const parentWidget: Widget = yield select(
      (state: ConnectState) => state.pageWidgets[parent.id],
    );
    if (parentWidget) {
      let resp = null;
      // After created success, call update parent widget
      if (parentWidget.type === WidgetType.TIMER) {
        // Handle side effect for timer widget
        const newParent: TimerWidget = cloneDeep(parentWidget);
        if (metaData[parentWidget.id].timeSlot) {
          const activeTimeSlot = metaData[parentWidget.id].timeSlot;
          const updateSlot = newParent.payload.slots.find((slot: TimeSlot) => {
            return slot.id === activeTimeSlot.id;
          });
          const updateSlotWidgets = updateSlot?.widgets;
          if (updateSlotWidgets) {
            resp = yield call(childServices.createChildWidget, parentWidget, widget);
            // Push new created widget to slot
            updateSlotWidgets.splice(widget.index, 0, resp.id);
            yield put.resolve(updateWidgetAction.execute(newParent));

            // Then update meta data
            yield put(setWidgetMetaAction({ widget: newParent, meta: { timeSlot: updateSlot } }));
            yield put(createChildWidgetAction.success(resp));
          }
        }
      }
      if (!resp) {
        yield put(createChildWidgetAction.fail());
      }
    }
    yield put(cloneChildWidgetAction.success());
  } catch (error) {
    yield put(cloneChildWidgetAction.fail(error));
  }
});
export default builder.build();
