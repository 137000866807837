import { RoleFormData, RolesRequest } from '@/infras/role/typing';
import { apiRequest } from '@/utils/request';
import { stringify } from 'qs';

async function getRoles(req: RolesRequest): Promise<any> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/roles?${qs}`);
  return { data, total: data.length };
}

async function createRole(role: RoleFormData) {
  const customHandle = true;
  const data = await apiRequest(
    `/roles`,
    {
      method: 'POST',
      data: role,
    },
    customHandle,
  );
  return data;
}

async function updateRole(role: RoleFormData) {
  return apiRequest(`/roles/${role.id}`, {
    method: 'PUT',
    data: role,
  });
}

export default { getRoles, createRole, updateRole };
