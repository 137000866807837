import { EditorType } from '@/infras/brick-editor/typing';
import { Page } from '@/infras/page/typing';
import { Widget } from '@/infras/widget/typing';
import { noop } from 'lodash';
import { createContext } from 'react';

export type EditorState = '' | 'EDIT' | 'ADD_WIDGET' | 'ADD_CHILD' | 'SETTING';

export interface IEditorContext {
  fieldSettingContainer: HTMLElement | null;
  toggleFieldSettings: (show?: boolean) => void;
  showFieldSettings: boolean;
  isDirty: boolean;
  editorType: EditorType;
  editorState: EditorState;
  pageData?: Page;
  setEditorState: (nextState: EditorState, meta?: any) => void;
  selectedWidget?: Widget;
  onSelectWidget: (widget: Widget) => void;
  setDirtyPage: (isDirty: boolean) => void;
  selectedChildWidgetId: string;
  setSelectedChildWidgetId: (id: string) => void;
}

export const EditorContext = createContext<IEditorContext>({
  fieldSettingContainer: null,
  showFieldSettings: false,
  toggleFieldSettings: () => {},
  isDirty: false,
  editorType: EditorType.CampaignPage,
  editorState: '',
  setEditorState: noop,
  onSelectWidget: noop,
  setDirtyPage: noop,
  selectedChildWidgetId: '',
  setSelectedChildWidgetId: noop,
});
