export const checkExtension = (src = '', ext = '') => {
  if (!src || !ext || !src.endsWith) return false;
  return src.endsWith(`.${ext}`);
};

export const resizeImageHasCache = (src: string = '', width: number, height?: number) => {
  let dimension = `w${width}`;
  if (height) {
    dimension = `${width}x${height}`;
  }
  try {
    if (checkExtension(src, 'gif')) {
      return src;
    }
    if (src && src.indexOf('/ts/') !== -1) {
      const arr = src.split('/ts/');
      if (src.indexOf('/cache/') === -1) {
        return `${arr[0]}/cache/${dimension}/ts/${arr[1]}`;
      }
      if (src.indexOf('/cache/') !== -1) {
        const arrCache = src.split('/cache/');
        return `${arrCache[0]}/cache/${dimension}/ts/${arr[1]}`;
      }
      return src;
    }
    const arr = src.split('/');
    arr.forEach((slug, index) => {
      if (slug === 'cache') {
        arr[index + 1] = dimension;
      }
    });
    src = arr.join('/');
    return src;
  } catch (e) {
    return src;
  }
};
