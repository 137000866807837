import Box from '@/components/Box';
import Edit from '@/components/CustomIcon/Edit';
import { NgonThemeFormData } from '@/infras/ngon-theme/typing';
import { orderNgonThemeHook } from '@/infras/ngon-theme/useCase';
import { ThemeOrderLimitEnum, ThemeTypeEnum } from '@/pages/create-ngon-theme/constant';
import { Button, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import ReorderTheme from '../ReorderTheme';

interface IThemePosition {
  theme: NgonThemeFormData;
}

const StyledText = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 30px;
  color: #38383d;
`;

const getThemePositionText = (priority: number, type?: ThemeTypeEnum | any) => {
  if (!priority || priority <= 0) return '－';

  return type === ThemeTypeEnum.CREATIVE
    ? ThemeOrderLimitEnum.CREATIVE - priority + 1
    : ThemeOrderLimitEnum.CREATIVE - priority + 1;
};

const ThemePosition = ({ theme }: IThemePosition) => {
  const [sortModalVisible, handleSortModalVisible] = useState<boolean>(false);
  const {
    fetch: orderNgonTheme,
    state: { request },
  } = orderNgonThemeHook();

  const { isDisplayed } = theme || {};
  const handleEdit = () => {
    handleSortModalVisible(true);
  };

  const closeSortModal = () => {
    handleSortModalVisible(false);
  };

  const handleUpdateOrder = (data: any) => {
    const orderData = data.map((theme: any, index: number) => ({
      id: theme.id,
      priority:
        theme?.type === ThemeTypeEnum.CREATIVE
          ? ThemeOrderLimitEnum.CREATIVE - index
          : ThemeOrderLimitEnum.CREATIVE - index,
    }));

    orderNgonTheme({ data: orderData, request });
    handleSortModalVisible(false);
  };
  return (
    <>
      <Box display="flex" alignItems="center" pr="8px">
        <StyledText>
          {getThemePositionText(theme?.priority, theme?.type || ThemeTypeEnum.CREATIVE)}
        </StyledText>
        {!!isDisplayed && (
          <Box ml="5px">
            <Tooltip title="Chỉnh sửa">
              <Button onClick={handleEdit} icon={<Edit />} style={{ borderRadius: '50%' }} />
            </Tooltip>
          </Box>
        )}
      </Box>

      <Modal
        key="order"
        visible={sortModalVisible}
        centered
        width={800}
        bodyStyle={{ borderRadius: 8, padding: '24px 32px', height: 400 }}
        footer={null}
        onCancel={closeSortModal}
        destroyOnClose
      >
        <ReorderTheme
          onCancel={closeSortModal}
          onConfirm={handleUpdateOrder}
          themeType={theme.type}
          sortModalVisible={sortModalVisible}
        />
      </Modal>
    </>
  );
};

export default ThemePosition;
