export enum ViewPosition {
  NGON_HOME = 1,
  STORE_TIKINGON = 2,
  NON_DISPLAY = 0,
}

export enum ThemeTypeEnum {
  PROMOTION = 'PROMOTION',
  PARTNERSHIP = 'PARTNERSHIP',
  CREATIVE = 'CREATIVE',
}

export enum ThemeOrderLimitEnum {
  PROMOTION = 6,
  CREATIVE = 10,
}

export enum CollectionTypeEnum {
  STORE = 'STORE',
  PRODUCT = 'PRODUCT',
}
