import Config from '@/config';
import { apiRequest } from '@/utils/request';
import { concat } from 'lodash';
import request from 'umi-request';
import { TikiCategory } from '@/infras/tiki/typing';

export async function getCategoryById(categoryId: number): Promise<TikiCategory | null> {
  const data: any = await apiRequest(`/tiki-categories/${categoryId}`);

  if (data?.id) {
    return data;
  }

  return null;
}

// Start fetching down below the tree
async function fetchChilds(parentId: number): Promise<TikiCategory[]> {
  let childs = [];
  try {
    const resp = await request.get(`${Config.APIGateway}/v2/categories`, {
      params: {
        parent_id: parentId,
        limit: 999,
        is_active: 1,
        include_promotion: 0,
      },
    });
    childs = resp.data || [];
  } catch (err) {
    // Do nothing
  }

  return childs;
}

const SAVED_KEY = 'savedTikiCategories';
type SavedData = {
  data: TikiCategory[];
  expiredAt: number;
};
export async function getCategories({ parentId }: { parentId: number }): Promise<any> {
  const savedData = localStorage.getItem(SAVED_KEY);
  if (savedData) {
    try {
      const { data, expiredAt }: SavedData = JSON.parse(savedData);
      if (expiredAt > Date.now()) {
        // Only return if cached data hasn't been expired
        return data;
      }
    } catch (err) {
      // If error, continue
    }
  }
  let categories: TikiCategory[] = await fetchChilds(parentId);
  let waiting = concat(categories, []);

  while (waiting.length) {
    const top = waiting.pop();
    if (top && !top.is_leaf) {
      // eslint-disable-next-line no-await-in-loop
      const childs = await fetchChilds(top.id);
      waiting = concat(waiting, childs);
      categories = concat(categories, childs);
    }
  }

  const expiredAt = Date.now() + 1000 * 60 * 60 * 24 * 30; // caching 30 day
  localStorage.setItem(
    SAVED_KEY,
    JSON.stringify({
      data: categories,
      expiredAt,
    } as SavedData),
  );
  return categories;
}
