import { RuntimeConfig } from './types';

const prodConfig: RuntimeConfig = {
  TikiFrontend: 'https://tiki.vn',
  OAuthEndpoint: 'https://api.tiki.vn/oauth2',
  BrickAPI: 'https://api.tiki.vn/brickv2/api',
  SpectrumAPI: 'https://api.tiki.vn/spectrum',
  SOAPI: 'https://api.tiki.vn/brickv2',
  SOPath: '/brickv2/socket.io',
  BrickAppDomain: 'brick.tiki.vn',
  TikiAPI: 'https://tiki.vn/api/v2',
  APIGateway: 'https://api.tiki.vn',
  TequilaAPI: 'https://api.tiki.vn/tequila/v1/consumer',
  OAuthBasicToken: 'dGlraS1idWlsZGVyLXVpOmlzLXB1YmxpYw==',
  SentryDSN: 'https://481489c6dfa1453b821adc06e600af93@sentry-next.tiki.com.vn/18',
  NewAPIUrl: 'https://api.tiki.vn',
};

export default prodConfig;
