import React from "react";

const Success = ({ ...props }) => {
  return (
    <img
      src="https://salt.tikicdn.com/ts/brickv2og/04/c3/df/1f88b070e5e2383c1083ff55fae2a461.png"
      width="20"
      height="20"
      {...props}
    />
  );
};

export default Success;
