import { createContext } from 'react';
import io from 'socket.io-client';
import Config from '../../config';

export const WebSocketContext = createContext<any>(null);

const WebSocket = () => {
  let socket: any;
  const connect = (payload: any) => {
    if (!socket || !socket?.connected) {
      socket = io(Config.SOAPI, {
        query: payload,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
        path: Config.SOPath,
      });
    }
  };
  const disconnect = () => {
    if (socket && socket?.connected) {
      socket.disconnect();
    }
    return { connect: false, socket };
  };

  const ws = {
    socket,
    connect,
    disconnect,
  };
  return ws;
};
const websocket = WebSocket();

export default websocket;
