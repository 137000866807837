export interface WithId {
  id: number | string;
}

export function mapObjectsById<T extends WithId>(objects: T[]): Map<number | string, T> {
  return objects.reduce((prev, cur) => {
    prev.set(cur.id, cur);
    return prev;
  }, new Map<number | string, T>());
}
