import { Widget } from '@/infras/widget/typing';

export enum CollectionType {
  GRID = 'grid',
  HORIZONTAL = 'horizontal'
}

export interface ICollectionItemSettings {
  title: string;
  subTitle: string;
  index: number;
  uid: string;
  clickUrl: string;
  url: string;
  height?: number;
  width?: number;
}

export interface ItemSettings<T = any> {
  type: T;
  count: number;
  minImage: number;
  maxImage: number;
  images: ICollectionItemSettings[];
}

export interface CategoryCollectionSettings {
  title: string;
  placement: string;
  layout: CollectionType;
  items: ICollectionItemSettings[];
  borderRadius?: boolean;
  showMax: number;
}

export type CategoryCollectionWidget = Widget<CategoryCollectionSettings>;
