import { hasUpdateCategoryAuthority } from '@/components/Authorized/CheckPermissions';
import Box from '@/components/Box';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Category } from '@/infras/category/typing';
import UpdateForm from '@/pages/categories/components/UpdateForm';

interface Props {
  category: Category;
}

const CategoryOperating: FunctionComponent<Props> = ({ category }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const openUpdateModal = useCallback(() => {
    setVisible(true);
  }, []);
  const handleVisibleModal = useCallback((visible) => {
    setVisible(visible);
  }, []);
  return (
    <Box display="flex" justifyContent="center">
      <Tooltip title="Chỉnh sửa">
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={openUpdateModal}
          disabled={!hasUpdateCategoryAuthority()}
        />
        <UpdateForm
          category={category}
          modalVisible={visible}
          handleVisibleModal={handleVisibleModal}
        />
      </Tooltip>
    </Box>
  );
};

export default CategoryOperating;
