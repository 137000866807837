/* eslint-disable prefer-promise-reject-errors */
import Box from '@/components/Box';
import ImagesUpload from '@/components/ImagesUpload/ImagesUpload';
import { isURL } from '@/components/widgets/util';
import { Button, Form, Input, Modal, notification } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useState } from 'react';

const defaultCampaignForm = {};
const CampaignForm: React.FC<any> = ({
  form,
  modalVisible,
  handleVisibleModal,
  mode,
  title,
  theme = defaultCampaignForm,
  onSubmit,
  loading = false,
}) => {
  const [slugError, setSlugError] = useState('');
  const [formValues, setFormValues] = useState<any>({});

  const resetForm = useCallback(() => {
    handleVisibleModal(false);
    setSlugError('');
    form.resetFields();
  }, [form, handleVisibleModal]);

  const onFinish = useCallback(
    (values: any) => {
      onSubmit(values)
        .then(() => {
          resetForm();
        })
        .catch((error: any) => {
          const originalErrorMessage = error?.data?.errorMessage;
          const isDuplicateCreation =
            originalErrorMessage && originalErrorMessage.includes('ER_DUP_ENTRY');
          if (isDuplicateCreation) {
            setSlugError(isDuplicateCreation && 'Đường dẫn đã tồn tại!');
          } else {
            notification.error({
              message: 'Request error',
              description: 'Đã xảy ra lỗi khi tạo quicklink mới',
            });
          }
        });
    },
    [onSubmit, resetForm],
  );

  const isUploadingImage = Boolean(get(formValues, 'imageUrl[0].name'));

  return (
    <Modal
      title={title}
      visible={modalVisible}
      maskClosable={false}
      onCancel={resetForm}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={theme}
        validateMessages={{
          // eslint-disable-next-line
          required: 'Vui lòng nhập ${label}',
        }}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <Form.Item name="title" label="Tên quick link" rules={[{ required: true }]}>
          <Input maxLength={70} />
        </Form.Item>
        <Form.Item
          name="url"
          label="Đường Dẫn"
          shouldUpdate
          rules={[
            { required: true },
            () => ({
              validator(rule, url) {
                if (url && !isURL(url)) {
                  return Promise.reject(Error('Vui lòng điền vào đường dẫn hợp lệ'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          validateStatus={slugError ? 'error' : ''}
          help={slugError || undefined}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 0 }}
          name="imageUrl"
          label="Hình"
          rules={[{ required: true }]}
        >
          <ImagesUpload isReturnUrl />
        </Form.Item>
        <Box mb="20px" display="block" as="i" color="silver">
          (Kích thước đề xuất: 100px x 100px)
        </Box>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{ margin: '0 auto', display: 'block' }}
            loading={loading}
            disabled={isUploadingImage}
          >
            {mode === 'create' ? 'Tạo' : 'Lưu'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CampaignForm;
