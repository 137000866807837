export const collectionPagesContext = 'widget-collection-page';

export enum ViewType {
  GRID = 'grid',
  LIST = 'list',
}

export const COLLECTION_ITEMS_LIMIT = 10;

export enum WidgetCollectionPageType {
  Normal = 0,
  TikiNGON = 1,
}
