import { getCampaignAction } from '@/infras/campaign/useCase';
import { getCategoryAction } from '@/infras/category/useCase';
import {
  clonePageAction,
  createPageAction,
  deletePage,
  getPages,
  requestPageAction,
  updatePageAction,
  updateWidgetsIndex,
} from '@/infras/page/useCase';
import { getWidgets } from '@/infras/widget/useCase';
import pagesServices from '@/services/pages';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { getListMapData, getPageType } from '@/utils';
import { each } from 'lodash';
import { pagesContext } from '../infras/page/constants';
import { Page, PageFormData, PagesState } from '../infras/page/typing';

const initState: PagesState = {};

const builder = new DvaModelBuilder(initState, pagesContext);
builder.immer(getPages, (state: PagesState, { response }: any) => {
  // Fix if getPages come after requestPageAction
  // which cause `widgets` field of each page to be null! 
  each(response, (value, pageId) => {
    state[pageId] = {
      ...state[pageId],
      ...value,
    };
  });
  return state;
});

builder.takeLatest<any>(createPageAction.execute, function* (
  payload: PageFormData,
  { call, put }: EffectsCommandMap,
) {
  const { campaignId, categoryId } = payload;
  const { isCampaignPage, isCategoryPage } = getPageType(payload);

  try {
    yield call(pagesServices.createPage, payload);

    if (isCampaignPage) {
      yield put.resolve(
        getCampaignAction.execute({
          campaignId: campaignId as number,
          filter: {
            page: 1,
            limit: 10,
          },
        }),
      );
    } else if (isCategoryPage) {
      yield put.resolve(
        getCategoryAction.execute({
          categoryId: categoryId as number,
        }),
      );
    }

    yield put(createPageAction.success());
  } catch (err) {
    yield put(createPageAction.fail(err));
  }
});

builder.takeLatest<any>(clonePageAction.execute, function* (
  payload: PageFormData,
  { call, put }: EffectsCommandMap,
) {
  const { campaignId, categoryId } = payload;
  const { isCampaignPage, isCategoryPage } = getPageType(payload);

  try {
    yield call(pagesServices.clonePage, payload);

    if (isCampaignPage) {
      yield put.resolve(
        getCampaignAction.execute({
          campaignId: campaignId as number,
          filter: {
            page: 1,
            limit: 10,
          },
        }),
      );
    } else if (isCategoryPage) {
      yield put.resolve(
        getCategoryAction.execute({
          categoryId: categoryId as number,
        }),
      );
    }

    yield put(clonePageAction.success());
  } catch (err) {
    yield put(clonePageAction.fail(err));
  }
});

builder.takeLatest<any>(deletePage.execute, function* (
  payload: PageFormData,
  { call, put }: EffectsCommandMap,
) {
  const { campaignId, categoryId } = payload;
  const { isCampaignPage, isCategoryPage } = getPageType(payload);

  try {
    yield call(pagesServices.deletePage, payload);

    if (isCampaignPage) {
      yield put.resolve(
        getCampaignAction.execute({
          campaignId: campaignId as number,
          filter: {
            page: 1,
            limit: 10,
          },
        }),
      );
    } else if (isCategoryPage) {
      yield put.resolve(
        getCategoryAction.execute({
          categoryId: categoryId as number,
        }),
      );
    }

    yield put(deletePage.success());
  } catch (err) {
    yield put(deletePage.fail(err));
  }
});

builder
  .immer(updatePageAction.success, (state: PagesState, { response }: { response: Page }) => {
    const { id } = response;
    state = { ...state, [id]: response };

    return state;
  })
  .takeLatest<any>(updatePageAction.execute, function* (
    request: PageFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      if (request.campaignId) {
        request.campaignId = Number(request.campaignId);
      }
      if (request.categoryId) {
        request.categoryId = Number(request.categoryId);
      }

      const response = yield call(pagesServices.updatePage, request);
      yield put(updatePageAction.success({ response }));
    } catch (error) {
      yield put(updatePageAction.fail(error));
    }
  });

builder
  .immer(
    requestPageAction.success,
    (state: PagesState, { request, response }: { request: { pageId: string }; response: Page }) => {
      const { pageId } = request;

      state = {
        ...state,
        [pageId]: response,
      };

      return state;
    },
  )
  .takeLatest<any>(requestPageAction.execute, function* (
    request: { pageId: string },
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const page = yield call(pagesServices.getPage, request);
      const { mapData: widgetMap, list: widgetList } = getListMapData(page.widgets);

      yield put.resolve(getWidgets({ response: widgetMap }));
      yield put(requestPageAction.success({ request, response: { ...page, widgets: widgetList } }));
    } catch (err) {
      yield put(requestPageAction.fail(err));
    }
  });

builder.takeLatest<any>(updateWidgetsIndex, function* (
  request: { pageId: string; widgets: string[] },
  { call, put }: EffectsCommandMap,
) {
  const { pageId, widgets } = request;
  try {
    if (widgets.length) {
      yield call(pagesServices.updateWidgetsIndex, request);
    }
    yield put.resolve(
      requestPageAction.execute({
        pageId,
      }),
    );
  } catch (error) {
    yield put(updatePageAction.fail(error));
  }
});
export default builder.build();
