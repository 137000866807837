import { WidgetType } from '@/infras/widget/constants';
import { Widget } from '@/infras/widget/typing';
import TextSettings from './RichTextSettings';
import RichTextView from './RichTextView';
import RichTextDraggable from './RichTextDraggable';
import { ICmsRichTextSettings } from './types';

const widget = {
  type: WidgetType.CMS_RICH_TEXT,
  View: RichTextView,
  Settings: TextSettings,
  Draggable: RichTextDraggable,
  default: {
    type: WidgetType.CMS_RICH_TEXT,
    payload: {
      text: {},
    },
  } as Partial<Widget<ICmsRichTextSettings>>,
};

export default widget;
