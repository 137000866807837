import { ListProps } from '@/typings/list';
import {
  CategoriesRequest,
  CategoriesRequestData,
  CategoryFormData,
  CategoryUpdateFormData,
  CreateCategoryRequest,
  UpdateCategoryRequest,
} from '@/infras/category/typing';

export const mapApiDataToCategoriesRes = (data: any): ListProps<any> => {
  const result = {
    data: data.items,
    total: data.meta.totalItems,
  };
  return result;
};
export const mapDataToUpdateCategoryReq = (
  category: CategoryUpdateFormData,
): UpdateCategoryRequest => {
  return {
    h1: category.h1,
    id: category.id,
    status: category.status || 0,
    footerContent: category.footerContent || '',
    metaDescription: category?.metaDescription || '',
    metaTitle: category?.metaTitle || '',
    relevantLinks: JSON.stringify(category.relevantLinks),
  };
};
export const mapDataCategoryReq = (request: CategoriesRequestData): CategoriesRequest => {
  if (!request) return { limit: 10, page: 1 };
  const filter = request?.filter ? { [request.filter.key]: request.filter.value } : {};
  return {
    limit: request.limit,
    page: request.page,
    ...filter,
    type: request?.type,
  };
};

export const mapDataToCreateCategoryReq = (category: CategoryFormData): CreateCategoryRequest => {
  return {
    ...category,
    tikiId: category.tikiId,
    footerContent: category.footerContent || '',
    relevantLinks: JSON.stringify(category.relevantLinks),
  };
};
