import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { pagesContext } from './constants';
import { Page, PageFormData, PagesState } from './typing';

export const { action: getPages, hook: getPagesHook } = createUseCase<{
  response: any;
}>(pagesContext, 'getPages');

export const { action: deletePage, hook: deletePageHook } = createAsyncUseCase<
  PageFormData,
  Page,
  PagesState
>(pagesContext, 'deletePage', {
  readableName: 'Xóa page',
  messageSettings: { initial: true, success: true },
});

export const { action: updateWidgetsIndex, hook: updateWidgetsIndexHook } = createUseCase<any>(
  pagesContext,
  'updateWidgetsIndex',
);

export const { action: createPageAction, hook: createPageHook } = createAsyncUseCase<
  PageFormData,
  Page,
  PagesState
>(pagesContext, 'createPageAction', {
  readableName: 'Tạo page mới',
  messageSettings: { initial: true, success: true },
});

export const { action: clonePageAction, hook: clonePageHook } = createAsyncUseCase<
  PageFormData,
  Page,
  PagesState
>(pagesContext, 'clonePageAction', {
  readableName: 'Clone page',
  messageSettings: { initial: true, success: true },
});

export const { action: updatePageAction, hook: updatePageHook } = createAsyncUseCase<
  PageFormData,
  { response: Page },
  PagesState
>(pagesContext, 'updatePageAction', {
  readableName: 'Update page',
  messageSettings: { initial: true, success: true },
});

export const { action: requestPageAction, hook: requestPageHook } = createAsyncUseCase<
  { pageId: string },
  { request: { pageId: string }; response: Page },
  PagesState
>(pagesContext, 'requestPageAction');
