import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ViewProps } from '..';
import { IRichTextSettings, RichTextWidget } from './types';
import { parseQuillJsonToHTMLList } from '@/utils/quillTextParser';

export interface RichTextViewProps extends ViewProps<RichTextWidget> {}

const RichTextContainer = styled.div`
  background-color: ${(props: Omit<IRichTextSettings, 'text'>) => props.backgroundColor};
  color: ${(props: Omit<IRichTextSettings, 'text'>) => props.color};
  padding: 8px 16px;
  p {
    display: contents;
    line-height: 150%;
  }
  * {
    color: inherit;
  }
  a {
    color: #40a9ff;
    text-decoration: underline;
  }
  .ql-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`;

const RichTextView: FC<RichTextViewProps> = ({ widget: { payload } }) => {
  const elements = useMemo(() => {
    const ops = payload?.text?.ops || [];
    return parseQuillJsonToHTMLList(ops);
  }, [payload]);
  return (
    <RichTextContainer
      className="rich-text-container"
      color={payload.color}
      backgroundColor={payload.backgroundColor}
    >
      {elements}
    </RichTextContainer>
  );
};

export default RichTextView;
