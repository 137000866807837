import Config from '@/config';
import { CollectionPageType } from '@/infras/collection-page/constants';
import { CollectionPage } from '@/infras/collection-page/typing';
import { PermissionType } from '@/infras/permission/constants';
import { Role } from '@/infras/role/typing';

export const prefixCollectionPageUrl = `${Config.TikiFrontend}/`;

export const getCollectionPageUrl = (collectionPage: CollectionPage) => {
  const { slug, url, id } = collectionPage;
  return `${prefixCollectionPageUrl}${
    collectionPage.pageType === CollectionPageType.TikiNGON ? 'di-cho-online/' : ''
  }bo-suu-tap/${slug || url}-b${id}`;
};

export const getUserPermissionsFromRoles = (roles: Role[]) => {
  const finalPermissions = new Set<PermissionType>();
  roles.forEach((role) => {
    role.permissions.forEach((permission) => {
      finalPermissions.add(permission.name as PermissionType);
    });
  });
  return Array.from(finalPermissions);
};
