import {Category} from "@/infras/category/typing";
import React, {FC, useCallback, useState} from "react";
import {Switch} from "antd";
import {updateCategoryHook} from "@/infras/category/useCase";
import check from "@/components/Authorized/CheckPermissions";
import {PermissionType} from "@/infras/permission/constants";

interface CategoryStatusProps {
  category: Category
}

const CategoryStatus: FC<CategoryStatusProps> = ({
  category
}) => {
  const [checked, setCheck] = useState(!!category.status);
  const {
    fetch
  } = updateCategoryHook();
  const onCheckChange = useCallback((checked) => {
    setCheck(checked);
    fetch({
      ...category,
      status: checked ? 1 : 0
    });
  }, []);
  const hasAuthorized = check([PermissionType.ALL, PermissionType.TOGGLE_CATEGORY], true, false);

  return (
    <Switch checked={checked} onChange={() => onCheckChange(!checked)} disabled={!hasAuthorized} />
  )
}

export default CategoryStatus;
