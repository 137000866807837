import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { ngonQuickLinkContext } from './constants';
import {
  NgonQuickLink,
  NgonQuickLinkFormData,
  NgonQuickLinkRequest,
  NgonQuickLinksRequestData,
  NgonQuickLinksResponse,
  NgonQuickLinkState,
} from './typing';

export const { action: getNgonQuickLinksAction, hook: getNgonQuickLinksHook } = createAsyncUseCase<
  NgonQuickLinksRequestData,
  NgonQuickLinksResponse,
  NgonQuickLinkState
>(ngonQuickLinkContext, 'getNgonQuickLinks');

export const { action: getNgonQuickLinkAction, hook: getNgonQuickLinkHook } = createAsyncUseCase<
  NgonQuickLinkRequest,
  { request: NgonQuickLinkRequest; response: NgonQuickLink },
  NgonQuickLinkState
>(ngonQuickLinkContext, 'getNgonQuickLink');

export const {
  action: createNgonQuickLinkAction,
  hook: createNgonQuickLinkHook,
} = createAsyncUseCase<NgonQuickLinkFormData, NgonQuickLink, NgonQuickLinkState>(
  ngonQuickLinkContext,
  'createNgonQuickLinkAction',
  {
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: updateNgonQuickLinkAction,
  hook: updateNgonQuickLinkHook,
} = createAsyncUseCase<NgonQuickLink, NgonQuickLink, NgonQuickLinkState>(
  ngonQuickLinkContext,
  'updateNgonQuickLinkAction',
  {
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: deleteNgonQuickLinkAction,
  hook: deleteNgonQuickLinkHook,
} = createAsyncUseCase<{ id: string | number }, { id: string | number }, NgonQuickLinkState>(
  ngonQuickLinkContext,
  'deleteNgonQuickLinkAction',
  {
    readableName: 'Xóa theme',
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: orderNgonQuickLinkAction,
  hook: orderNgonQuickLinkHook,
} = createAsyncUseCase<{ data: any; request?: {} }, NgonQuickLinkState>(
  ngonQuickLinkContext,
  'orderNgonQuickLinkAction',
  {},
);

export const {
  action: getOrderNgonQuickLinkAction,
  hook: getOrderNgonQuickLinkHook,
} = createAsyncUseCase<{ data: []; request: {} }, { creative: []; promotion: [] }, any>(
  ngonQuickLinkContext,
  'getOrderNgonQuickLinkAction',
  {},
);

export const {
  action: resetNgonQuickLinkRequestAction,
  hook: resetNgonQuickLinkRequestHook,
} = createUseCase<any>(ngonQuickLinkContext, 'resetRequest');
