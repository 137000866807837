import { Widget } from '@/infras/widget/typing';

export enum StickyPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}
export interface NavigationTab {
  id: string;
  tabTitle: string;
  tabImage: string;
  anchorId?: string;
  redirectURL?: string;
}

export type NavigationSettings = {
  backgroundColor: string;
  color: string;
  sticky: boolean;
  stickyPosition: StickyPosition;
  tabs: NavigationTab[];
  name?: string;
};

export type NavigationWidget = Widget<NavigationSettings>;

export const WIDGET_TYPE = 'navigation';
