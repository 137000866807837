import { apiRequest } from '@/utils/request';
import { Widget } from '@/infras/widget/typing';

async function getChildWidgets(parent: Widget) {
  return apiRequest(`/widgets/${parent.id}/childs`, {
    method: 'GET',
  });
}

async function createChildWidget(parent: Widget, widget: Widget) {
  return apiRequest(`/widgets/${parent.id}/childs`, {
    method: 'POST',
    data: { ...widget, payload: JSON.stringify(widget.payload) },
  });
}
async function cloneChildWidget(parent: Widget, widget: Widget) {
  return apiRequest(`/widgets/${parent.id}/childs`, {
    method: 'POST',
    data: { ...widget, payload: JSON.stringify(widget.payload) },
  });
}

async function updateChildWidget(parent: Widget, widget: Widget) {
  return apiRequest(`/widgets/${parent.id}/childs/${widget.id}`, {
    method: 'PUT',
    data: { ...widget, payload: JSON.stringify(widget.payload) },
  });
}

async function removeChildWidget(parent: Widget, widget: Widget) {
  return apiRequest(`/widgets/${parent.id}/childs/${widget.id}`, {
    method: 'DELETE',
  });
}

async function updateChildWidgetsIndex({
  parentWidgetId,
  childWidgetsIndexData,
}: {
  parentWidgetId: string;
  childWidgetsIndexData: { [key: number]: number };
}) {
  await apiRequest(`/widgets/${parentWidgetId}/childs/index`, {
    method: 'PUT',
    data: childWidgetsIndexData,
  });
}

export default {
  getChildWidgets,
  createChildWidget,
  updateChildWidget,
  removeChildWidget,
  updateChildWidgetsIndex,
  cloneChildWidget,
};
