import { permissionsContext } from '@/infras/permission/constants';
import {
  PermissionsRequestData,
  PermissionsResponse,
  PermissionState,
} from '@/infras/permission/typing';
import { getPermissionsAction } from '@/infras/permission/useCase';
import permissionsServices from '@/services/permission';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { getListMapData } from '@/utils';

const initState = {
  permissionMap: {},
  permissionList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, permissionsContext);

builder
  .immer(
    getPermissionsAction.success,
    (state: PermissionState, { request, response }: PermissionsResponse) => {
      const { mapData: permissionMap, list: permissionList } = getListMapData(response.data);
      state.permissionMap = { ...state.permissionMap, ...permissionMap };
      state.permissionList = { data: permissionList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(
    getPermissionsAction.execute,
    function* (request: PermissionsRequestData, { call, put }: EffectsCommandMap) {
      try {
        const response = yield call(permissionsServices.getPermissions);
        yield put(getPermissionsAction.success({ request, response }));
      } catch (error) {
        yield put(getPermissionsAction.fail(error));
      }
    },
  );

export default builder.build();
