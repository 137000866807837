import { useNameField } from '@/pages/campaign/hooks/useNameField';
import { Form, Input } from 'antd';
import { map } from 'lodash';
import React, { FC } from 'react';
import { useParams } from 'umi';

const NameFieldItemSettings: FC<{ pageId?: string; widgetId?: string }> = (props) => {
  const { widgetId = '' } = props;
  const { pageId } = props.pageId || useParams<any>();

  const names = useNameField(pageId, widgetId);

  return (
    <Form.Item
      name="name"
      label="Tên widget"
      rules={[
        () => ({
          validator(rule, name) {
            if (
              name &&
              map(names, (name) => (name.value || '').toLowerCase()).includes(name.toLowerCase())
            ) {
              return Promise.reject(Error('Tên widget đã tồn tại'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input placeholder="Đặt tên cho widget (không bắt buộc)" />
    </Form.Item>
  );
};

export default NameFieldItemSettings;
