export enum CATEGORY_CAMPAIGN {
  BRAND = 'BRAND',
  UMBRELLA = 'UMBRELLA',
  SEASONAL = 'SEASONAL',
  CATEGORY = 'CATEGORY',
  TIKINGON = 'TIKINGON',
  TIKI_PROGRAM = 'TIKI_PROGRAM',
  BANK_PARTNERSHIP = 'BANK_PARTNERSHIP',
  INFORMATION = 'GENERAL_INFORMATION',
}

export const Categories = {
  [CATEGORY_CAMPAIGN.BRAND]: {
    key: CATEGORY_CAMPAIGN.BRAND,
    title: 'Brand',
    purpose: 'Run Brand campaign, including Super Brand Day',
    vnPurpose: 'Chương trình Brand/ Super Brand Day. VD: hang-tieu-dung-unilever',
    ex: 'hang-tieu-dung-unilever',
  },
  [CATEGORY_CAMPAIGN.UMBRELLA]: {
    key: CATEGORY_CAMPAIGN.UMBRELLA,
    title: 'Umbrella campaign',
    purpose: 'Run Umbrella campaign main landing page, attract awareness from the consumers.',
    vnPurpose: 'Chương trình Umbrella Campaign. VD: tiki-sale-1212-sieu-dinh',
    ex: 'tiki-sale-1212-sieu-dinh',
  },
  [CATEGORY_CAMPAIGN.SEASONAL]: {
    key: CATEGORY_CAMPAIGN.SEASONAL,
    title: 'Seasonal campaign',
    purpose: 'Run Seasonal campaign main landing page, promote potential selections',
    vnPurpose: 'Chương trình Seasonal Campaign. VD: yeu-bep-nghien-nha-giam-den-50-100-chinh-hang',
    ex: 'yeu-bep-nghien-nha-giam-den-50-100-chinh-hang',
  },
  [CATEGORY_CAMPAIGN.TIKINGON]: {
    key: CATEGORY_CAMPAIGN.TIKINGON,
    title: 'NGON campaign',
    purpose: 'Run NGON campaign main landing page, promote potential selections',
    vnPurpose: 'Chiến dịch của NGON. VD: tikingon-di-cho-online',
    ex: 'tikingon-di-cho-online',
  },
  [CATEGORY_CAMPAIGN.CATEGORY]: {
    key: CATEGORY_CAMPAIGN.CATEGORY,
    title: 'Category campaign',
    purpose: 'Introduce the selections, promotions of the category during the month ',
    vnPurpose: 'Chương trình của ngành hàng. VD: bao-sale-giam-gia',
    ex: 'bao-sale-giam-gia',
  },
  [CATEGORY_CAMPAIGN.TIKI_PROGRAM]: {
    key: CATEGORY_CAMPAIGN.TIKI_PROGRAM,
    title: 'Tiki program',
    purpose: 'Introduce program during the month',
    vnPurpose: 'Chương trình chung của Tiki. VD: ma-giam-gia',
    ex: 'ma-giam-gia',
  },
  [CATEGORY_CAMPAIGN.BANK_PARTNERSHIP]: {
    key: CATEGORY_CAMPAIGN.BANK_PARTNERSHIP,
    title: 'Bank partnership',
    purpose: 'Introduce the partnership program of bank, e-wallet',
    vnPurpose: 'Chương trình đối tác ngân hàng. VD: mo-the-tikicard',
    ex: 'mo-the-tikicard',
  },
  [CATEGORY_CAMPAIGN.INFORMATION]: {
    key: CATEGORY_CAMPAIGN.INFORMATION,
    title: 'General Information',
    purpose: 'Show General Information',
    vnPurpose: 'Các thông tin chung từ Tiki',
    ex: 'chinh-sach-doi-tra',
  },
};
