import Config from '@/config';
import {
  CreateNgonThemeRequest,
  NgonThemeRequest,
  NgonThemesRequest,
  UpdateNgonThemeRequest,
} from '@/infras/ngon-theme/typing';
import { ThemeOrderLimitEnum, ThemeTypeEnum } from '@/pages/create-ngon-theme/constant';
import { mapApiDataToNgonThemesRes } from '@/pages/create-ngon-theme/mapper';
import { sortOrderMapper, SORT_KEYS } from '@/pages/ngon-themes/constant';
import { typeOf } from '@/pages/ngon-themes/util';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
import { stringify } from 'qs';
import request from 'umi-request';

async function getNgonThemes(req: NgonThemesRequest): Promise<ListProps<any>> {
  const { sort, filter, ...rest } = req;
  const sortQuery = typeOf(sort) === 'String' ? [sort] : sort;

  const qs = stringify(rest);

  const sortQs = (sortQuery || [])
    .map((data: any) => {
      const [key, value] = data.split(',');
      if (key === SORT_KEYS.PRIORITY) {
        return `sort=${key},${
          value === sortOrderMapper.ascend ? sortOrderMapper.descend : sortOrderMapper.ascend
        }`;
      }
      return `sort=${data}`;
    })
    .join('&');
  const data: any = await apiRequest(`/theme-collections?${qs}&${sort?.length > 0 ? sortQs : ''}`);
  return mapApiDataToNgonThemesRes(data);
}

async function createNgonTheme(ngonTheme: CreateNgonThemeRequest): Promise<any> {
  const customHandle = true;
  const data = await apiRequest(
    `/theme-collections`,
    {
      method: 'POST',
      data: ngonTheme,
    },
    customHandle,
  );
  return data;
}

async function updateNgonTheme(ngonTheme: UpdateNgonThemeRequest) {
  const { id, ...data } = ngonTheme;
  return apiRequest(`/theme-collections/${id}`, {
    method: 'PUT',
    data,
  });
}

async function getNgonTheme(req: NgonThemeRequest): Promise<any> {
  const data: any = await apiRequest(`/theme-collections/${req.ngonThemeId}`);
  return data;
}

export async function getThemeCollectionData({ themeId, collectionId }: any): Promise<any> {
  const api = `${Config.APIGateway}/shopping-tikifresh/v1/theme-collection/${themeId}/${collectionId}/preview`;
  const data: any = await request.get(api, {});
  return data;
}

export async function getThemeCollectionPublicData(slug: any): Promise<any> {
  const data: any = await request.get(
    `${Config.APIGateway}/shopping-tikifresh/v1/theme-collection/slug/${encodeURIComponent(slug)}`,
    {},
  );
  return data;
}

async function deleteNgonTheme(id: string | number): Promise<any> {
  await apiRequest(`/theme-collections/${id}`, {
    method: 'DELETE',
  });
}
export async function getOrderNgonThemes(type: ThemeTypeEnum): Promise<any> {
  if (type === ThemeTypeEnum.CREATIVE) {
    const data: any = await apiRequest(
      `/theme-collections/hub/creative?limit=${ThemeOrderLimitEnum.CREATIVE}&page=1`,
      {},
    );
    return data;
  }
  const data: any = await apiRequest(
    `/theme-collections/hub/promotion?limit=${ThemeOrderLimitEnum.PROMOTION}&page=1`,
    {},
  );
  return data;
}

export async function getAllOrderNgonThemes(): Promise<any> {
  const [creative, promotion]: any = await Promise.all([
    apiRequest(`/theme-collections/hub/creative?limit=${ThemeOrderLimitEnum.CREATIVE}&page=1`, {}),
    apiRequest(
      `/theme-collections/hub/promotion?limit=${ThemeOrderLimitEnum.PROMOTION}&page=1`,
      {},
    ),
  ]);
  return { creative: creative.items, promotion: promotion.items };
}

async function orderNgonTheme(data: any): Promise<any> {
  await apiRequest(`/theme-collections/change/priorities`, {
    method: 'PUT',
    data,
  });
}

export default {
  getNgonThemes,
  createNgonTheme,
  updateNgonTheme,
  getNgonTheme,
  deleteNgonTheme,
  orderNgonTheme,
  getThemeCollectionData,
  getOrderNgonThemes,
  getAllOrderNgonThemes,
};
