import {
  mapDataCollectionPagesReq,
  mapDataToUpdateCollectionPageReq,
} from '@/pages/collections/mapper';
import {
  createCollectionPage,
  getCollectionPage,
  getCollectionPages,
  updateCollectionPage,
} from '@/services/collectionPages';
import { EffectsCommandMap } from '@/utils/dva-model-creator';
import { Collection } from '../collection/typing';
import { setCollections } from '../collection/useCase';
import {
  CollectionPageFormData,
  CollectionPageRequest,
  CollectionPagesRequestData,
} from './typing';
import {
  createCollectionPageAction,
  getCollectionPageAction,
  getCollectionPagesAction,
  updateCollectionPageAction,
} from './useCase';

export function* handleExecuteGetListCPages(
  request: CollectionPagesRequestData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const response: any = yield call(getCollectionPages, mapDataCollectionPagesReq(request));
    yield put(getCollectionPagesAction.success({ request, response }));
  } catch (error) {
    yield put(getCollectionPagesAction.fail(error));
  }
}

export function* handleExecuteGetOneCPage(
  request: CollectionPageRequest,
  { call, put }: EffectsCommandMap,
) {
  try {
    const resp: any = yield call(getCollectionPage, request);
    const { collection_meta_data: collection = {}, meta_data, data, next_page } = resp;

    const response: {
      id: any;
      payload: Collection;
    } = {
      id: collection?.id,
      payload: {
        id: collection?.id,
        metaData: {
          ...meta_data,
          name: collection.name,
          description: collection.description,
        },
        uiData: {
          collectionPageId: meta_data.id,
          collectionId: collection.id,
          title: collection.name,
          subtitle: collection.description,
          backgroundImage: meta_data.backgroundImage,
          style: meta_data.style,
          textColor: meta_data.textColor,
          productList: data,
          page: 0,
          isLastPage: !next_page,
        },
      },
    };

    yield put(
      setCollections({
        response,
      }),
    );

    yield put(
      getCollectionPageAction.success({
        request,
        response: {
          ...meta_data,
          collections: collection?.id ? [collection.id] : [],
        },
      }),
    );
  } catch (err) {
    console.log('err', err);
    yield put(getCollectionPageAction.fail(err));
  }
}

export function* handleExecuteCreateCPage(
  payload: CollectionPageFormData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const reps = yield call(createCollectionPage, payload);
    yield put(createCollectionPageAction.success());
    yield put(getCollectionPagesAction.execute());
    return reps;
  } catch (err) {
    yield put(createCollectionPageAction.fail(err));
  }
}

export function* handleExecuteUpdateOneCPage(
  payload: CollectionPageFormData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const resp: any = yield call(updateCollectionPage, mapDataToUpdateCollectionPageReq(payload));
    yield put(updateCollectionPageAction.success(resp));
    return resp;

  } catch (error) {
    yield put(updateCollectionPageAction.fail(error));
  }
}
