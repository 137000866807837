import noop from 'lodash/noop';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface IStyledFadeInImgWrapper {
  ratio: number;
  imageIsLoaded: boolean;
  bg: string;
}

const StyledFadeInImgWrapper = styled.div<IStyledFadeInImgWrapper>`
  width: 100%;
  padding-bottom: ${({ ratio }) => `calc(100% * ${ratio})`};
  position: relative;
  background: ${({ imageIsLoaded, bg }) => (imageIsLoaded ? 'transparent' : bg)};
`;

const StyledFadeInImg = styled.img<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  width: 100%;
  height: auto;
  transition: 0.3s opacity;
`;

const StyledFadeInImgWithDimensions = styled(StyledFadeInImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledLink = styled.a`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;

interface IFadeInImage {
  src: string;
  alt: string;
  onClick?: () => void;
  imgStyle?: React.CSSProperties;
  href?: string;
  ratio?: number | null;
  dominantColor?: string;
}

const FadeInImage = ({
  src,
  alt,
  onClick = noop,
  imgStyle = {},
  href = '',
  ratio = null,
  dominantColor = '#EFEFEF',
}: IFadeInImage) => {
  const [opacity, setOpacity] = useState(0);
  const handleImageLoaded = useCallback(() => {
    setOpacity(1);
  }, []);
  const imgRef = useRef<any>();

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setOpacity(1);
    }
  }, []);

  const imageIsLoaded = opacity === 1;
  const imageProps = {
    src,
    alt,
    ref: imgRef,
    style: imgStyle,
    opacity,
    onLoad: handleImageLoaded,
    onClick,
  };

  const image = ratio ? (
    <StyledFadeInImgWrapper imageIsLoaded={imageIsLoaded} ratio={ratio} bg={dominantColor}>
      <StyledFadeInImgWithDimensions {...imageProps} />
    </StyledFadeInImgWrapper>
  ) : (
    <StyledFadeInImg {...imageProps} />
  );

  return href ? <StyledLink href={href}>{image}</StyledLink> : image;
};

export default FadeInImage;
