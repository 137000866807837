import { PageFormData } from '@/infras/page/typing';
import { Widget } from '@/infras/widget/typing';
import { widgetProps } from '@/components/Editor/constant';
import { parseJSONPayload } from '@/utils/parseWidgetPayload';
import { apiRequest } from '@/utils/request';

async function createPage(page: PageFormData) {
  return apiRequest(`/pages`, {
    method: 'POST',
    data: page,
  });
}

async function clonePage(page: PageFormData) {
  return apiRequest(`/pages/clone/${page.id}`, {
    method: 'POST',
    data: page,
  });
}

async function updatePage(page: PageFormData) {
  await apiRequest(`/pages/${page.id}`, {
    method: 'PUT',
    data: page,
  });
  return page;
}

async function updateWidgetsIndex({ pageId, widgets }: { pageId: string; widgets: string[] }) {
  await apiRequest(`/pages/${pageId}/index`, {
    method: 'PUT',
    data: { widgets: widgets.map((id, index) => ({ id, index })) },
  });
}

async function deletePage(page: PageFormData) {
  return apiRequest(`/pages/${page.id}`, {
    method: 'DELETE',
  });
}

async function getPage({ pageId }: { pageId: string }): Promise<any> {
  const data: any = await apiRequest(`/pages/${pageId}`);
  const widgets = data.widgets!.reduce((res: Widget[], widget: Widget) => {
    if (!widget.status) return res;
    res.push({
      ...widget,
      payload: parseJSONPayload(widget.payload, widgetProps[widget.type]),
    });
    return res;
  }, []);

  return { ...data, widgets: widgets.sort((a: any, b: any) => (a.index < b.index ? -1 : 1)) };
}
export default { createPage, updatePage, deletePage, getPage, updateWidgetsIndex, clonePage };
