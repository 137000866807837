import { tikiContext } from '@/infras/tiki/constants';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { TikiState, TikiCategoriesRequest } from '../infras/tiki/typing';
import { fetchTikiCategoriesAction } from '@/infras/tiki/useCase';
import { getCategories } from '@/services/tiki-categories';

const initState: TikiState = {
  categories: [],
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, tikiContext);

builder
  .takeLatest<any>(fetchTikiCategoriesAction.execute, function* (
    request: TikiCategoriesRequest,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const categories = yield call(getCategories, request);

      yield put(fetchTikiCategoriesAction.success({ categories }));
    } catch (err) {
      yield put(fetchTikiCategoriesAction.fail(err));
    }
  })
  .immer(fetchTikiCategoriesAction.execute, (state: TikiState, req) => {
    state.categories = [];
    state.status = 'loading';
    state.error = undefined;
    return state;
  })
  .immer(fetchTikiCategoriesAction.success, (state: TikiState, { categories }) => {
    state.categories = categories;
    state.status = 'success';
    return state;
  })
  .immer(fetchTikiCategoriesAction.fail, (state: TikiState, err: Error) => {
    state.error = err;
    state.status = 'failure';
    return state;
  });

export default builder.build();
