import { usersContext } from '@/infras/user/constants';
import {
  User,
  UserFormData,
  UserRequest,
  UsersRequestData,
  UsersResponse,
  UserState,
} from '@/infras/user/typing';
import {
  createUserAction,
  checkCurrentUserAction,
  getUserAction,
  getUsersAction,
  resetUserRequestAction,
  updateUserAction,
} from '@/infras/user/useCase';
import { FILTER_KEY } from '@/pages/admin/components/Users/components/UserFilter';
import { mapDataUsersReq } from '@/pages/admin/mapper';
import { getUserPermissionsFromRoles } from '@/pages/collections/util';
import usersServices from '@/services/user';
import { ACTION_STATUS } from '@/typings/redux';
import { setAuthority } from '@/utils/authority';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { getListMapData } from '@/utils';

const initState = {
  userMap: {},
  userList: { data: [], total: 0 },
  currentUser: undefined,
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: FILTER_KEY.EMAIL,
      value: '',
    },
  },
  status: 'initial',
  loading: true,
};

// export const { action: changeNotifyCount, hook: useChangeNotifyCount } = createUseCase<{
//   totalCount: number;
//   unreadCount: number;
// }>(usersContext, 'changeNotifyCount');

const builder = new DvaModelBuilder(initState, usersContext);

builder
  .immer(getUsersAction.success, (state: UserState, { request, response }: UsersResponse) => {
    const { mapData: userMap, list: userList } = getListMapData(response.data);
    state.userMap = { ...state.userMap, ...userMap };
    state.userList = { data: userList, total: response.total };
    if (request) {
      state.request = { ...state.request, ...request };
    }
    return state;
  })
  .takeLatest<any>(
    getUsersAction.execute,
    function* (request: UsersRequestData, { call, put }: EffectsCommandMap) {
      try {
        const response = yield call(usersServices.getUsers, mapDataUsersReq(request));
        yield put(getUsersAction.success({ request, response }));
      } catch (error) {
        yield put(getUsersAction.fail(error));
      }
    },
  );

builder
  .takeLatest<any>(
    createUserAction.execute,
    function* (payload: UserFormData, { call, put }: EffectsCommandMap) {
      try {
        yield call(usersServices.createUser, payload);
        yield put(createUserAction.success());
        yield put.resolve(getUsersAction.execute());
      } catch (err) {
        yield put(createUserAction.fail(err));
      }
    },
  )
  .immer(createUserAction.execute, (state: UserState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })
  .immer(createUserAction.success, (state: UserState) => {
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(createUserAction.fail, (state: UserState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

builder.immer(resetUserRequestAction, (state: UserState) => {
  state.status = ACTION_STATUS.INITIAL;
  state.error = null;
  return state;
});

builder
  .takeLatest<any>(
    updateUserAction.execute,
    function* (payload: UserFormData, { call, put }: EffectsCommandMap) {
      try {
        const resp = yield call(usersServices.updateUser, payload);
        yield put(updateUserAction.success(resp));
      } catch (error) {
        yield put(updateUserAction.fail(error));
      }
    },
  )
  .immer(updateUserAction.success, (state: UserState, user: User) => {
    const { id } = user;
    state.userMap = { ...state.userMap, [id]: user };
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(updateUserAction.execute, (state: UserState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })

  .immer(updateUserAction.fail, (state: UserState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

builder
  .immer(
    getUserAction.success,
    (state: UserState, { request, response }: { request: UserRequest; response: User }) => {
      state.userMap[request.id] = response;

      return state;
    },
  )
  .takeLatest<any>(
    getUserAction.execute,
    function* (request: UserRequest, { call, put }: EffectsCommandMap) {
      try {
        const user = yield call(usersServices.getUser, request);

        yield put(getUserAction.success({ request, response: user }));
      } catch (err) {
        yield put(getUserAction.fail(err));
      }
    },
  );

builder
  .immer(checkCurrentUserAction.success, (state: UserState, user) => {
    const permissions = getUserPermissionsFromRoles(user?.roles);
    state.currentUser = { ...user, permissions };
    setAuthority(permissions);
    state.loading = false;
    return state;
  })
  .immer(checkCurrentUserAction.fail, (state: UserState, error: Error) => {
    state.error = error;
    state.currentUser = null;
    state.loading = false;
    return state;
  })
  .takeLatest<any>(
    checkCurrentUserAction.execute,
    function* (request: UserRequest, { call, put }: EffectsCommandMap) {
      try {
        const user: User = yield call(usersServices.checkCurrentUser, request);

        yield put(checkCurrentUserAction.success(user));
      } catch (err) {
        yield put(checkCurrentUserAction.fail(err));
      }
    },
  );
export default builder.build();
