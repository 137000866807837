import { NoticeIconData } from '@/components/NoticeIcon';
import { DvaModelBuilder } from '@/utils/dva-model-creator';
import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';

export interface NoticeItem extends NoticeIconData {
  id: string;
  type: string;
  status: string;
}

export interface GlobalModelState {
  collapsed?: boolean;
  notices: NoticeItem[];
  mobileView: boolean;
}

const globalNameSpace = 'global';

const initState = {
  collapsed: false,
  notices: [],
  mobileView: true,
};

export const { action: clearNotices, hook: useClearNotices } = createAsyncUseCase<string, string>(
  globalNameSpace,
  'clearNotices',
);

export const { action: changeLayoutCollapsed, hook: useChangeLayoutCollapsed } = createUseCase(
  globalNameSpace,
  'changeLayoutCollapsed',
);

export const { action: changeNoticeReadState, hook: useChangeNoticeReadState } = createUseCase(
  globalNameSpace,
  'changeNoticeReadState',
);

export const { action: changeLayoutView, hook: useChangeLayoutView } = createUseCase(
  globalNameSpace,
  'changeLayoutView',
);
const globalBuilder = new DvaModelBuilder(initState, globalNameSpace);

globalBuilder.immer(changeLayoutCollapsed, (state: GlobalModelState, payload: boolean) => {
  return {
    ...state,
    collapsed: payload,
  };
});

globalBuilder.immer(changeLayoutView, (state: GlobalModelState, payload: boolean) => {
  return {
    ...state,
    mobileView: payload,
  };
});
export default globalBuilder.build();
