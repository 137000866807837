import { WidgetType } from '@/infras/widget/constants';
import TextSettings from './TextSettings';
import TextView from './TextView';
import TextDraggable from './TextDraggable';

const widget = {
  type: WidgetType.TEXT,
  View: TextView,
  Settings: TextSettings,
  Draggable: TextDraggable,
  default: {
    type: WidgetType.TEXT,
    payload: {
      textType: 'heading',
      heading: { align: 'center', text: '', size: 'large', color: '#222' },
      paragraph: { align: 'center', text: '', color: '#111' },
      backgroundColor: 'transparent',
    },
  },
};

export default widget;
