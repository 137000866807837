// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/campaigns",
            "exact": true
          },
          {
            "name": "Campaigns",
            "icon": "table",
            "path": "/campaigns",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns' */'/app/src/pages/campaigns'), loading: LoadingComponent}),
            "authority": [
              "ALL",
              "READ_CAMPAIGN",
              "UPDATE_CAMPAIGN"
            ],
            "exact": true
          },
          {
            "name": "Collections",
            "icon": "table",
            "path": "/collections",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collections' */'/app/src/pages/collections'), loading: LoadingComponent}),
            "authority": [
              "ALL",
              "READ_COLLECTION",
              "UPDATE_COLLECTION"
            ],
            "exact": true
          },
          {
            "name": "Categories",
            "icon": "table",
            "path": "/categories",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__categories' */'/app/src/pages/categories'), loading: LoadingComponent}),
            "authority": [
              "ALL",
              "READ_CATEGORY",
              "UPDATE_CATEGORY"
            ],
            "exact": true
          },
          {
            "name": "Store Collections",
            "icon": "table",
            "path": "/stores",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stores' */'/app/src/pages/stores'), loading: LoadingComponent}),
            "authority": [
              "ALL",
              "READ_COLLECTION",
              "UPDATE_COLLECTION"
            ],
            "exact": true
          },
          {
            "name": "NGON",
            "icon": "table",
            "path": "/ngon",
            "authority": [
              "ALL",
              "READ_THEME",
              "UPDATE_THEME"
            ],
            "routes": [
              {
                "name": "NGON Quick Link",
                "icon": "table",
                "path": "/ngon/quick-link",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ngon-quick-link' */'/app/src/pages/ngon-quick-link'), loading: LoadingComponent}),
                "authority": [
                  "ALL",
                  "READ_THEME",
                  "UPDATE_THEME"
                ],
                "exact": true
              },
              {
                "name": "NGON Dynamic Title",
                "icon": "table",
                "path": "/ngon/dynamic-title",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ngon-dynamic-title' */'/app/src/pages/ngon-dynamic-title'), loading: LoadingComponent}),
                "authority": [
                  "ALL",
                  "READ_THEME",
                  "UPDATE_THEME"
                ],
                "exact": true
              },
              {
                "name": "NGON Theme collection",
                "icon": "table",
                "path": "/ngon/collection",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ngon-themes' */'/app/src/pages/ngon-themes'), loading: LoadingComponent}),
                "authority": [
                  "ALL",
                  "READ_THEME",
                  "UPDATE_THEME"
                ],
                "exact": true
              },
              {
                "name": "Tạo chủ đề",
                "hideInMenu": true,
                "path": "/ngon/create-theme",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__create-ngon-theme' */'/app/src/pages/create-ngon-theme'), loading: LoadingComponent}),
                "authority": [
                  "ALL",
                  "UPDATE_THEME"
                ],
                "exact": true
              },
              {
                "name": "Edit Theme Collection Page",
                "hideInMenu": true,
                "path": "/ngon/:ngonThemeId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__edit-theme-collection-page' */'/app/src/pages/edit-theme-collection-page'), loading: LoadingComponent}),
                "authority": [
                  "ALL",
                  "UPDATE_THEME"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "Home Widget Collection",
            "icon": "table",
            "path": "/widget",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__widget-collection' */'/app/src/pages/widget-collection'), loading: LoadingComponent}),
            "authority": [
              "ALL",
              "READ_COLLECTION",
              "UPDATE_COLLECTION"
            ],
            "exact": true
          },
          {
            "name": "Edit Category Page",
            "icon": "profile",
            "path": "/categories/:categoryId/edit/:pageId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__edit-category-page' */'/app/src/pages/edit-category-page'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "READ_CATEGORY",
              "UPDATE_CATEGORY"
            ],
            "exact": true
          },
          {
            "name": "Category",
            "path": "/categories/:categoryId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__category' */'/app/src/pages/category'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "READ_CATEGORY",
              "UPDATE_CATEGORY"
            ],
            "exact": true
          },
          {
            "name": "Edit Collections Page",
            "icon": "profile",
            "path": "/collections/:collectionPageId/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__edit-collection-page' */'/app/src/pages/edit-collection-page'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "READ_COLLECTION",
              "UPDATE_COLLECTION"
            ],
            "exact": true
          },
          {
            "name": "Campaign",
            "path": "/campaigns/:campaignId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaign' */'/app/src/pages/campaign'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "READ_CAMPAIGN",
              "UPDATE_CAMPAIGN"
            ],
            "exact": true
          },
          {
            "name": "Edit Page",
            "icon": "profile",
            "path": "/campaigns/:campaignId/edit/:pageId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__edit-campaign-page' */'/app/src/pages/edit-campaign-page'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "READ_CAMPAIGN",
              "UPDATE_CAMPAIGN"
            ],
            "exact": true
          },
          {
            "path": "/user-management",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin' */'/app/src/pages/admin'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL",
              "MANAGE_CAMPAIGN_PERMISSION",
              "MANAGE_COLLECTION_PERMISSION",
              "MANAGE_CATEGORY_PERMISSION"
            ],
            "exact": true
          },
          {
            "path": "/remote-config",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__remote-config' */'/app/src/pages/remote-config'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "ALL"
            ],
            "exact": true
          },
          {
            "path": "/test-image-upload-v2",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test-image-upload-v2' */'/app/src/pages/test-image-upload-v2'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
