import Box from '@/components/Box';
import { apiRequest } from '@/utils/request';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import EditImageModal from './EditImageModal';
import { QuillImage } from './types';

interface Props {
  maxLength?: number;
  value?: any;
  onChange?: (value: any) => void;
  customToolbar?: any[];
  placeholder?: string;
}

const QuillTextInput: FC<Props> = ({ maxLength, onChange, value = '' }) => {
  const editorRef = useRef<any | null>(null);
  const initialValue = useRef<any | null>(value || '');
  const [editingImage, setEditingImage] = useState<QuillImage | null>(null);

  const handleClose = useCallback(() => setEditingImage(null), []);

  return (
    <Box my="8px" height={500}>
      {maxLength ? (
        <Box
          my="8px"
          style={{ fontSize: 12, textAlign: 'end' }}
          color={value && value.length > maxLength ? '#f5222d' : 'inherit'}
        >{`Độ dài ${value?.length || 0}/${maxLength}`}</Box>
      ) : null}
      <Editor
        apiKey="ncp0d93v1cj085f2z4aja5jyuxf4dmpe094fbnwnrv62dgz2"
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.setContent(value || '');
        }}
        initialValue={initialValue.current}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',

          images_upload_handler: async (blobInfo: any, success: any): Promise<any> => {
            const data = new FormData();
            data.append('file', blobInfo.blob());
            const resp: any = await apiRequest(`/upload`, {
              method: 'POST',
              body: data,
            });

            success(1);
            return resp.url;
          },

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={debounce((a: any) => {
          if (onChange) {
            onChange(a);
          }
        }, 300)}
      />
      <EditImageModal
        visible={Boolean(editingImage)}
        image={editingImage}
        onConfirm={() => {}}
        onCancel={handleClose}
      />
    </Box>
  );
};

export default memo(QuillTextInput);
