export enum TabTitle {
  IMG = 'img',
  TEXT = 'text',
}

export enum TabType {
  SINGLE = 'single',
  DOUBLE = 'double',
  TRIPLE = 'triple',
  FREE = 'free',
}
