import {
  CampaignRequest,
  CampaignsRequest,
  CreateCampaignRequest,
  UpdateCampaignRequest,
} from '@/infras/campaign/typing';
import { Page } from '@/infras/page/typing';
import { mapApiDataToCampaignsRes } from '@/pages/campaigns/mapper';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
import { stringify } from 'qs';
import moment from 'moment';

async function getCampaigns(req: CampaignsRequest): Promise<ListProps<any>> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/campaigns?${qs}`);
  return mapApiDataToCampaignsRes(data);
}

async function createCampaign(campaign: CreateCampaignRequest) {
  const customHandle = true;
  const data = await apiRequest(
    `/campaigns`,
    {
      method: 'POST',
      data: campaign,
    },
    customHandle,
  );
  return data;
}

async function updateCampaign(campaign: UpdateCampaignRequest) {
  return apiRequest(`/campaigns/${campaign.id}`, {
    method: 'PUT',
    data: campaign,
  });
}

async function getCampaign(req: CampaignRequest): Promise<any> {
  const qs = stringify(req.filter);
  const data: any = await apiRequest(`/campaigns/${req.campaignId}?${qs}`);
  const pages = data.pages!.reduce((res: Page[], page: Page) => {
    if (!page.status) return res;
    res.push(page);
    return res;
  }, []);
  return {
    ...data,
    pages: pages.sort((a: any, b: any) =>
      moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1,
    ),
  };
}
async function uploadCampaignImage(imgForm: any): Promise<any> {
  const formData = new FormData();
  formData.append('image', imgForm);
  const data: any = await apiRequest(`/campaigns/upload`, {
    method: 'POST',
    body: formData,
    header: {
      Accept: 'application/json',
    },
  });
  return data;
}
export default { getCampaigns, createCampaign, updateCampaign, getCampaign, uploadCampaignImage };
