import React, { FC } from 'react';
import { DraggableProps } from '..';
import { RichTextWidget } from './types';

export interface RichTextDraggableProps extends DraggableProps<RichTextWidget> {}

const RichTextDraggable: FC<RichTextDraggableProps> = ({ widget: { id } }) => {
  return <div>Draggable RichText Widget: {id}</div>;
};

export default RichTextDraggable;
