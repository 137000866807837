import {
  CollectionPage,
  CollectionPageFormData,
  CollectionPagesRequest,
  CollectionPagesRequestData,
  UpdateCollectionPageRequest,
} from '@/infras/collection-page/typing';
import { ListProps } from '@/typings/list';
import { UploadFile } from 'antd/lib/upload/interface';
import { isString } from 'lodash';
import { prefixCollectionPageUrl } from './util';

export const mapMetaImageToImgFile = (collectionPage: CollectionPage): UploadFile => {
  return {
    uid: '-1',
    name: collectionPage.name,
    status: 'done',
    url: collectionPage.ogImage,
    type: '',
    size: 200,
  };
};
export const getOGImg = (imgPath: any) => {
  return (
    imgPath || 'https://salt.tikicdn.com/ts/brickv2og/55/02/1a/577228e1dd2baffa22fb634e0fa0afb7.png'
  );
};
export const mapDataCollectionPagesReq = (
  request: CollectionPagesRequestData,
): CollectionPagesRequest => {
  if (!request) return { limit: 10, page: 1 };
  const filter: any = request?.filter
    ? { [request.filter.key]: request.filter.value.replace(prefixCollectionPageUrl, '') }
    : {};
  if (typeof request.page_type !== 'undefined') {
    filter.page_type = request.page_type;
  }
  return {
    limit: request.limit,
    page: request.page,
    ...filter,
  };
};

export const mapDataToUpdateCollectionPageReq = (
  collectionPage: CollectionPageFormData,
): UpdateCollectionPageRequest => {
  const { url, slug } = collectionPage;

  return {
    ...collectionPage,
    url: slug || url,
    ogImage: isString(collectionPage?.ogImage)
      ? getOGImg(collectionPage?.ogImage)
      : getOGImg(collectionPage?.ogImage?.[0]),
  };
};

export const mapApiDataToCollectionPagesRes = (data: any): ListProps<any> => {
  return {
    data: data.items,
    total: data.meta.totalItems,
  };
};
