/* eslint-disable prefer-promise-reject-errors */
import QuillTextInput from '@/components/quill/QuillTextInput2';
import Config from '@/config';
import { Category, CategoryFieldsProps } from '@/infras/category/typing';
import { Button, Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { endsWith, get, startsWith } from 'lodash';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import request from 'umi-request';
import { categoryText, TikiCategoryType } from '../constants';
import { getAuthorSlug, getBrandSlug } from '../util';
import UploadRelevantsField from './UploadRelevantsField';

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

interface CategoryFormProps {
  title: string;
  visible: boolean;
  form: FormInstance;
  category?: CategoryFieldsProps;
  handleVisibleModal: (visibility: boolean) => void;
  onSubmit: (values: CategoryFieldsProps) => Promise<Category>;
  loading: boolean;
  mode: FormMode;
}
export const TikiCategoryUrlRegex = /\/.*\/c([0-9]*)/;
export const TikiCategoryBrandUrlRegex = /\/.*\/c([0-9]*\/.*)/;

const defaultCategory: CategoryFieldsProps = {
  tikiId: -1,
  url: '',
};

const StyledCategoryModal = styled(Modal)`
  min-width: 800px;
  max-height: 80vh;
  transform: translateY(-50%);
  top: 50%;
  padding-bottom: 0;

  .ql-container {
    .ql-editor {
      max-height: 220px;
      h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        font-weight: 600;
      }
      h4 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        font-weight: 600;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
  }
`;

const CategoryForm: FC<CategoryFormProps> = ({
  title,
  visible,
  form,
  category = defaultCategory,
  handleVisibleModal,
  onSubmit,
  loading,
  mode,
}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const type: TikiCategoryType =
    (urlSearchParams.get('type') as TikiCategoryType) || TikiCategoryType.CATEGORY;

  const [urlError, setUrlError] = useState('');
  const [tikiIdError, setTikiIdError] = useState('');
  const resetForm = useCallback(() => {
    handleVisibleModal(false);
    setUrlError('');
    form.resetFields();
  }, [form, handleVisibleModal]);

  const onFinish = useCallback(
    (values: CategoryFieldsProps) => {
      onSubmit({ ...values, type })
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          const originalErrorMessage = error?.data?.error?.message;
          setTikiIdError(originalErrorMessage);
        });
    },
    [onSubmit, resetForm, type],
  );

  const onValuesChange = useCallback(
    debounce(async (changedValues: CategoryFieldsProps) => {
      if (changedValues.url) {
        const url = form.getFieldValue('url') || '';
        if (url && type === TikiCategoryType.BRAND) {
          const brandSlug = getBrandSlug(url);
          if (!brandSlug) {
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            return;
          }
          try {
            const brandInfo = await request(
              `${Config.NewAPIUrl}/v2/brands?slug=${encodeURIComponent(brandSlug)}`,
            );

            const brandId = get(brandInfo, 'data[0].id');
            if (!brandId) {
              form.setFieldsValue({
                tikiId: '',
              });
              setUrlError('Đường dẫn không tần tại');
              return;
            }
            form.setFieldsValue({
              tikiId: brandId || '',
            });
          } catch (err) {
            const errMessage = get(err, 'response.data.error.message');
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            console.error('Get brand error:', errMessage);
          }
          return;
        }
        if (url && type === TikiCategoryType.AUTHOR) {
          const authorSlug = getAuthorSlug(url);
          if (!authorSlug) {
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            return;
          }
          try {
            const authorInfo = await request(
              `${Config.NewAPIUrl}/v2/authors?slug=${encodeURIComponent(authorSlug)}`,
            );

            const authorId = get(authorInfo, 'data[0].id');
            if (!authorId) {
              form.setFieldsValue({
                tikiId: '',
              });
              setUrlError('Đường dẫn không tần tại');
              return;
            }
            form.setFieldsValue({
              tikiId: authorId || '',
            });
          } catch (err) {
            const errMessage = get(err, 'response.data.error.message');
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            console.error('Get brand error:', errMessage);
          }
          return;
        }
        if (url && type === TikiCategoryType.CATEGORY_BRAND) {
          const [cateId, brandSlug] = url.match(TikiCategoryBrandUrlRegex)[1].split('/');

          // const brandSlug = getBrandSlug(url);
          if (!brandSlug) {
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            return;
          }
          try {
            const brandInfo = await request(
              `${Config.NewAPIUrl}/v2/brands?slug=${encodeURIComponent(brandSlug)}`,
            );

            const brandId = get(brandInfo, 'data[0].id');
            if (!brandId) {
              form.setFieldsValue({
                tikiId: '',
              });
              setUrlError('Đường dẫn không tần tại');
              return;
            }
            form.setFieldsValue({
              tikiId: `${cateId}-${brandSlug}` || '',
            });
          } catch (err) {
            const errMessage = get(err, 'response.data.error.message');
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            console.error('Get brand error:', errMessage);
          }
          return;
        }
        if (url && type === TikiCategoryType.SEARCH) {
          const newUrl = new URL(url);
          const params = new URLSearchParams(newUrl.search);
          const tikiId = encodeURIComponent(params.get('q') || '');
          if (!url.startsWith(`${Config.TikiFrontend}/search` || !tikiId)) {
            form.setFieldsValue({
              tikiId: '',
            });
            setUrlError('Đường dẫn không tần tại');
            return;
          }
          form.setFieldsValue({
            tikiId,
          });
          return;
        }
        form.setFieldsValue({
          tikiId: url.match(TikiCategoryUrlRegex)
            ? Number(url.match(TikiCategoryUrlRegex)[1])
            : null,
        });
      }
    }, 300),
    [type],
  );

  return (
    <StyledCategoryModal
      title={title}
      visible={visible}
      maskClosable={false}
      onCancel={resetForm}
      footer={null}
      transitionName=""
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={category}
        validateMessages={{
          // eslint-disable-next-line
          required: 'Vui lòng nhập ${label}',
        }}
      >
        {mode === FormMode.CREATE ? (
          <Form.Item
            name="url"
            label={`${categoryText?.[type]?.title || ''} tiki url`}
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (!value) {
                    setUrlError('Vui lòng nhập Đường dẫn');
                    return Promise.reject('Vui lòng nhập Đường dẫn tới tiki category');
                  }
                  if (startsWith(value, '-') || endsWith(value.content, '-')) {
                    setUrlError('Đường dẫn không thể bắt đầu hoặc kết thúc bằng "-"');
                    return Promise.reject('Đường không thể bắt đầu hoặc kết thúc bằng "-"');
                  }
                  setUrlError('');
                  return Promise.resolve();
                },
              }),
            ]}
            validateStatus={urlError ? 'error' : ''}
            help={urlError || undefined}
          >
            <Input placeholder="Tiki link dẫn tới category muốn tạo" />
          </Form.Item>
        ) : null}
        <Form.Item
          name="tikiId"
          label="Tiki Id"
          validateStatus={tikiIdError ? 'error' : ''}
          help={tikiIdError || undefined}
        >
          <Input disabled placeholder="Tiki id" />
        </Form.Item>
        <Form.Item name="relevantLinks" label="Danh sách liên quan">
          <UploadRelevantsField />
        </Form.Item>

        <Form.Item name="footerContent" label="Footer Content">
          <QuillTextInput />
        </Form.Item>
        <Form.Item name="metaTitle" label="Meta Title">
          <Input placeholder="Dưới 60 ký tự, sẽ được dùng để hiển thị trên Web/App/Facebook/Google" />
        </Form.Item>
        <Form.Item name="metaDescription" label="Meta Description">
          <Input.TextArea
            rows={3}
            placeholder="Tóm tắt ngắn gọn nội dung quan trọng nhất của page, thường được sử dụng như lời quảng cáo hấp dẫn. Chiều dài tốt nhất là từ 50-300 ký tự; và sẽ được dùng để hiển thị trên Facebook/Google"
          />
        </Form.Item>
        <Form.Item name="h1" label="h1">
          <Input placeholder="Được dùng để hiển thị content của h1 tag" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{ margin: '0 auto', display: 'block' }}
            loading={loading}
          >
            {mode === FormMode.CREATE ? 'Tạo' : 'Lưu'}
          </Button>
        </Form.Item>
      </Form>
    </StyledCategoryModal>
  );
};
export default CategoryForm;
