import { ListProps } from '@/typings/list';
import {
  StoreCollectionsRequest,
  StoreCollectionsRequestData,
  StoreCollectionFormData,
  StoreCollectionUpdateFormData,
  UpdateStoreCollectionRequest,
  CreateStoreCollectionRequest,
} from '@/infras/store/typing';
import { isString } from 'lodash';

export const mapApiDataToStoreCollectionsRes = (data: any): ListProps<any> => {
  const result = {
    data: data.items,
    total: data.meta.totalItems,
  };
  return result;
};

export const mapDataStoreCollectionReq = (
  request: StoreCollectionsRequestData,
): StoreCollectionsRequest => {
  if (!request) return { limit: 10, page: 1 };
  const filter = request?.filter ? { [request.filter.key]: request.filter.value } : {};
  return {
    limit: request.limit || 10,
    page: request.page || 1,
    ...filter,
  };
};

export const mapDataToCreateStoreCollectionReq = (
  storeCollection: StoreCollectionFormData,
): CreateStoreCollectionRequest => {
  return {
    ...storeCollection,
    storeIds: (storeCollection?.storeIds || []).join(','),
  };
};

export const mapDataToUpdateStoreCollectionReq = (
  storeCollection: StoreCollectionUpdateFormData,
): UpdateStoreCollectionRequest => {
  return {
    ...storeCollection,
    storeIds: isString(storeCollection?.storeIds)
      ? storeCollection?.storeIds
      : (storeCollection?.storeIds || []).join(','),
  };
};
