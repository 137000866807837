import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SketchPicker, ColorResult, RGBColor } from 'react-color';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

const Swatch = styled.div<{ disabled?: boolean }>`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #f5f5f5;
      cursor: not-allowed;
    `}
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const Popover = styled.div`
  display: ${(props: { state: TransitionStatus }) => (props.state === 'exited' ? 'none' : 'block')};
  opacity: ${(props: { state: TransitionStatus }) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  z-index: 21;
`;

const Color = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 2px;
  background: ${(props: { color: string }) => props.color};
`;

interface Props {
  value?: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
}

const ColorPicker: FC<Props> = ({
  value = 'transparent',
  onChange: onPickColor,
  disabled = false,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<string>(value);
  const [isTouched, setTouched] = useState(false);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setDisplayColorPicker((display) => !display);
    }
  }, [disabled]);

  const handleClose = useCallback(() => {
    setDisplayColorPicker(false);
  }, []);
  const formatRGBToString = (rgbColor: RGBColor) => {
    return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${rgbColor.a})`;
  };
  const handleChange = useCallback(
    (nextColor: ColorResult) => {
      if (!isTouched) {
        setTouched(true);
        nextColor.rgb.a = 1;
      }
      setColor(formatRGBToString(nextColor.rgb));
    },
    [isTouched],
  );

  const handleChangeComplete = (lastColor: ColorResult) => {
    if (onPickColor) {
      onPickColor(formatRGBToString(lastColor.rgb));
    }
  };
  return (
    <div>
      <Swatch onClick={handleClick} disabled={disabled}>
        <Color color={color} />
      </Swatch>
      <Transition in={displayColorPicker} timeout={500}>
        {(state) => (
          <Popover state={state}>
            <Cover onClick={handleClose} />
            <SketchPicker
              color={color}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
            />
          </Popover>
        )}
      </Transition>
    </div>
  );
};

export default ColorPicker;
