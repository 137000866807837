import { indexOf, reduce, trim } from 'lodash';
import { IRelevantLink } from './type';

export const getCodeFromFile = (file: any) => {
  return new Promise<any>((resolve) => {
    import('xlsx').then((XLSX) => {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;

        const wb = XLSX.read(bstr, { type: 'binary' });

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_csv(ws)));

        const dataArr = data.split('\n');
        /* Update state */
        const [titleRow, ...dataContent] = dataArr;
        const titleCols = titleRow.split(',');

        const nameIndex = indexOf(titleCols, 'name');
        const urlIndex = indexOf(titleCols, 'URL');

        const codeArr = reduce(
          dataContent,
          (res: IRelevantLink[], dataString: string) => {
            const dataCols = dataString.split(',');
            const name = dataCols[nameIndex];
            const url = dataCols[urlIndex];
            res.push({ name: trim(name), url: trim(url) });

            return res;
          },
          [],
        );
        console.log(codeArr,'codeArr')
        resolve(codeArr);
      };
      reader.readAsBinaryString(file);
    });
  });
};

export const createRelevantLinksReportFile = async ({ links, fileName }: any) => {
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  wb.Props = {
    Title: 'Theme collection report',
    Subject: 'Report',
    Author: 'nghiahd@tiki.vn',
    CreatedDate: new Date(),
  };
  const typeName = 'Links';

  wb.SheetNames.push(typeName);

  const ws_data = links.reduce(
    (res: any[], { name, url }: IRelevantLink) => {
      res.push([name, url]);
      return res;
    },
    [['name', 'URL']],
  );
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets[typeName] = ws;

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName || `Theme collection ${typeName} report.xlsx`;
  link.click();
};
