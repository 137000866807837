import { ProColumns } from '@ant-design/pro-table';
import React from 'react';
import ThemeInfo from './components/ThemeInfo';
import ThemeOperating from './components/ThemeOperating';
import ThemePosition from './components/ThemePosition';
import ThemeStatus from './components/ThemeStatus';
import ThemeType from './components/ThemeType';
import { getSortQueryMapper } from './util';

export enum SORT_KEYS {
  NAME = 'name',
  POSITION = 'position',
  TYPE = 'type',
  PRIORITY = 'priority',
  IS_DISPLAYED = 'isDisplayed',
  NUMBER_OF_COLLECTION = 'collection_size',
}

export const sortOrderMapper = {
  descend: 'desc',
  desc: 'descend',
  ascend: 'asc',
  asc: 'ascend',
};
export const itemsPerPage = [10, 15, 25, 50, 100];
export const getColumns = (query: any): ProColumns<any>[] => {
  const sortMapper = getSortQueryMapper(query?.sort);

  return [
    {
      title: 'Chủ đề',

      render: (dom, theme) => {
        return <ThemeInfo theme={theme} />;
      },
    },
    {
      title: 'Loại chủ đề',
      key: SORT_KEYS.TYPE,
      sorter: { multiple: 1 },
      sortOrder: sortOrderMapper[sortMapper?.[SORT_KEYS.TYPE]],
      // filters: [
      //   {
      //     text: <ThemeType themeType={ThemeTypeEnum.PROMOTION} />,
      //     value: ThemeTypeEnum.PROMOTION,
      //   },
      //   {
      //     text: <ThemeType themeType={ThemeTypeEnum.CREATIVE} />,
      //     value: ThemeTypeEnum.CREATIVE,
      //   },
      // ],
      render: (dom, theme) => {
        return <ThemeType themeType={theme.type} />;
      },
    },
    {
      title: 'Vị trí',
      key: SORT_KEYS.PRIORITY,
      sorter: { multiple: 2 },
      sortOrder: sortOrderMapper[sortMapper?.[SORT_KEYS.PRIORITY]],
      render: (dom, theme) => {
        return <ThemePosition theme={theme} />;
      },
    },
    {
      title: 'Số lượng bộ sưu tập',
      render: (dom, theme) => {
        return <div>{theme.collections.length}</div>;
      },
    },
    {
      title: 'Trạng thái',
      key: SORT_KEYS.IS_DISPLAYED,
      sorter: { multiple: 3 },
      sortOrder: sortOrderMapper[sortMapper?.[SORT_KEYS.IS_DISPLAYED]],
      render: (dom, theme) => {
        return <ThemeStatus theme={theme} />;
      },
    },
    {
      title: 'Thao tác',
      render: (dom, theme) => {
        return <ThemeOperating theme={theme} />;
      },
    },
  ];
};
