/* eslint-disable no-console */
import get from 'lodash/get';

export type GTAGFunction = (...args: any[]) => void;

const debug = (...args: any[]) => console.log('gtag_debug', args);

function getGTAG(): GTAGFunction {
  const gtag = get(window, 'gtag') || debug;

  return gtag;
}

function setUserId(userId: string | null) {
  const gtag = getGTAG();

  gtag('config', 'GA_MEASUREMENT_ID', {
    user_id: userId,
  });
}

function pushEvent(action: string, category: string, label: string, value: number) {
  const gtag = getGTAG();

  gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
}

const gtagService = {
  getGTAG,
  setUserId,
  pushEvent,
};

export default gtagService;
