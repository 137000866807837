export interface ReduxPayLoadSuccessProps<RES, REQ> {
  request: RES;
  response: REQ;
}

export enum ACTION_STATUS {
  INITIAL = 'initial',
  REJECTED = 'rejected',
  RESOLVED = 'resolved',
  REQUESTING = 'requesting',
}
