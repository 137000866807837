import { Form } from 'antd';
import styled from 'styled-components';

export const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 10px;
  }
  > .ant-form-item-control {
    > .ant-form-item-control-input {
      > .ant-form-item-control-input-content {
        > .ant-radio-group {
          > .ant-radio-button-wrapper {
            width: 130px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
            }
            margin: 2.5px;
            margin-left: 5px;
            margin-right: 5px;

            &:first-child {
              border-top-width: 1.02px;
              border-left-width: 0;
            }

            &:first-child::before {
              position: absolute;
              top: -1px;
              left: -1px;
              display: block;
              box-sizing: content-box;
              width: 1px;
              height: 100%;
              padding: 1px 0;
              background-color: #d9d9d9;
              transition: background-color 0.3s;
              content: '';
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
              background-color: #1890ff;
            }
          }
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

export const LayoutFormItem = styled(StyledFormItem)`
  &.ant-form-item {
    margin-bottom: 20px;
  }
  > .ant-form-item-control {
    > .ant-form-item-control-input {
      > .ant-form-item-control-input-content {
        > .ant-radio-group {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: 390px;
          margin: 0 auto;
        }
      }
    }
  }
`;

export const ColorPickerFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label > label {
    padding-top: 10px;
  }
`;

export const StyledLayoutImgWrapper = styled.div<{ imgWidth?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: ${({ imgWidth }) => imgWidth || '100%'};
    height: auto;
  }
`;

export const TabTypeImg = styled.img`
  width: 40px;
  height: 40px;
  filter: opacity(0.4);
`;
