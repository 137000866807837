import { hasUpdateThemeCollectionAuthority } from '@/components/Authorized/CheckPermissions';
import Box from '@/components/Box';
import { MenuOutlined } from '@ant-design/icons';
import React, { FunctionComponent, useCallback } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';

interface DragElementProps {
  index: number;
  id: string;
  pageId: string;
  onSelectThemeCollection: (theme?: any) => void;
  onDeleteThemeCollection: (id: string) => void;
  onAddThemeCollection?: (fromThemeCollection: any, meta?: any) => void;
  theme: any;
  selectedThemeCollectionId: string | null;
  enableClone?: boolean;
  selectedDraggingIds?: string[];
  selectSingleItem?: (id: string) => void;
  selectMultiItems?: (id: string) => void;
  draggingId?: any;
  type?: string;
}

const ThemeCollectionContainer = styled.div`
  /* used for positioning the after content */
  position: static;
  display: flex;
  align-items: center;
`;

const ThemeOperation: FunctionComponent<DragElementProps | any> = ({
  children,
  onDeleteThemeCollection,
  onUpdateThemeCollection,
  theme,
  type,
  selectSingleItem,
  ...props
}) => {
  const hasEditingAuthority = hasUpdateThemeCollectionAuthority();

  const selectDragAction = useCallback(
    (e: React.MouseEvent<any> | React.KeyboardEvent<any>) => {
      if (!selectSingleItem) {
        return;
      }
      if (e.defaultPrevented) {
        return;
      }

      // @ts-ignore
      if (e.keyCode) {
        return;
      }

      // marking the event as used
      e.preventDefault();

      selectSingleItem(theme.id);
    },
    [selectSingleItem, theme.id],
  );
  return (
    <Draggable key={`${theme.id}`} draggableId={`${theme.id}`} index={props.index}>
      {(dragProvided: DraggableProvided) => {
        return (
          <ThemeCollectionContainer ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
            <Box
              width="100%"
              borderRadius="5px"
              border="solid 1px #DDDDE3"
              background="white"
              my="8px"
              p={16}
              boxShadow="0 2px 0 rgba(0, 0, 0, 0.015)"
            >
              <Box display="flex" flex={1} alignItems="center">
                <Box
                  {...dragProvided?.dragHandleProps}
                  display={hasEditingAuthority ? 'block' : 'none'}
                  mr={hasEditingAuthority ? 15 : 0}
                >
                  <MenuOutlined
                    style={{ cursor: 'move' }}
                    onClick={selectDragAction}
                    onKeyDown={selectDragAction}
                  />
                </Box>

                <Box display="flex" flex={1} alignItems="center">
                  <Box display="flex" flex={1} flexDirection="column" px={16}>
                    <Box fontSize="16px" fontWeight={500}>
                      {theme.name}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ThemeCollectionContainer>
        );
      }}
    </Draggable>
  );
};
export default ThemeOperation;
