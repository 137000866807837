import { getCollectionPage, updateCollectionPage } from '@/services/collectionPages';
import { createCollection, updateCollection } from '@/services/collections';
import { EffectsCommandMap } from '@/utils/dva-model-creator';
import { COLLECTION_ITEMS_LIMIT } from '../collection-page/constants';
import { getCollectionPageAction } from '../collection-page/useCase';
import { requestPageAction } from '../page/useCase';
import {
  createCollectionAction,
  deleteCollection,
  fetchMoreCollectionItemsAction,
  updateCollectionAction,
} from './useCase';

export function* handleExecuteCreateCollection(payload: any, { call, put }: EffectsCommandMap) {
  const { form, onSuccess } = payload;
  try {
    const { collection, collectionPage } = form;
    const resp: any = yield call(createCollection, {
      ...form,
      skus: collection.skus,
      skusFileName: collection.skusFileName,
    });
    // After create collection, put update collection page
    yield call(updateCollectionPage, collectionPage);

    yield put(createCollectionAction.success());
    if (onSuccess) {
      onSuccess(resp);
    }
    yield put(
      getCollectionPageAction.execute({
        collectionPageId: collectionPage.id,
      }),
    );
    return resp;
  } catch (error) {
    console.error(error);
    yield put(createCollectionAction.fail(error));
  }
}

export function* handleExecuteDeleteCollection(
  { collectionPageId }: { id: string; collectionPageId: string },
  { put }: EffectsCommandMap,
) {
  try {
    // yield call(collectionsServices.deleteCollection, id);
    yield put.resolve(
      requestPageAction.execute({
        pageId: collectionPageId,
      }),
    );
    yield put(deleteCollection.success());
  } catch (error) {
    yield put(deleteCollection.fail(error));
  }
}

export function* handleExecuteUpdateCollection(payload: any, { call, put }: EffectsCommandMap) {
  const {
    form: { collectionPage, collection },
    onSuccess,
  } = payload;

  try {
    yield call(updateCollection, {
      collectionPage,
      collection,
    });
    yield call(updateCollectionPage, collectionPage);

    yield put.resolve(
      getCollectionPageAction.execute({
        collectionPageId: collectionPage.id,
      }),
    );
    yield put(updateCollectionAction.success());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(updateCollectionAction.fail(error));
  }
}

export function* handleFetchMoreCollectionItems(
  { collectionPageId, collectionId, page }: any,
  { call, put }: EffectsCommandMap,
) {
  try {
    const cursor = page * COLLECTION_ITEMS_LIMIT;
    const collectionPage = yield call(getCollectionPage, {
      collectionPageId,
      filter: { cursor, limit: COLLECTION_ITEMS_LIMIT },
    });

    const { data, next_page } = collectionPage;

    yield put(
      fetchMoreCollectionItemsAction.success({ collectionId, data, isLastPage: !next_page }),
    );
  } catch (err) {
    yield put(fetchMoreCollectionItemsAction.fail(err));
  }
}
