import { WidgetType } from '@/infras/widget/constants';
import { Widget } from '@/infras/widget/typing';
import TextSettings from './RichTextSettings';
import RichTextView from './RichTextView';
import RichTextDraggable from './RichTextDraggable';
import { IRichTextSettings } from './types';

const widget = {
  type: WidgetType.RICH_TEXT,
  View: RichTextView,
  Settings: TextSettings,
  Draggable: RichTextDraggable,
  default: {
    type: WidgetType.RICH_TEXT,
    payload: {
      text: {},
      color: '#111',
      backgroundColor: 'transparent',
      display: true,
    },
  } as Partial<Widget<IRichTextSettings>>,
};

export default widget;
