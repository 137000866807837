import { ThemeTypeEnum } from '@/pages/create-ngon-theme/constant';
import React from 'react';
import styled from 'styled-components';

interface IThemeType {
  themeType: ThemeTypeEnum;
}

const PROMOTION_BG_COLOR = '#FFF5EB';
const PROMOTION_COLOR = '#FC820A';
const PROMOTION_BORDER_COLOR = '#FFD1A4';


const CREATIVE_BG_COLOR = '#EFFFF4';
const CREATIVE_COLOR = '#00AB56';
const CREATIVE_BORDER_COLOR = '#B7EFC3';

const BaseContainer = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: inline;
  border: 1px solid;
`;

const ThemeType = ({ themeType }: IThemeType) => {
  if (themeType === ThemeTypeEnum.CREATIVE) {
    return (
      <BaseContainer
        style={{
          color: CREATIVE_COLOR,
          backgroundColor: CREATIVE_BG_COLOR,
          borderColor: CREATIVE_BORDER_COLOR,
        }}
      >
        Creative
      </BaseContainer>
    );
  }
  if (themeType === ThemeTypeEnum.PROMOTION) {
    return (
      <BaseContainer
        style={{
          color: PROMOTION_COLOR,
          backgroundColor: PROMOTION_BG_COLOR,
          borderColor: PROMOTION_BORDER_COLOR,
        }}
      >
        Promotion / Partnership
      </BaseContainer>
    );
  }
  return <div>{'-'}</div>;
};

export default ThemeType;
