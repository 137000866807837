import {
  CollectionPageFormData,
  CollectionPageRequest,
  CollectionPagesRequest,
  UpdateCollectionPageRequest,
} from '@/infras/collection-page/typing';
// import { Page } from '@/infras/page/typing';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
import { stringify } from 'qs';
import { mapApiDataToCampaignsRes } from '@/pages/campaigns/mapper';
import { COLLECTION_ITEMS_LIMIT, ViewType } from '@/infras/collection-page/constants';
import { collectionBackgroundImages } from '@/infras/collection/constants';
import { getOGImg } from '@/pages/collections/mapper';

export async function getCollectionPages(req: CollectionPagesRequest): Promise<ListProps<any>> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/collections?${qs}`);
  return mapApiDataToCampaignsRes(data);
}

export async function createCollectionPage(collectionPage: CollectionPageFormData) {
  const customHandle = true;

  const data = await apiRequest(
    `/collections`,
    {
      method: 'POST',
      data: {
        ...collectionPage,
        ogImage: getOGImg(collectionPage?.ogImage?.[0]),
        status: 0,
        sellerId: 0,
        backgroundImage: collectionBackgroundImages[0],
        style: ViewType.LIST,
        textColor: '#fff',
      },
    },
    customHandle,
  );
  return data;
}

export async function createThemeCollectionPage(collectionPage: CollectionPageFormData) {
  const customHandle = true;

  const data = await apiRequest(
    `/collections`,
    {
      method: 'POST',
      data: {
        ...collectionPage,
        ogImage: getOGImg(collectionPage?.ogImage?.[0]),
        sellerId: 0,
        backgroundImage: collectionBackgroundImages[0],
        style: ViewType.LIST,
        textColor: '#fff',
      },
    },
    customHandle,
  );
  return data;
}

export async function updateCollectionPage(collectionPage: UpdateCollectionPageRequest) {
  return apiRequest(`/collections/${collectionPage.id}`, {
    method: 'PUT',
    data: collectionPage,
  });
}

export async function getCollectionPage(req: CollectionPageRequest): Promise<any> {
  const {
    collectionPageId,
    filter: { cursor, limit } = { cursor: 0, limit: COLLECTION_ITEMS_LIMIT },
  } = req;
  const resp: any = await apiRequest(
    `/collections/${collectionPageId}?cursor=${cursor}&limit=${limit}`,
  );
  const { collection_meta_data, meta_data, data } = resp;

  return {
    meta_data: {
      ...meta_data,
      cid: collection_meta_data?.id,
    },
    collection_meta_data,
    data: data || [],
  };
}
