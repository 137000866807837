export const DEFAULT_RICH_TEXT_TOOLBAR = [
  [
    {
      header: 1,
    },
    {
      header: 2,
    },
    'bold',
    'italic',
    'underline',
  ],
  ['link'],
  ['clean'],
];

export const MAX_CONTENT_LENGTH = 10000;
