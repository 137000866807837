import Box from '@/components/Box';
import cookieService from '@/services/cookie';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table, Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { FC, useCallback, useState } from 'react';
import { createRelevantLinksReportFile, getCodeFromFile } from './util';

const SKUS_LIMIT = 30;

const UploadRelevantsField: FC<any> = ({
  value,
  onChange,
  fileName = value?.file?.name || 'Tải lên danh sách liên quan .xlsx',
}) => {
  const { file = {}, links = [] } = value || { file: {}, links: [] };

  const [loading, setLoading] = useState(false);
  const token = cookieService.getUserToken();

  const handleChange = useCallback((info: UploadChangeParam) => {
    const { file } = info;
    if (file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (file.status === 'done') {
      // Get this url from response in real world.
      setLoading(false);

      if (file?.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
    }
  }, []);

  const beforeUpload = useCallback(
    (file: any) => {
      const isAllowed =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'text/csv';
      if (!isAllowed) {
        message.error('Vui lòng kiểm tra lại file');
        return false;
      }

      const getCode = async () => {
        const links: any = await getCodeFromFile(file);
        if (links.length === 0) {
          message.error('Vui lòng kiểm tra lại nội dung của file');
          return;
        }
        if (links.length > SKUS_LIMIT) {
          message.error(`Tối đa được ${SKUS_LIMIT} link`);
          return;
        }

        onChange({ links, file: { name: file.name } });
      };
      getCode();

      return isAllowed;
    },
    [onChange],
  );

  const dummyRequest = useCallback(({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  }, []);

  const handleClickDelete = useCallback(
    (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      onChange();
    },
    [onChange],
  );

  const downReportFile = () => {
    createRelevantLinksReportFile({ links, fileName });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
  ];

  return (
    <>
      <Box pb={10} display="flex" flex={1} flexDirection="column">
        <Box p={12} display="flex" flex={1} border="solid 1px #DDDDE3" alignItems="center">
          <Box display="flex" flex={1}>
            {file?.name ? (
              <Box display="flex" flex={1} bg="#F5F5FA" px="7px">
                <Box
                  display="flex"
                  flex={1}
                  onClick={downReportFile}
                  color="#0B74E5"
                  style={{ cursor: 'pointer' }}
                >
                  {fileName}
                </Box>
                <Box onClick={handleClickDelete}>
                  <CloseOutlined />
                </Box>
              </Box>
            ) : (
              fileName
            )}
          </Box>
          <Box width="2px" height="22px" mx="16px" bg="#DDDDE3" />
          <Upload
            customRequest={dummyRequest}
            headers={{ Authorization: `Basic ${token}` }}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            itemRender={() => null}
          >
            <Box color="#0B74E5" style={{ cursor: 'pointer' }}>
              {loading ? <LoadingOutlined /> : 'Upload'}
            </Box>
          </Upload>
        </Box>
      </Box>
      <>
        <Table
          dataSource={value?.links || []}
          columns={columns}
          rowKey="url"
          pagination={{ pageSize: 6 }}
        />
      </>
      <a
        style={{ marginBottom: 5 }}
        target="_blank"
        href="/relevant-links.xlsx"
        download="/relevant-links.xlsx"
      >
        Download biểu mẫu
      </a>
    </>
  );
};
export default UploadRelevantsField;
