import { Widget } from '@/infras/widget/typing';
import { ColumnSettings } from '../banner/types';

export enum SliderType {
  ARROW = 1,
  DOTS = 2,
  PORTION = 3,
}
export interface ImageSettings {
  url: string;
  name: string;
  status: string;
  index: number;
  uid: string;
  urlLink: string;
  anchorId: string;
  height?: number;
  width?: number;
  dominantColor?: string;
}

export interface SliderSettings {
  column: ColumnSettings<SliderType>;
  images: Array<any | ImageSettings>;
  backgroundColor: string;
  borderRadius?: boolean;
  padding?: boolean;
  centerMode: boolean;
  dots?: boolean;
}

export type SliderWidget = Widget<SliderSettings>;
