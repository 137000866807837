import { createUseCase } from '@/utils/dva-model-creator/async';
import { socketContext } from './constants';

export const { action: connectSocket, hook: connectSocketHook } = createUseCase<any>(
  socketContext,
  'ConnectSocket',
);

export const { action: disConnectSocket, hook: disConnectSocketHook } = createUseCase(
  socketContext,
  'DisConnectSocket',
);
