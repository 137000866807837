import Box from '@/components/Box';
import { NgonQuickLinkFormData } from '@/infras/ngon-quick-link/typing';
import React from 'react';
import { Image } from 'antd';

interface IThemeInfo {
  theme: NgonQuickLinkFormData;
}

const ThemeInfo = ({ theme }: IThemeInfo) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" width={135} justifyContent="center" pr="10px">
        <Image
          src={theme?.image_url || theme?.imageUrl || 'https://placehold.it/90?text=No%20Image'}
          alt={theme.title}
        />
      </Box>
      <Box display="flex" flexDirection="column" maxWidth={600} wordBreak="break-all">
        <Box as="a" href={theme.url} style={{ fontSize: 14, lineHeight: '20px', color: '#0B74E5' }}>
          {theme.title}
        </Box>
        <Box>{theme.url}</Box>
      </Box>
    </Box>
  );
};

export default ThemeInfo;
