export const widgetsContext = 'pageWidgets';

export enum WidgetType {
  PRODUCT = 'PRODUCT',
  FLASH_DEAL = 'FLASH_DEAL',
  BANNER = 'BANNER',
  SLIDER = 'SLIDER',
  TEXT = 'TEXT',
  RICH_TEXT = 'RICH_TEXT',
  CMS_RICH_TEXT = 'CMS_RICH_TEXT',
  COUPON = 'COUPON',
  TIMER = 'TIMER',
  POPUP = 'POPUP',
  VIDEO = 'VIDEO',
  PERSONALIZE = 'PERSONALIZE',
  WAY_POINT = 'WAY_POINT',
  NORMAL_DEAL = 'NORMAL_DEAL',
  NAVIGATION = 'NAVIGATION',
  COUPON_HUB = 'COUPON_HUB',
  COLLECTION = 'COLLECTION',
  FORM = 'FORM',
  VOTING = 'VOTING',
  REDEEM = 'REDEEM',
  SLOT_MACHINE = 'SLOT_MACHINE',
  CATEGORY_BRAND = 'CATEGORY_BRAND',
  CATEGORY_SLIDER = 'CATEGORY_SLIDER',
  ASA_COUPON = 'ASA_COUPON',
  SELLER_COUPON = 'SELLER_COUPON',
}

export enum PageType {
  CAMPAIGN = 'CAMPAIGN',
  CATEGORY = 'CATEGORY',
}
