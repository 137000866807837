import { ProColumns } from '@ant-design/pro-table';
import CategoryStatus from '@/pages/categories/components/CategoryStatus';
import React from 'react';
import { Link } from 'umi';
import CategoryOperating from '@/pages/categories/components/CategoryOperating';

export enum TikiCategoryType {
  BRAND = 'BRAND',
  AUTHOR = 'AUTHOR',
  CATEGORY = 'CATEGORY',
  CATEGORY_BRAND = 'CATEGORY_BRAND',
  SEARCH = 'SEARCH',
}
export const categoryText = {
  [TikiCategoryType.CATEGORY]: {
    title: 'Danh mục',
  },
  [TikiCategoryType.AUTHOR]: {
    title: 'Tác giả',
  },
  [TikiCategoryType.BRAND]: {
    title: 'Thương hiệu',
  },
  [TikiCategoryType.CATEGORY_BRAND]: {
    title: 'Thương hiệu danh mục',
  },
  [TikiCategoryType.SEARCH]: {
    title: 'Tìm kiếm',
  },
};
export const itemsPerPage = [10, 15, 25, 50, 100];
export const columns: ProColumns<any>[] = [
  {
    title: 'Category Name',
    dataIndex: 'name',
    valueType: 'option',
    render: (_, category) => <Link to={`/categories/${category.id}`}>{category.name}</Link>,
  },
  {
    title: 'TikiId',
    dataIndex: 'tikiId',
    valueType: 'text',
    key: 'tikiId',
  },
  {
    title: 'Tạo bởi',
    dataIndex: 'createdBy',
    valueType: 'text',
    key: 'createdBy',
  },
  {
    title: 'Trạng thái',
    align: 'center',
    dataIndex: 'status',
    valueType: 'option',
    render: (_, category) => <CategoryStatus category={category} />,
  },
  {
    title: 'Thao tác',
    align: 'center',
    dataIndex: 'option',
    valueType: 'option',
    render: (_, category) => <CategoryOperating category={category} />,
  },
];
