import Config from '@/config';
import { PermissionType } from '@/infras/permission/constants';
import { Role } from '@/infras/role/typing';

export const prefixWidgetCollectionPageUrl = `${Config.TikiFrontend}/`;

export const getUserPermissionsFromRoles = (roles: Role[]) => {
  const finalPermissions = new Set<PermissionType>();
  roles.forEach((role) => {
    role.permissions.forEach((permission) => {
      finalPermissions.add(permission.name as PermissionType);
    });
  });
  return Array.from(finalPermissions);
};
