import { categoryWidgetsContext } from '@/infras/category-widgets/constants';
import { ICategoryPageRequestData, ICategoryWidgetsState } from '@/infras/category-widgets/typing';
import { getCategoryPageAction } from '@/infras/category-widgets/useCases';
import { getCategoryById } from '@/services/tiki-categories';
import pageServices from '@/services/pages';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';

const initialState: ICategoryWidgetsState = {
  loading: false,
};

const builder = new DvaModelBuilder(initialState, categoryWidgetsContext);

builder
  .immer(getCategoryPageAction.execute, (state) => {
    state.loading = true;
    state.error = undefined;
    return state;
  })
  .immer(getCategoryPageAction.success, (state, payload) => {
    state.loading = false;
    state.page = payload.page;
    state.category = payload.category;
    return state;
  })
  .immer(getCategoryPageAction.fail, (state, payload: any) => {
    state.loading = false;
    state.error = payload;
    return state;
  })
  .takeLatest<any>(getCategoryPageAction.execute, function* (
    payload: ICategoryPageRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    const { pageId, categoryId } = payload;
    try {
      const category = yield call(getCategoryById, categoryId);
      const page = yield call(pageServices.getPage, { pageId: pageId.toString() });

      yield put(
        getCategoryPageAction.success({
          page,
          category,
        }),
      );
    } catch (err) {
      yield put(getCategoryPageAction.fail(err));
    }
  });

export default builder.build();
