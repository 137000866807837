import { mapDataWidgetCollectionPagesReq } from '@/pages/widget-collection/mapper';
import {
  createWidgetCollectionPage,
  getWidgetCollectionPage,
  getWidgetCollectionPages,
  updateWidgetCollectionPage,
} from '@/services/widgetCollectionPage';
import { EffectsCommandMap } from '@/utils/dva-model-creator';
import { Collection } from '../collection/typing';
import { setCollections } from '../collection/useCase';
import {
  WidgetCollectionPageFormData,
  WidgetCollectionPageRequest,
  WidgetCollectionPagesRequestData,
} from './typing';
import {
  createWidgetCollectionPageAction,
  getWidgetCollectionPageAction,
  getWidgetCollectionPagesAction,
  updateWidgetCollectionPageAction,
} from './useCase';

export function* handleExecuteGetListCPages(
  request: WidgetCollectionPagesRequestData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const response: any = yield call(
      getWidgetCollectionPages,
      mapDataWidgetCollectionPagesReq(request),
    );
    yield put(getWidgetCollectionPagesAction.success({ request, response }));
  } catch (error) {
    yield put(getWidgetCollectionPagesAction.fail(error));
  }
}

export function* handleExecuteGetOneCPage(
  request: WidgetCollectionPageRequest,
  { call, put }: EffectsCommandMap,
) {
  try {
    const resp: any = yield call(getWidgetCollectionPage, request);
    const { collection_meta_data: collection = {}, meta_data, data, next_page } = resp;

    const response: {
      id: any;
      payload: Collection;
    } = {
      id: collection?.id,
      payload: {
        id: collection?.id,
        metaData: {
          ...meta_data,
          name: collection.name,
          description: collection.description,
        },
        uiData: {
          collectionPageId: meta_data.id,
          collectionId: collection.id,
          title: collection.name,
          subtitle: collection.description,
          backgroundImage: meta_data.backgroundImage,
          style: meta_data.style,
          textColor: meta_data.textColor,
          productList: data,
          page: 0,
          isLastPage: !next_page,
        },
      },
    };

    yield put(
      setCollections({
        response,
      }),
    );

    yield put(
      getWidgetCollectionPageAction.success({
        request,
        response: {
          ...meta_data,
          collections: collection?.id ? [collection.id] : [],
        },
      }),
    );
  } catch (err) {
    console.log('err', err);
    yield put(getWidgetCollectionPageAction.fail(err));
  }
}

export function* handleExecuteCreateCPage(
  payload: WidgetCollectionPageFormData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const reps = yield call(createWidgetCollectionPage, payload);
    yield put(createWidgetCollectionPageAction.success());
    yield put(getWidgetCollectionPagesAction.execute());
    return reps;
  } catch (err) {
    yield put(createWidgetCollectionPageAction.fail(err));
  }
}

export function* handleExecuteUpdateOneCPage(
  payload: WidgetCollectionPageFormData,
  { call, put }: EffectsCommandMap,
) {
  try {
    const resp: any = yield call(updateWidgetCollectionPage, payload);
    yield put(updateWidgetCollectionPageAction.success(resp));
    return resp;
  } catch (error) {
    yield put(updateWidgetCollectionPageAction.fail(error));
  }
}
