// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelCampaign0 from '/app/src/models/campaign.ts';
import ModelCategory1 from '/app/src/models/category.ts';
import ModelCategoryWidgets2 from '/app/src/models/categoryWidgets.ts';
import ModelCollection3 from '/app/src/models/collection.ts';
import ModelCollectionPage4 from '/app/src/models/collectionPage.ts';
import ModelConfig5 from '/app/src/models/config.ts';
import ModelGlobal6 from '/app/src/models/global.ts';
import ModelLogin7 from '/app/src/models/login.ts';
import ModelNgonDynamicTitle8 from '/app/src/models/ngonDynamicTitle.ts';
import ModelNgonQuickLink9 from '/app/src/models/ngonQuickLink.ts';
import ModelNgonTheme10 from '/app/src/models/ngonTheme.ts';
import ModelOauth11 from '/app/src/models/oauth.ts';
import ModelPage12 from '/app/src/models/page.ts';
import ModelPermission13 from '/app/src/models/permission.ts';
import ModelRole14 from '/app/src/models/role.ts';
import ModelSetting15 from '/app/src/models/setting.ts';
import ModelSocket16 from '/app/src/models/socket.ts';
import ModelStoreCollection17 from '/app/src/models/storeCollection.ts';
import ModelTiki18 from '/app/src/models/tiki.ts';
import ModelUser19 from '/app/src/models/user.ts';
import ModelWidget20 from '/app/src/models/widget.ts';
import ModelWidgetCollectionPage21 from '/app/src/models/widgetCollectionPage.ts';
import ModelWidgetMeta22 from '/app/src/models/widgetMeta.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(require('/app/node_modules/dva-immer/dist/index.js')());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'campaign', ...ModelCampaign0 });
app.model({ namespace: 'category', ...ModelCategory1 });
app.model({ namespace: 'categoryWidgets', ...ModelCategoryWidgets2 });
app.model({ namespace: 'collection', ...ModelCollection3 });
app.model({ namespace: 'collectionPage', ...ModelCollectionPage4 });
app.model({ namespace: 'config', ...ModelConfig5 });
app.model({ namespace: 'global', ...ModelGlobal6 });
app.model({ namespace: 'login', ...ModelLogin7 });
app.model({ namespace: 'ngonDynamicTitle', ...ModelNgonDynamicTitle8 });
app.model({ namespace: 'ngonQuickLink', ...ModelNgonQuickLink9 });
app.model({ namespace: 'ngonTheme', ...ModelNgonTheme10 });
app.model({ namespace: 'oauth', ...ModelOauth11 });
app.model({ namespace: 'page', ...ModelPage12 });
app.model({ namespace: 'permission', ...ModelPermission13 });
app.model({ namespace: 'role', ...ModelRole14 });
app.model({ namespace: 'setting', ...ModelSetting15 });
app.model({ namespace: 'socket', ...ModelSocket16 });
app.model({ namespace: 'storeCollection', ...ModelStoreCollection17 });
app.model({ namespace: 'tiki', ...ModelTiki18 });
app.model({ namespace: 'user', ...ModelUser19 });
app.model({ namespace: 'widget', ...ModelWidget20 });
app.model({ namespace: 'widgetCollectionPage', ...ModelWidgetCollectionPage21 });
app.model({ namespace: 'widgetMeta', ...ModelWidgetMeta22 });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (typeof window !== 'undefined') {
      _onCreate();
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
