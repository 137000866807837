import React, { FC } from 'react';
import { DraggableProps } from '..';
import { CategoryCollectionWidget } from './types';

export interface CollectionDraggableProps extends DraggableProps<CategoryCollectionWidget> {}

const CollectionDraggable: FC<CollectionDraggableProps> = ({ widget: { name } }) => {
  return <div>Draggable Widget: {name}</div>;
};

export default CollectionDraggable;
