import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { campaignsContext } from './constants';
import {
  Campaign,
  CampaignFormData,
  CampaignRequest,
  CampaignsRequestData,
  CampaignsResponse,
  CampaignState,
} from './typing';

export const { action: getCampaignsAction, hook: getCampaignsHook } = createAsyncUseCase<
  CampaignsRequestData,
  CampaignsResponse,
  CampaignState
>(campaignsContext, 'getCampaigns');

export const { action: getCampaignAction, hook: getCampaignHook } = createAsyncUseCase<
  CampaignRequest,
  { request: CampaignRequest; response: Campaign },
  CampaignState
>(campaignsContext, 'getCampaign');

export const { action: createCampaignAction, hook: createCampaignHook } = createAsyncUseCase<
  CampaignFormData,
  Campaign,
  CampaignState
>(campaignsContext, 'createCampaignAction', {
  readableName: 'Tạo campaign mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateCampaignAction, hook: updateCampaignHook } = createAsyncUseCase<
  Campaign,
  Campaign,
  CampaignState
>(campaignsContext, 'updateCampaignAction', {
  readableName: 'Lưu thay đổi campaign',
  messageSettings: { initial: true, success: true },
});

export const { action: resetCampaignRequestAction, hook: resetCampaignRequestHook } = createUseCase(
  campaignsContext,
  'resetRequest',
);
