import {
  StoreCollection,
  StoreCollectionFormData,
  StoreCollectionRequest,
} from '@/infras/store/typing';
import { createAsyncUseCase } from '@/utils/dva-model-creator/async';
import {
  StoreCollectionsRequestData,
  StoreCollectionsResponse,
  StoreCollectionsState,
} from './typing';
import { StoreCollectionsContext } from './constants';

export const {
  action: getStoreCollectionsAction,
  hook: getStoreCollectionsHook,
} = createAsyncUseCase<
  StoreCollectionsRequestData,
  StoreCollectionsResponse,
  StoreCollectionsState
>(StoreCollectionsContext, 'getStoreCollections');

export const {
  action: createStoreCollectionAction,
  hook: createStoreCollectionHook,
} = createAsyncUseCase<StoreCollectionFormData, StoreCollection, StoreCollectionsState>(
  StoreCollectionsContext,
  'createStoreCollection',
);

export const {
  action: getStoreCollectionAction,
  hook: getStoreCollectionHook,
} = createAsyncUseCase<
  StoreCollectionRequest,
  { request: StoreCollectionRequest; response: StoreCollection },
  StoreCollectionsState
>(StoreCollectionsContext, 'getStoreCollection');

export const {
  action: updateStoreCollectionAction,
  hook: updateStoreCollectionHook,
} = createAsyncUseCase<Partial<StoreCollection>, StoreCollection, StoreCollectionsState>(
  StoreCollectionsContext,
  'updateStoreCollectionAction',
  {
    readableName: 'Lưu thay đổi Bộ sưu tập Stores',
    messageSettings: { initial: true, success: true },
  },
);
