import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { collectionsContext } from './constants';
import { Collection, ICollectionProduct } from './typing';

export const { action: setCollections, hook: setCollectionsHook } = createUseCase<{
  response: any;
}>(collectionsContext, 'setCollections');

export const { action: resetErrorCollections, hook: resetErrorCollectionsHook } = createUseCase<{
  response: any;
}>(collectionsContext, 'resetErrorCollections');

export const { action: deleteCollection, hook: deleteCollectionHook } = createAsyncUseCase<
  any,
  any
>(collectionsContext, 'deleteCollection', {
  readableName: 'Xóa collection',
  messageSettings: { initial: true, success: true },
});

export const { action: cloneCollection, hook: cloneCollectionHook } = createAsyncUseCase<
  {
    pageId: string;
    collection: Collection;
    collections: string[];
  },
  any
>(collectionsContext, 'cloneCollection', {
  readableName: 'Clone collection',
  messageSettings: { initial: true, success: true },
});

export const { action: createCollectionAction, hook: createCollectionHook } = createAsyncUseCase<
  any,
  Collection,
  any
>(collectionsContext, 'createCollectionAction', {
  readableName: 'Tạo collection mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateCollectionAction, hook: updateCollectionHook } = createAsyncUseCase<
  { form: Collection; onSuccess: Function },
  { response: Collection; error?: Error },
  any
>(collectionsContext, 'updateCollectionAction', {
  readableName: 'Lưu thay đổi collection',
  messageSettings: { initial: true, success: true },
});

export const {
  action: fetchMoreCollectionItemsAction,
  hook: fetchMoreCollectionItemsHook,
} = createAsyncUseCase<
  { collectionPageId: number; collectionId: number; page: number },
  { collectionId: number; data: ICollectionProduct[]; isLastPage: boolean }
>(collectionsContext, 'fetchMoreCollectionItemsAction');
