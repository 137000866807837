import React from "react";

const Edit = ({ ...props }) => {
  return (
    <img
      src="https://salt.tikicdn.com/ts/brickv2og/ef/f7/d5/7cef11d2556425465beead7063d35bf8.png"
      width="16"
      height="16"
      {...props}
    />
  );
};

export default Edit;
