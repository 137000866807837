import Config from '@/config';
import React, { FC, useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { Modal, Input, ModalProps, Upload, Typography, message } from 'antd';
import { last } from 'lodash';
import { InboxOutlined } from '@ant-design/icons';
import cookieService from '@/services/cookie';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { handleSize } from './toolbar';
import Box from '../Box';
import { QuillImage } from './types';
import { formatImageFileName } from '@/utils/helpers';

const { Dragger } = Upload;

interface ImageModalProps extends ModalProps {
  onConfirm: (img: QuillImage) => void;
  image?: QuillImage | null;
}

const ImageModal: FC<ImageModalProps> = ({ onConfirm, image, ...props }) => {
  const token = useMemo(() => cookieService.getUserToken(), []);
  const [err, setErr] = useState('');
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const imgRef = useRef<QuillImage | null | undefined>(null);
  const [altText, setAltText] = useState<string>('');

  const handleOk = useCallback(() => {
    const imageUrl = imgRef.current?.url || '';
    if (!imageUrl) {
      setErr('Vui lòng Chọn ảnh');
      return;
    }
    if (
      !imageUrl.startsWith('https://salt.tikicdn.com') &&
      !imageUrl.startsWith('https://uat.tikicdn.com')
    ) {
      setErr('Vui lòng upload lại ảnh');
      return;
    }
    {
      onConfirm({
        ...imgRef.current,
      });
      imgRef.current = null;
      setAltText('');
    }
  }, [onConfirm]);

  useEffect(() => {
    imgRef.current = image;
    setErr('');
    if (image?.url) {
      const name = last(image.url.split('/')) || '';
      const type = last(name.split('.')) || '';
      setFiles([
        {
          uid: '',
          type,
          name,
          size: 0,
          url: image.url,
        },
      ]);
      setAltText(image.alt);
    } else {
      setFiles([]);
      setAltText('');
    }
  }, [image]);

  const draggerProps = useMemo(
    () => ({
      name: 'file',
      accept: '.png, .jpg, .jpeg',
      multiple: false,
      action: `${Config.BrickAPI}/upload`,
      maxCount: 1,
      headers: { Authorization: `Basic ${token}` },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: true,
      },
      onChange(info: UploadChangeParam<UploadFile<any>>) {
        setErr('');
        const { status, response } = info.file;
        switch (status) {
          case 'done': {
            // AFter uploaded
            message.success(`${info.file.name} tải lên thành công.`);
            if (response && info.file.originFileObj instanceof File) {
              handleSize(info.file.originFileObj).then((size: any) => {
                imgRef.current = {
                  ...imgRef.current,
                  ...size,
                  url: response.url,
                };
              });
            }
            break;
          }
          case 'error': {
            message.error(`${info.file.name} tải lên không thành công.`);
            break;
          }
          case 'removed': {
            // Reset
            if (imgRef.current) {
              imgRef.current.url = '';
              imgRef.current.width = 0;
              imgRef.current.height = 0;
            }
            break;
          }
          default:
            break;
        }
        setFiles(info.fileList);
      },
    }),
    [],
  );
  const handleChange = useCallback((e: any) => {
    if (imgRef.current) {
      imgRef.current.alt = (e.target.value || '').trim();
    }
    setAltText(e.target.value);
  }, []);

  return (
    <Modal title="Tải lên hình" centered onOk={handleOk} {...props}>
      {image && (
        <Dragger
          {...draggerProps}
          fileList={files}
          beforeUpload={(file, fileList) => {
            return new Promise((resolve) => {
              resolve(new File([file], formatImageFileName(file.name), { type: file.type }));
            });
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Nhấp hoặc thả hình vào đây để upload</p>
        </Dragger>
      )}
      <Box my="8px">
        <Input
          name="alt"
          placeholder="Nhập vào Alt (không bắt buộc)"
          value={altText}
          onChange={handleChange}
        />
      </Box>
      {err && <Typography.Text type="danger">{err}</Typography.Text>}
    </Modal>
  );
};

export default ImageModal;
