import React, {FC, useCallback, useMemo, useState} from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import {Input, Select} from 'antd';
import {StyledBox} from './styles';

export interface Filter {
  key: string,
  value: any
}

interface FilterBarProps {
  currentFilter: Filter,
  filterMap: {
    [key: string]: {
      key: string,
      title: string,
      options?: {
        [key: string]: any
      }
    }
  }
  onChange: (filter: Filter) => void
}

const FilterBar: FC<FilterBarProps> = ({
   currentFilter = {
     key: '',
     value: ''
   },
   filterMap,
   onChange = () => {}
 }) => {
  const { value } = currentFilter;

  const [searchData, setSearchData] = useState(currentFilter);

  const searchDataOptions: {
    [key: string]: any
  } | undefined = useMemo(() => {
    return get(filterMap[searchData.key], 'options');
  }, [searchData.key]);

  const handleSearch = useCallback(
    debounce(
      (newFilter: Filter) => {
        onChange(newFilter);
      },
      500,
    ),
    [onChange]
  );

  const handleKeyChange = useCallback(
    (newKey: string) => {
      setSearchData({ key: newKey, value });
      if (value) {
        handleSearch({ key: newKey, value });
      }
    },
    [handleSearch, value],
  );

  const handleInputChange = useCallback(
    (newValue) => {
      setSearchData({ key: searchData.key, value: newValue });
      handleSearch({ key: searchData.key, value: newValue });
    },
    [handleSearch, searchData.key],
  );

  return (
    <StyledBox p='20px' bg='white' mb='10px'>
      <Select
        className='filter-key'
        value={searchData.key}
        style={{ width: 220 }}
        onChange={handleKeyChange}
      >
        {
          Object.entries(filterMap).map(([key, value]) => (
            <Select.Option key={key} value={key}>{value.title}</Select.Option>
          ))
        }
      </Select>
      {
        searchDataOptions
          ?
          <Select
            value={searchData.value}
            onChange={handleInputChange}
            style={{ width: 150 }}
          >
            {
              Object.entries(searchDataOptions).map(([key, value]) => (
                <Select.Option key={value} value={value}>{key}</Select.Option>
              ))
            }
          </Select>
          :
          <Input
            value={searchData.value}
            onChange={({target: { value = '' }}) => handleInputChange(value)}
            style={{width: 'calc(100% - 220px)'}}
          />
      }
    </StyledBox>
  );
}

export default FilterBar;
