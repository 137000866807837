import {
  UpdateWidgetCollectionPageRequest,
  WidgetCollectionPageFormData,
  WidgetCollectionPageRequest,
  WidgetCollectionPagesRequest,
} from '@/infras/widget-collection-page/typing';
// import { Page } from '@/infras/page/typing';
import { COLLECTION_ITEMS_LIMIT } from '@/infras/widget-collection-page/constants';
import { mapApiDataToCampaignsRes } from '@/pages/campaigns/mapper';
import { getOGImg } from '@/pages/widget-collection/mapper';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
import { stringify } from 'qs';

export async function getWidgetCollectionPages(
  req: WidgetCollectionPagesRequest,
): Promise<ListProps<any>> {
  const qs = stringify(req);
  const data: any = await apiRequest(`/widget_collections?${qs}`);
  return mapApiDataToCampaignsRes(data);
}

export async function createWidgetCollectionPage(collectionPage: WidgetCollectionPageFormData) {
  const customHandle = true;

  const data = await apiRequest(
    `/widget_collections`,
    {
      method: 'POST',
      data: {
        ...collectionPage,
        image: getOGImg(collectionPage?.imageUrl?.[0]),
      },
    },
    customHandle,
  );
  return data;
}

export async function createThemeWidgetCollectionPage(
  collectionPage: WidgetCollectionPageFormData,
) {
  const customHandle = true;

  const data = await apiRequest(
    `/widget_collections`,
    {
      method: 'POST',
      data: {
        ...collectionPage,
        image: getOGImg(collectionPage?.imageUrl?.[0]),
      },
    },
    customHandle,
  );
  return data;
}

export async function updateWidgetCollectionPage(
  collectionPage: UpdateWidgetCollectionPageRequest,
) {
  return apiRequest(`/widget_collections/${collectionPage.id}`, {
    method: 'PUT',
    data: collectionPage,
  });
}

export async function deleteWidgetCollectionPage(id: string) {
  return apiRequest(`/widget_collections/${id}`, {
    method: 'DELETE',
  });
}

export async function changeStatusWidgetCollectionPage(id: string, status: 0 | 1) {
  return apiRequest(`/widget_collections/${status ? 'enable' : 'disable'}/${id}`, {
    method: 'PUT',
  });
}

export async function updateWidgetCollectionPageIndex(
  collectionPage: UpdateWidgetCollectionPageRequest,
) {
  return apiRequest(`/widget_collections/switch/index`, {
    method: 'PUT',
    data: collectionPage,
  });
}
export async function getWidgetCollectionPage(req: WidgetCollectionPageRequest): Promise<any> {
  const {
    collectionPageId,
    filter: { cursor, limit } = { cursor: 0, limit: COLLECTION_ITEMS_LIMIT },
  } = req;
  const resp: any = await apiRequest(
    `/widget_collections/${collectionPageId}?cursor=${cursor}&limit=${limit}`,
  );
  const { collection_meta_data, meta_data, data } = resp;

  return {
    meta_data: {
      ...meta_data,
      cid: collection_meta_data?.id,
    },
    collection_meta_data,
    data: data || [],
  };
}
