import Config from '@/config';

export const getBrandSlug = (url: string) => {
  const [brandLink] = url.split('?');
  return brandLink.replace(`${Config.TikiFrontend}/thuong-hieu/`, '').slice(0, -5);
};

export const getAuthorSlug = (url: string) => {
  const [brandLink] = url.split('?');
  return brandLink.replace(`${Config.TikiFrontend}/author/`, '').slice(0, -5);
};
