import { Widget } from '@/infras/widget/typing';

export enum CouponHubType {
  CATEGORY = 'CATEGORY',
  TOP_SELLER = 'TOP_SELLER',
  SHIPPING_RULE = 'SHIPPING_RULE',
}

export type Category = {
  id: number;
  parent_id: number | null;
  name: string;
  thumbnail_url: string | null;
};

export type CategoryConfiguration = {
  categoryId: number;
  categoryName: string;
  categoryLabel: string;
  thumbnail: string;
  displayName: string;
};

export type CouponHubSettings = {
  type: CouponHubType;
  applicableCategories?: CategoryConfiguration[];
  categoriesFontColor?: string;
  name?: string;
};

export type CouponHubWidget = Widget<CouponHubSettings>;
