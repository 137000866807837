import ColorPicker from '@/components/core/ColorPicker';
import { useEditorContext } from '@/infras/brick-editor/useEditorContext';
import { Form, Radio } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { EditSettingsProps } from '..';
import TightFormItem from '../shared/TightFormItem.styled';
import EditHeadingSettings from './fields/HeadingSettings';
import EditParagraphSettings from './fields/ParagraphSettings';
import { TextSettings, TextWidget } from './types';
import NameFieldItemSettings from '../shared/NameFieldItem';

const horizontalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const verticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const StyledFormItem = styled(Form.Item)`
  > .ant-form-item-control {
    > .ant-form-item-control-input {
      > .ant-form-item-control-input-content {
        > .ant-radio-group {
          > .ant-radio-button-wrapper {
            width: 130px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

const ColorPickerFormItem = styled(TightFormItem)`
  .ant-form-item-label > label {
    padding-top: 10px;
  }
`;

const EditorSettings: any = {
  heading: EditHeadingSettings,
  paragraph: EditParagraphSettings,
};

const TextWidgetSettings: FC<EditSettingsProps<TextWidget>> = ({ widget, onChange }) => {
  const [payloadState, setPayloadState] = useState<TextSettings>({ ...widget.payload });
  const [editForType, setEditForType] = useState<string>(widget.payload.textType || 'heading');

  const { fieldSettingContainer, toggleFieldSettings } = useEditorContext();
  const onFieldChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange({
          ...payloadState,
          [editForType]: value,
        } as any);
      }
      setPayloadState({
        ...payloadState,
        [editForType]: value,
      });
    },
    [onChange, payloadState, editForType],
  );

  useEffect(() => {
    // Reset widget state when change
    if (widget) {
      setPayloadState({
        ...widget.payload,
      });
    }
  }, [widget]);

  const textValidator = useCallback(
    async (data: any, input: any) => {
      if (!input?.text) {
        toggleFieldSettings(true);
        return Promise.reject(new Error('Vui lòng nhập vào nội dung!'));
      }
      return Promise.resolve();
    },
    [editForType],
  );

  const fieldSetting = useMemo(() => {
    if (fieldSettingContainer && editForType) {
      const Settings = EditorSettings[editForType];
      if (Settings) {
        return createPortal(
          <StyledFormItem name={editForType} rules={[{ validator: textValidator }]}>
            <Settings
              {...payloadState[editForType]}
              onClose={toggleFieldSettings}
              onChange={onFieldChange}
            />
          </StyledFormItem>,
          fieldSettingContainer,
        );
      }
    }
    return null;
  }, [fieldSettingContainer, editForType, textValidator, onFieldChange]);

  const openTextFieldSettings = useCallback(
    (e: any) => {
      setEditForType(e.target.value);
      toggleFieldSettings(true);
    },
    [toggleFieldSettings],
  );

  return (
    <TightFormItem labelAlign="left">
      <h2>Text</h2>

      <NameFieldItemSettings widgetId={widget.id || ''} />
      <StyledFormItem label="Loại văn bản" name="textType" {...verticalLayout}>
        <Radio.Group size="large">
          <Radio.Button value="heading" onClick={openTextFieldSettings}>
            <img src="/widgets/text/header.png" alt="" width="50px" height="auto" />
          </Radio.Button>
          <Radio.Button value="paragraph" onClick={openTextFieldSettings}>
            <img src="/widgets/text/paragraph.png" alt="" width="50px" height="auto" />
          </Radio.Button>
        </Radio.Group>
      </StyledFormItem>

      <ColorPickerFormItem {...horizontalLayout} name="backgroundColor" label="Màu background">
        <ColorPicker />
      </ColorPickerFormItem>
      {fieldSetting}
    </TightFormItem>
  );
};

export default TextWidgetSettings;
