import Config from '@/config';
import {
  CreateNgonQuickLinkRequest,
  NgonQuickLinkRequest,
  UpdateNgonQuickLinkRequest,
} from '@/infras/ngon-quick-link/typing';
import { QUICK_LINK_TYPE } from '@/pages/ngon-quick-link/constant';
import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
// import { stringify } from 'qs';
import request from 'umi-request';

async function getNgonQuickLinks(request: any): Promise<ListProps<any>> {
  // const qs = stringify(request?.filter);
  const data: any = await apiRequest(`/menu-links?path=${request?.path || QUICK_LINK_TYPE.HCM}`);
  return { ...data, data: data?.items.map((i: any) => ({ ...i, id: i._id || i.id })) || [] };
}

async function createNgonQuickLink(ngonQuickLink: CreateNgonQuickLinkRequest): Promise<any> {
  const customHandle = true;
  const data = await apiRequest(
    `/menu-links`,
    {
      method: 'POST',
      data: ngonQuickLink,
    },
    customHandle,
  );
  return data;
}

async function updateNgonQuickLink(ngonQuickLink: UpdateNgonQuickLinkRequest) {
  const { id, ...data } = ngonQuickLink;
  return apiRequest(`/menu-links/${id}`, {
    method: 'PUT',
    data,
  });
}

async function getNgonQuickLink(req: NgonQuickLinkRequest): Promise<any> {
  const data: any = await apiRequest(`/menu-links/${req.ngonQuickLinkId}`);
  return data;
}

export async function getThemeCollectionPublicData(slug: any): Promise<any> {
  const data: any = await request.get(
    `${Config.APIGateway}/shopping-tikifresh/v1/theme-collection/slug/${encodeURIComponent(slug)}`,
    {},
  );
  return data;
}

async function deleteNgonQuickLink(id: string | number): Promise<any> {
  await apiRequest(`/menu-links/${id}`, {
    method: 'DELETE',
  });
}

async function orderNgonQuickLink(data: any): Promise<any> {
  await apiRequest(`/menu-links/${data?.id}/index`, {
    method: 'PUT',
    data: {
      index: data.index,
    },
  });
}

export default {
  getNgonQuickLinks,
  createNgonQuickLink,
  updateNgonQuickLink,
  getNgonQuickLink,
  deleteNgonQuickLink,
  orderNgonQuickLink,
};
