import {
  CreateNgonDynamicTitleRequest,
  NgonDynamicTitleRequest,
  UpdateNgonDynamicTitleRequest,
} from '@/infras/ngon-dynamic-title/typing';

import { ListProps } from '@/typings/list';
import { apiRequest } from '@/utils/request';
// import { stringify } from 'qs';

async function getNgonDynamicTitles(request: any): Promise<ListProps<any>> {
  // const qs = stringify(request?.filter);
  const data: any = await apiRequest(`/ngon-tags`);
  return { ...data, data: data?.items.map((i: any) => ({ ...i, id: i._id || i.id })) || [] };
}

async function createNgonDynamicTitle(
  ngonDynamicTitle: CreateNgonDynamicTitleRequest,
): Promise<any> {
  const customHandle = true;
  const data = await apiRequest(
    `/ngon-tags`,
    {
      method: 'POST',
      data: ngonDynamicTitle,
    },
    customHandle,
  );
  return data;
}

async function updateNgonDynamicTitle(ngonDynamicTitle: UpdateNgonDynamicTitleRequest) {
  const { id, ...data } = ngonDynamicTitle;
  return apiRequest(`/ngon-tags/${id}`, {
    method: 'PUT',
    data,
  });
}

async function getNgonDynamicTitle(req: NgonDynamicTitleRequest): Promise<any> {
  const data: any = await apiRequest(`/ngon-tags/${req.ngonDynamicTitleId}`);
  return data;
}

async function deleteNgonDynamicTitle(id: string | number): Promise<any> {
  await apiRequest(`/ngon-tags/${id}`, {
    method: 'DELETE',
  });
}

export default {
  getNgonDynamicTitles,
  createNgonDynamicTitle,
  updateNgonDynamicTitle,
  getNgonDynamicTitle,
  deleteNgonDynamicTitle,
};
