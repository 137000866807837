import { createAsyncUseCase } from '@/utils/dva-model-creator/async';
import { TikiCategoriesRequest, TikiCategoriesResponse, TikiState } from './typing';
import { tikiContext } from './constants';

export const {
  action: fetchTikiCategoriesAction,
  hook: fetchTikiCategoriesHook,
} = createAsyncUseCase<TikiCategoriesRequest, TikiCategoriesResponse, TikiState>(
  tikiContext,
  'fetchTikiCategories',
);
