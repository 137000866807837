import ColorPicker from '@/components/core/ColorPicker';
import QuillTextInput from '@/components/quill/QuillTextInput';
import { Form, Switch } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { every } from 'lodash';
import { EditSettingsProps } from '..';
import TightFormItem from '../shared/TightFormItem.styled';
import NameFieldItemSettings from '../shared/NameFieldItem';
import { MAX_CONTENT_LENGTH } from './constants';
import { RichTextWidget } from './types';
import { StyledFormItem } from '../shared/product-normal-deal/settings/ProductNormalDealSettings.styled';

const horizontalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ColorPickerFormItem = styled(TightFormItem)`
  .ant-form-item-label > label {
    padding-top: 10px;
  }
`;

const QuillFormItem = styled(Form.Item)`
  flex-flow: column nowrap;

  [contenteditable] {
    caret-color: black;
  }

  .ql-container {
    height: 270px;
    color: ${(props: { color: string }) => props.color};
  }

  .ql-editor {
    * {
      color: inherit;
    }
  }
  .ql-snow .ql-editor {
    a {
      color: #40a9ff;
      text-decoration: underline;

      &:hover {
        color: #40a9ff;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      height: unset !important;
      max-width: 100%;
    }
  }
`;

const RichTextWidgetSettings: FC<EditSettingsProps<RichTextWidget>> = ({ widget, form }) => {
  const [color, setColor] = useState(form.getFieldValue('color'));
  const handleChangeColor = useCallback((val: string) => {
    setColor(val);
  }, []);

  const textValidator = useMemo(
    () => async (data: any, input: any) => {
      if (input?.ops) {
        if (
          every(input.ops, (op: any) => {
            // All ops are string & is empty
            return Boolean(typeof op.insert === 'string' && !op.insert.trim());
          })
        ) {
          return Promise.reject(new Error('Vui lòng nhập vào nội dung!'));
        }
      }
      if (!input?.ops || !input.ops.length) {
        return Promise.reject(new Error('Vui lòng nhập vào nội dung!'));
      }
      if (input?.length && input?.length > MAX_CONTENT_LENGTH) {
        return Promise.reject(new Error('Số lượng ký tự vượt quá giới hạn'));
      }
      return Promise.resolve();
    },
    [],
  );

  return (
    <TightFormItem labelAlign="left">
      <h2>Rich Text</h2>
      <NameFieldItemSettings widgetId={widget.id || ''} />
      <QuillFormItem
        name="text"
        label="Nội dung"
        rules={[{ validator: textValidator }]}
        color={color}
      >
        <QuillTextInput maxLength={MAX_CONTENT_LENGTH} />
      </QuillFormItem>
      <ColorPickerFormItem {...horizontalLayout} name="color" label="Màu chữ">
        <ColorPicker onChange={handleChangeColor} />
      </ColorPickerFormItem>
      <ColorPickerFormItem {...horizontalLayout} name="backgroundColor" label="Màu background">
        <ColorPicker />
      </ColorPickerFormItem>
      <StyledFormItem {...horizontalLayout} name="display" label="Display" valuePropName="checked">
        <Switch />
      </StyledFormItem>
    </TightFormItem>
  );
};

export default RichTextWidgetSettings;
