/* eslint-disable @typescript-eslint/naming-convention */
import React, { FunctionComponent } from 'react';
import FilterBar from "@/components/FilterBar";

interface CampaignProps {
  filter?: { key: string; value: string };
  onChange?: (value: any) => void;
}
export enum FILTER_KEY {
  NAME = 'name',
  CREATED_BY = 'created_by',
  URL = 'url',
}

const filterMap = {
  [FILTER_KEY.NAME]: {
    key: FILTER_KEY.NAME,
    title: 'Tên campaign'
  },
  [FILTER_KEY.CREATED_BY]: {
    key: FILTER_KEY.CREATED_BY,
    title: 'Email người tạo'
  },
  [FILTER_KEY.URL]: {
    key: FILTER_KEY.URL,
    title: 'Đường dẫn'
  },
};

const CampaignFilter: FunctionComponent<CampaignProps> = ({
  onChange = () => {},
  filter = {
    key: '',
    value: '',
  },
}) => {

  return (
    <FilterBar currentFilter={filter} filterMap={filterMap} onChange={onChange}/>
  );
};
export default CampaignFilter;
