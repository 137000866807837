import Box from '@/components/Box';
import React from 'react';
import styled from 'styled-components';

const BaseButton = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const CancelButton = styled(BaseButton)`
  color: #0b74e5;
  border: 1px solid #0b74e5;
  background-color: white;
`;

const ConfirmButton = styled(BaseButton)`
  color: white;
  border: 1px solid #0b74e5;
  background-color: #0b74e5;
`;

const DeleteModalContent = ({ onCancel, onConfirm }: any) => {
  return (
    <Box>
      <Box display="flex">
        <Box mr="8px" mb="8px">
          <img
            src="https://salt.tikicdn.com/ts/brickv2og/41/05/e3/8ee861eed77b5f5344d0663e1dddb00c.png"
            width="24"
            height="24"
          />
        </Box>

        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: '#38383D',
          }}
        >
          Xóa chủ đề
        </div>
      </Box>

      <Box ml="32px">
        <div
          style={{
            fontSize: 14,
            lineHeight: '20px',
            color: '#515158',
            marginBottom: 24,
          }}
        >
          Bạn có chắc muốn xóa chủ đề Giá tốt mỗi ngày?
        </div>
      </Box>

      <Box display="flex">
        <CancelButton style={{ marginLeft: 'auto', marginRight: '8px' }} onClick={onCancel}>
          Không
        </CancelButton>
        <ConfirmButton onClick={onConfirm}>Xác nhận</ConfirmButton>
      </Box>
    </Box>
  );
};

export default DeleteModalContent;
