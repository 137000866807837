export const isURL = (url: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (err) {
    return false;
  }
  return true;
};
