import {useEditorContext} from '@/infras/brick-editor/useEditorContext';
import useBoolean from '@/hooks/useBoolean';
import {Form, Input} from 'antd';
import React, {FC, useCallback, useState} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {EditSettingsProps} from '..';
import ItemsSettings from './fields/ItemsSettings';
import {CategoryCollectionSettings, CategoryCollectionWidget, CollectionType} from './types';
import NameFieldItemSettings from '../shared/NameFieldItem';
import Box from "@/components/Box";
import NumberInput from "@/components/controls/inputs/NumberInput";
import {MIN_ITEMS} from "@/components/widgets/category-collection/constants";

const CollectionTypeOption = styled.div`
  margin: 10px 5px;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${(props: { active?: boolean }) => (props.active ? '#40a9ff' : '#d9d9d9')};
  width: 120px;
  height: 100px;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: ${(props: { active?: boolean }) =>
  props.active && '0 0 0 2px rgba(24, 144, 255, 0.08)'};

  img {
    width: calc(100% - 10px);
    height: auto;
    margin: auto;

    &.large-img {
      width: auto;
      height: calc(100% - 10px);
    }
  }
`;

const horizontalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 10px;
  }
`;

const CollectionSettings: FC<EditSettingsProps<CategoryCollectionWidget>> = ({ widget, form }) => {
  const [show, actions] = useBoolean(true);
  const { fieldSettingContainer, toggleFieldSettings } = useEditorContext();
  const [payloadState, setPayloadState] = useState<CategoryCollectionSettings>({ ...widget.payload });

  const onLayoutChange = useCallback((layout) => () => {
    toggleFieldSettings(true);
    actions.on();
    if (payloadState.layout === layout) return;
    form.setFieldsValue({
      layout
    });
    setPayloadState({
      ...payloadState,
      layout
    });
  }, [payloadState, form, actions, toggleFieldSettings]);

  let fieldSetting;
  if (fieldSettingContainer && show) {
    fieldSetting = createPortal(
      <ItemsSettings onClose={toggleFieldSettings} form={form} />,
      fieldSettingContainer,
    );
  }
  const isLayoutActive = useCallback(
    (layout: CollectionType) => {
      return payloadState.layout === layout;
    },
    [payloadState.layout],
  );
  return (
    <div className="brand-settings">
      <h2>Category Collection</h2>
      <NameFieldItemSettings widgetId={widget.id || ''} />
      <StyledFormItem {...horizontalLayout} name="title" label="Tiêu đề">
        <Input type="text" placeholder="Nhập vào tiêu đề" />
      </StyledFormItem>
      <StyledFormItem
        {...horizontalLayout}
        name="showMax"
        label="Hiển thị tối đa"
        rules={[
          {
            validator: async (data, input) => {
              if (!input) {
                return Promise.reject(new Error(`Vui lòng nhập Số lượng tối đa hiển thị ban đầu`));
              }
              if (input < MIN_ITEMS) {
                return Promise.reject(new Error(`Số lượng tối đa hiển thị ban đầu phải trên ${MIN_ITEMS}`));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <NumberInput type="number" placeholder="Nhập vào số lượng tối đa xuất hiện trên 1 dòng" />
      </StyledFormItem>
      <StyledFormItem name="layout" label='Dàn Trang'>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth="500px"
        >
          <CollectionTypeOption
            active={isLayoutActive(CollectionType.GRID)}
            onClick={onLayoutChange(CollectionType.GRID)}
          >
            <img src="/widgets/banner/banner_2row2column.png" alt="" />
          </CollectionTypeOption>
          <CollectionTypeOption
            active={isLayoutActive(CollectionType.HORIZONTAL)}
            onClick={onLayoutChange(CollectionType.HORIZONTAL)}
          >
            <img src="/widgets/banner/banner_1row3column.png" alt="" />
          </CollectionTypeOption>
        </Box>
      </StyledFormItem>
      {fieldSetting}
    </div>
  );
};

export default CollectionSettings;
