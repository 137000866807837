import React from "react";

const Preview = ({ disabled = false, ...props }) => {
  return (
    <img
      src={
        disabled
          ? 'https://salt.tikicdn.com/ts/brickv2og/d5/e7/e8/10fb5dfda6fce611f2eba5fabf7dd3a2.png'
          : 'https://salt.tikicdn.com/ts/brickv2og/24/47/65/e90eb8fdd74c86a3746dbdc345fd0872.png'
      }
      width="16"
      height="16"
      {...props}
    />
  );
};

export default Preview;
