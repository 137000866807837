/* eslint-disable @typescript-eslint/naming-convention */
import React, { FunctionComponent, useCallback } from 'react';
import FilterBar from '@/components/FilterBar';
import { Radio } from 'antd';
import { CollectionPageType } from '@/infras/collection-page/constants';
import styled from 'styled-components';

const PageTypeWrapper = styled.div`
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

interface CollectionProps {
  filter?: { key: string; value: string };
  pageType?: CollectionPageType;
  onChange?: (field: string, value: any) => void;
}
export enum FILTER_KEY {
  NAME = 'name',
  CREATED_BY = 'created_by',
  URL = 'url',
}

const filterMap = {
  [FILTER_KEY.NAME]: {
    key: FILTER_KEY.NAME,
    title: 'Tên collection',
  },
  [FILTER_KEY.CREATED_BY]: {
    key: FILTER_KEY.CREATED_BY,
    title: 'Email người tạo',
  },
  [FILTER_KEY.URL]: {
    key: FILTER_KEY.URL,
    title: 'Đường dẫn',
  },
};

const CollectionFilter: FunctionComponent<CollectionProps> = ({
  onChange = () => {},
  pageType,
  filter = {
    key: '',
    value: '',
  },
}) => {
  const handleChangePageType = useCallback(
    (e: any) => {
      if (onChange) {
        const { value } = e.target;
        onChange('page_type', value);
      }
    },
    [onChange],
  );
  const handleChangeFilter = useCallback(
    (value: any) => {
      if (onChange) {
        onChange('filter', value);
      }
    },
    [onChange],
  );
  return (
    <div>
      <FilterBar currentFilter={filter} filterMap={filterMap} onChange={handleChangeFilter} />
      <PageTypeWrapper>
        <span>Loại Trang:&nbsp;&nbsp;</span>
        <Radio.Group onChange={handleChangePageType} defaultValue={pageType}>
          <Radio.Button value={undefined}>Tất cả</Radio.Button>
          <Radio.Button value={CollectionPageType.Normal}>Trang thường</Radio.Button>
          <Radio.Button value={CollectionPageType.TikiNGON}>Trang TikiNGON</Radio.Button>
        </Radio.Group>
      </PageTypeWrapper>
    </div>
  );
};
export default CollectionFilter;
