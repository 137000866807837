import { rolesContext } from '@/infras/role/constants';
import {
  Role,
  RoleFormData,
  RolesRequestData,
  RolesResponse,
  RoleState,
} from '@/infras/role/typing';
import {
  createRoleAction,
  getRolesAction,
  resetRoleRequestAction,
  updateRoleAction,
} from '@/infras/role/useCase';
import rolesServices from '@/services/role';
import { ACTION_STATUS } from '@/typings/redux';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { getListMapData } from '@/utils';

const initState = {
  roleMap: {},
  roleList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: 'name',
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, rolesContext);

builder
  .immer(getRolesAction.success, (state: RoleState, { request, response }: RolesResponse) => {
    const { mapData: roleMap, list: roleList } = getListMapData(response.data);
    state.roleMap = { ...state.roleMap, ...roleMap };
    state.roleList = { data: roleList, total: response.total };
    if (request) {
      state.request = { ...state.request, ...request };
    }
    return state;
  })
  .takeLatest<any>(
    getRolesAction.execute,
    function* (request: RolesRequestData, { call, put }: EffectsCommandMap) {
      try {
        const response = yield call(rolesServices.getRoles, request);
        yield put(getRolesAction.success({ request, response }));
      } catch (error) {
        yield put(getRolesAction.fail(error));
      }
    },
  );

builder
  .takeLatest<any>(
    createRoleAction.execute,
    function* (payload: RoleFormData, { call, put }: EffectsCommandMap) {
      try {
        yield call(rolesServices.createRole, payload);
        yield put(createRoleAction.success());
        yield put.resolve(getRolesAction.execute());
      } catch (err) {
        yield put(createRoleAction.fail(err));
      }
    },
  )
  .immer(createRoleAction.execute, (state: RoleState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })
  .immer(createRoleAction.success, (state: RoleState) => {
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(createRoleAction.fail, (state: RoleState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

builder.immer(resetRoleRequestAction, (state: RoleState) => {
  state.status = ACTION_STATUS.INITIAL;
  state.error = null;
  return state;
});

builder

  .takeLatest<any>(
    updateRoleAction.execute,
    function* (payload: RoleFormData, { call, put }: EffectsCommandMap) {
      try {
        const resp = yield call(rolesServices.updateRole, payload);
        yield put(updateRoleAction.success(resp));
      } catch (error) {
        yield put(updateRoleAction.fail(error));
      }
    },
  )
  .immer(updateRoleAction.success, (state: RoleState, role: Role) => {
    const { id } = role;
    state.roleMap = { ...state.roleMap, [id]: role };
    state.status = ACTION_STATUS.RESOLVED;
    return state;
  })
  .immer(updateRoleAction.execute, (state: RoleState) => {
    state.status = ACTION_STATUS.REQUESTING;
    state.error = null;
    return state;
  })

  .immer(updateRoleAction.fail, (state: RoleState, error: Error) => {
    state.status = ACTION_STATUS.REJECTED;
    state.error = error;
    return state;
  });

export default builder.build();
