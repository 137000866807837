import { RuntimeConfig } from './types';

const testConfig: RuntimeConfig = {
  TikiFrontend: 'https://localhost:8080',
  OAuthEndpoint: 'https://api.tala.xyz/oauth2',
  BrickAPI: 'http://localhost:4000/api',
  SpectrumAPI: 'https://api.tiki.vn/spectrum',
  SOAPI: 'http://localhost:4000',
  SOPath: '',
  BrickAppDomain: 'localhost',
  TikiAPI: 'https://tiki.vn/api/v2',
  APIGateway: 'https://api.tiki.vn',
  TequilaAPI: 'https://api.tala.xyz/tequila/v1/consumer',
  OAuthBasicToken: 'dGlraS1idWlsZGVyLXVpOmN0SjRYNGV5enFaUQ==',
  SentryDSN: '',
  NewAPIUrl: 'https://api.tala.xyz',
};

export default testConfig;
