import React, { FC, useCallback } from 'react';
import { Button, Col, Input, Row, Select, Space } from 'antd';
import Box from '@/components/Box';
import ColorPicker from '@/components/core/ColorPicker';
import { AlignLeftOutlined, AlignCenterOutlined } from '@ant-design/icons';

import { HeadingSettings, TextSize } from '../types';

interface Props extends HeadingSettings {
  onClose?: () => void;
  onChange: (settings: HeadingSettings) => void;
}

const EditHeadingSettings: FC<Props> = ({ size, align, color, text, onChange, onClose }) => {
  const handleTextChange = useCallback(
    (e: any) => {
      onChange({
        text: e.target.value,
        color,
        align,
        size,
      });
    },
    [color, align, size],
  );

  const handleSizeChange = useCallback(
    (value: TextSize) => {
      onChange({
        align,
        color,
        text,
        size: value,
      });
    },
    [color, text, align],
  );

  const handleChangeColor = useCallback(
    (value: string) => {
      onChange({
        color: value,
        align,
        text,
        size,
      });
    },
    [align, text, size],
  );

  return (
    <div>
      <Box my="12px">
        <Box mb="8px">Tiêu đề</Box>
        <Input.TextArea value={text} onChange={handleTextChange} placeholder="Nhập vào tiêu đề..." />
      </Box>

      <Box my="12px">
        <Row>
          <Col span={8}>Canh lề</Col>
          <Col span={16}>
            <Space>
              <Button
                type={align === 'left' ? 'primary' : 'default'}
                value="left"
                onClick={() =>
                  onChange({
                    align: 'left',
                    color,
                    text,
                    size,
                  })
                }
              >
                <AlignLeftOutlined />
              </Button>
              <Button
                type={align === 'center' ? 'primary' : 'default'}
                value="center"
                onClick={() =>
                  onChange({
                    align: 'center',
                    color,
                    text,
                    size,
                  })
                }
              >
                <AlignCenterOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
      </Box>

      <Box my="12px" className="ant-form-item-unstyled">
        <Row>
          <Col span={8}>Kích thước</Col>
          <Col span={16}>
            <Select<TextSize> value={size} onChange={handleSizeChange}>
              <Select.Option value={'small' as TextSize}>Nhỏ</Select.Option>
              <Select.Option value={'large' as TextSize}>Lớn</Select.Option>
            </Select>
          </Col>
        </Row>
      </Box>

      <Box my="12px">
        <Row>
          <Col span={8}>Màu chữ</Col>
          <Col span={16}>
            <ColorPicker value={color} onChange={handleChangeColor} />
          </Col>
        </Row>
      </Box>

      <Space>
        <Button onClick={onClose}>Đóng</Button>
      </Space>
    </div>
  );
};

export default EditHeadingSettings;
