import { Widget } from '@/infras/widget/typing';

export enum FormType {
  PARTNERSHIP = 'partnership',
  SUBSCRIBER = 'subscriber',
}

export enum QuestionType {
  SHORT_ANSWER = 'SHORT_ANSWER',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  CONFIRMATION = 'CONFIRMATION',
}

export interface FormField {
  id: string;
  questionType: QuestionType;
  question: string;
  questionDesc: string;
  options: string[];
  name: string;
  otherOption: boolean;
  required: boolean;
}

export interface FormTab {
  id: string;
  tabTitleContent: string;
  color?: string;
  formTypeInput: string;
  formType: FormType;
  seeMoreLink?: string;
  brand?: string;
}
export type FormSettings = {
  backgroundColor: string;
  fields: FormField[];
  type: FormType;
  title: string;
  emails: string[];
  submitButtonColor: string;
  submitTitle: string;
  showSubscriber: boolean;
  name?: string;
  confirmLetter?: string;
};

export interface FormGeneralSettings {
  backgroundColor: string;
  layout: {
    rowCount: number;
  };
}

export interface FormQuestionField {
  form: any;
  id: string;
  label: string;
  subtext: string;
  required: boolean;
  name: string;
  options: string[];
  otherOption: boolean;
  placeholder: string;
  getFieldError: (fieldId: string) => string;
  handleOnChange: (fieldId: string, value: any) => () => void;
  setCheckedConfirmationCount: React.Dispatch<React.SetStateAction<number>>;
  submissionSuccessful: boolean;
}

export interface OptionValue {
  optionId: string;
  optionValue: string;
  checked: boolean;
}

export interface RadioValue {
  optionId: string;
  optionValue: string;
}

export type FormWidget = Widget<FormSettings>;

export const WIDGET_TYPE = 'form';
