import { ConnectState } from '@/models/connect';
import { Action } from '@/utils/dva-model-creator';
import nanoid from '@/utils/nanoid';
import { message } from 'antd';
import { Dispatch } from 'umi';

const namespace = 'messageKeys';

const SET_KEY = `${namespace}/SET_KEY`;

const initialState = {
  effects: {},
};

const createIndicatorMessagesMiddleware = () => ({
  action: ({ getState, dispatch }: { getState: () => ConnectState; dispatch: Dispatch }) => (
    next: any,
  ) => (action: Action<any>) => {
    const { readableName, messageSettings } = action.meta || {};
    if (!readableName) return next(action);

    const { type: actionType } = action;
    const isInitAction = actionType.endsWith('_INIT');
    const isSuccessAction = actionType.endsWith('_SUCCESS');
    const isFailureAction = actionType.endsWith('_FAILURE');
    const initialShow = messageSettings?.initial;
    const successShow = messageSettings?.success;

    const initActionType = actionType.replace(/_SUCCESS|_FAILURE/, '_INIT');
    const existingMessageKey = getState().messageKeys.effects[initActionType];

    const formattedActionName = readableName.trim().toLowerCase();

    if (isInitAction) {
      const messageKey = nanoid();
      if (!existingMessageKey) {
        dispatch({ type: SET_KEY, payload: { actionType, messageKey } });
      }
      if (initialShow) {
        message.loading({
          content: `Đang ${formattedActionName}...`,
          key: existingMessageKey || messageKey,
          duration: 0,
        });
      }
    } else if (isSuccessAction) {
      if (successShow) {
        message.success({
          content: `Đã ${formattedActionName} thành công`,
          key: existingMessageKey,
        });
      } else {
        message.destroy(existingMessageKey);
      }
    } else if (isFailureAction) {
      message.destroy(existingMessageKey);
    }

    return next(action);
  },
  reducer: {
    messageKeys(state = initialState, { type, payload }: any) {
      const { actionType, messageKey } = payload || {};
      switch (type) {
        case SET_KEY:
          return {
            ...state,
            effects: { ...state.effects, [actionType]: messageKey },
          };
        default:
          return state;
      }
    },
  },
});

export default createIndicatorMessagesMiddleware;
