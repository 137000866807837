import { NgonQuickLink } from '@/infras/ngon-quick-link/typing';
import { updateNgonQuickLinkHook } from '@/infras/ngon-quick-link/useCase';
import { Modal, Switch } from 'antd';

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useFetchCurrent } from 'umi';

interface IThemeStatus {
  theme: NgonQuickLink;
}

const CustomWrapper = styled.div`
  .ant-switch-checked {
    background-color: #0b74e5;
  }
`;

const ThemeStatus = ({ theme }: IThemeStatus) => {
  const { active } = theme;
  const modalTitle = active ? 'Tạm dừng campaign' : 'Kích hoạt campaign';
  const modalContent = active
    ? 'Bạn có thật sự muốn tạm dừng quick link này không?'
    : 'Bạn có thật sự muốn kích hoạt quick link này không?';

  const [visible, setVisible] = useState(false);
  const [checked, setCheck] = useState(active);
  const { fetch } = updateNgonQuickLinkHook();
  const {
    state: { currentOauth },
  } = useFetchCurrent();
  const onCheckChange = useCallback(() => {
    setVisible(true);
  }, []);

  const handleOk = useCallback(() => {
    setCheck(!checked);
    fetch({ ...theme, active: !checked, updatedBy: currentOauth.email });
    setVisible(false);
  }, [checked, currentOauth.email, fetch, theme]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <CustomWrapper>
      <Switch checked={active} onChange={onCheckChange} />
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={checked ? 'Tạm dừng' : 'Kích hoạt'}
        cancelText="Huỷ"
      >
        <p>{modalContent}</p>
      </Modal>
    </CustomWrapper>
  );
};

export default ThemeStatus;
