import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { widgetsContext } from './constants';
import { PageWidgetsState, Widget } from './typing';

export const { action: getWidgets, hook: getWidgetsHook } = createUseCase<{
  response: any;
}>(widgetsContext, 'getWidgets');

export const { action: getChildWidgetsAction, hook: getChildWidgetsHook } = createAsyncUseCase<
  { parent: Widget },
  Widget[],
  any
>(widgetsContext, 'getChildWidgets');

export const { action: deleteWidget, hook: deleteWidgetHook } = createAsyncUseCase<any, any>(
  widgetsContext,
  'deleteWidget',
  {
    readableName: 'Xóa widget',
    messageSettings: { initial: true, success: true },
  },
);

export const { action: deleteMultiWidget, hook: deleteMultiWidgetHook } = createAsyncUseCase<any, any>(
  widgetsContext,
  'deleteMultiWidget',
  {
    readableName: 'Xóa nhiều widgets',
    messageSettings: { initial: true, success: true },
  },
);

export const { action: cloneWidget, hook: cloneWidgetHook } = createAsyncUseCase<
  {
    pageId: string;
    widget: Widget;
    widgets: string[];
  },
  any,
  PageWidgetsState
>(widgetsContext, 'cloneWidget', {
  readableName: 'Clone widget',
  messageSettings: { initial: true, success: true },
});

export const { action: createWidgetAction, hook: createWidgetHook } = createAsyncUseCase<
  any,
  Widget,
  any
>(widgetsContext, 'createWidgetAction', {
  readableName: 'Tạo widget mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateWidgetAction, hook: updateWidgetHook } = createAsyncUseCase<
  Widget,
  { response: Widget; error?: Error },
  PageWidgetsState
>(widgetsContext, 'updateWidgetAction', {
  readableName: 'Lưu thay đổi widget',
  messageSettings: { initial: true, success: true },
});
