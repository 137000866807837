const theme = {
  colors: {
    text_link: '#0B74E5',
    base: '#333',
    white: '#ffffff',
    primary: '#1ba8ff',
    danger: '#FF424E',
    muted: '#787878',
    gray: '#e0e0e0',
    warning: '#fd820a',
    success: '#26BC4E',
    backgroundGray: '#F2F2F2',
    black86: '#242424',
    silver: '#bdbdbd',
    backgroundOverlay: 'rgba(0, 0, 0, 0.5)',
    brownGrey: '#999999',
    grayLight: '#fafafa',
    disabled: '#c7c7c7',
    veryLightPink: '#eeeeee',
    baseGray: '#f2f2f2',
    green: '#009900',
    yellow: '#fdd835',
    green_50: '#2DC26D',
    wheat: '#fcf8e4',
    lineGray: '#d8d8d8',
    warningText: '#f48f2d',
    warningBg: '#fff5ec',
    lightRed: '#ff424e',
    grayNote: '#676767',
    black: '#000000',
    blue: '#0d5cb6',
    greenBold: '#0d5302',
    gray_10: '#F5F5FA',
    gray_20: '#EBEBF0',
    gray_30: '#DDDDE3',
    gray_40: '#C4C4CF',
    gray_60: '#808089',
    gray_70: '#64646D',
    gray_90: '#38383D',
    gray_100: '#27272A',
    blue_60: '#1A94FF',
    green_60: '#00AB56',
    orange_50: '#FF9F41',
    blue_80: '#0D5BB5',
    red_10: '#FFF0F1',
    red_60: '#FF424E',
    red_70: '#D93843',
    blue_10: '#F0F8FF',
    blue_70: '#0B74E5',
    asaBg_L0: '#1B1928',
    asaBg_L2: '#36324D',
    purple_10: '#F2F0FE',
    purple_20: '#ECE9FD',
    purple_60: '#856EFA',
    purple_70: '#6F55ED',
    purple_90: '#402DA1',
  },
  devices: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
  },
};

export default theme;

export type AppTheme = typeof theme;
