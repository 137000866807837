import React, { FC } from 'react';
import { DraggableProps } from '..';
import { CategoryBrandWidget } from './types';

export interface BrandDraggableProps extends DraggableProps<CategoryBrandWidget> {}

const BannerDraggable: FC<BrandDraggableProps> = ({ widget: { name } }) => {
  return <div>Draggable Widget: {name}</div>;
};

export default BannerDraggable;
