import React, { FC } from 'react';
import { DraggableProps } from '..';
import { RedeemWidget } from './types';

export interface RedeemDraggableProps extends DraggableProps<RedeemWidget> {}

const RedeemDraggable: FC<RedeemDraggableProps> = ({ widget: { id } }) => {
  return <div>Draggable Draggable Widget: {id}</div>;
};
export default RedeemDraggable;
