import Config from '@/config';
import { Category } from '@/infras/category/typing';
import { PageFormData } from '@/infras/page/typing';
import { PageType } from '@/infras/widget/constants';
import { parse } from 'querystring';
import { TikiCategory } from '@/infras/category-widgets/typing';
import React from 'react';
import { loadImage } from '@/components/ImagesUpload/util';
import { TikiCategoryType } from '@/pages/categories/constants';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const urlReg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const emailReg = /\S+@\S+\.\S+/;
export const isUrl = (path: string): boolean => urlReg.test(path);

export function getListMapData(data: any[], key: string = 'id') {
  return data.reduce(
    (
      res: {
        mapData: {};
        list: (string | number)[];
      },
      item,
    ) => {
      const id = item[key];
      res.mapData[id] = item;
      res.list.push(id);
      return res;
    },
    {
      mapData: {},
      list: [],
    },
  );
}

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/* used for test debugging */
export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

export const getPageType = (payload: PageFormData) => {
  const { pageType, campaignId, categoryId } = payload;
  const isCampaignPage = pageType === PageType.CAMPAIGN && campaignId;
  const isCategoryPage = pageType === PageType.CATEGORY && categoryId;

  return { isCampaignPage, isCategoryPage };
};

export const getTikiCategoryPageUrl = (
  category: TikiCategory | Category | undefined,
  pageId?: string,
  preview?: boolean,
) => {
  if (!category) return '';
  const { tikiId, slug, type } = category;

  if (type === TikiCategoryType.CATEGORY) {
    return `${Config.TikiFrontend}/${slug}/c${tikiId}${
      preview ? `?preview=1&page_id=${pageId}` : ''
    }`;
  }
  return `${Config.TikiFrontend}/${slug}${preview ? `?preview=1&page_id=${pageId}` : ''}`;
};

export const isMultipleSelect = (
  e: MouseEvent | KeyboardEvent | React.MouseEvent<any> | React.KeyboardEvent<any>,
) => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
  return isUsingWindows ? e.ctrlKey : e.metaKey;
};

export const updateImagesMeta = (originalImages: any) => {
  return Promise.all(
    originalImages.map(async (image: any) => {
      const newImage = { ...image };
      if (!newImage.height || !newImage.width || !newImage.dominantColor) {
        try {
          const renderedImage = await loadImage(newImage.url as string);
          const {
            naturalHeight: height = undefined,
            naturalWidth: width = undefined,
          } = renderedImage;
          newImage.height = height;
          newImage.width = width;

          newImage.dominantColor = '#EFEFEF';
        } catch (err) {
          console.error('loading image err', err);
        }
      }
      return newImage;
    }),
  );
};
