import {
  WidgetCollectionPagesRequest,
  WidgetCollectionPagesRequestData,
} from '@/infras/widget-collection-page/typing';
import { ListProps } from '@/typings/list';
import { prefixWidgetCollectionPageUrl } from './util';

export const getOGImg = (imgPath: any) => {
  return (
    imgPath || 'https://salt.tikicdn.com/ts/brickv2og/55/02/1a/577228e1dd2baffa22fb634e0fa0afb7.png'
  );
};
export const mapDataWidgetCollectionPagesReq = (
  request: WidgetCollectionPagesRequestData,
): WidgetCollectionPagesRequest => {
  if (!request) return { limit: 20, page: 1 };
  const filter: any = request?.filter
    ? { [request.filter.key]: request.filter.value.replace(prefixWidgetCollectionPageUrl, '') }
    : {};
  if (typeof request.page_type !== 'undefined') {
    filter.page_type = request.page_type;
  }
  return {
    limit: request.limit,
    page: request.page,
    ...filter,
  };
};

export const mapApiDataToWidgetCollectionPagesRes = (data: any): ListProps<any> => {
  return {
    data: data.items,
    total: data.meta.totalItems,
  };
};
