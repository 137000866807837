import Config from '@/config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Initialize Sentry
if (Config.SentryDSN) {
  Sentry.init({
    dsn: Config.SentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
