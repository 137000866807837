import { Form } from 'antd';
import styled from 'styled-components';

const TightFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0;
  }
`;

export default TightFormItem;
