export enum PersonalizeType {
  COUPONS = 'coupon',
  PRODUCTS = 'product',
}

export enum PersonalizeLayout {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
}

export const BaseUrl: Record<PersonalizeType, string> = {
  [PersonalizeType.PRODUCTS]: 'https://tiki.vn/api/personalish/v1/blocks/products',
  [PersonalizeType.COUPONS]: 'https://tiki.vn/api/personalish/v1/blocks/coupons',
};
