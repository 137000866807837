import styled from 'styled-components';
import Box from '@/components/Box';

export const StyledBox = styled(Box)`
  .filter-key {
    .ant-select-selector {
      text-align: center;
      background-color: #fafafa;
      border-right: 0;
    }
  }
`
