import { message } from 'antd';
import 'antd/dist/antd.css';
import React, { FunctionComponent } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ThemeProvider } from 'styled-components';
import defaultThemes from './themes/defaultThemes';
import createIndicatorMessagesMiddleware from './middlewares/indicatorMessages';
import './utils/sentry';

message.config({
  top: 25,
  duration: 2,
});

const indicatorMessagesMiddleware = createIndicatorMessagesMiddleware();
const {
  action: indicatorMessagesAction,
  reducer: indicatorMessagesReducer,
} = indicatorMessagesMiddleware;

export const dva = {
  config: {
    namespacePrefixWarning: false,
    onError(err: ErrorEvent) {
      err.preventDefault();
    },
    onAction: [indicatorMessagesAction],
    extraReducers: { ...indicatorMessagesReducer },
  },
};
const theme = defaultThemes;
const App: FunctionComponent = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
export function rootContainer(container: any) {
  return React.createElement(App, null, container);
}
