import { Categories, CATEGORY_CAMPAIGN } from '@/pages/campaigns/components/CampaignForm/constant';

export enum PREFIX_SLUG {
  COLLECTION = 'bo-suu-tap',
  NGON_THEME = 'di-cho-online/chu-de',
}

export enum CAMPAIGN_PREFIX_OPTIONS {
  PROMO = 'khuyen-mai',
  INFORMATION = 'thong-tin',
}

export const CAMPAIGN_PROMO_TYPES = [
  Categories[CATEGORY_CAMPAIGN.BRAND],
  Categories[CATEGORY_CAMPAIGN.UMBRELLA],
  Categories[CATEGORY_CAMPAIGN.SEASONAL],
  Categories[CATEGORY_CAMPAIGN.TIKINGON],
  Categories[CATEGORY_CAMPAIGN.CATEGORY],
  Categories[CATEGORY_CAMPAIGN.TIKI_PROGRAM],
  Categories[CATEGORY_CAMPAIGN.BANK_PARTNERSHIP],
];

export const CAMPAIGN_INFO_TYPES = [Categories[CATEGORY_CAMPAIGN.INFORMATION]];
export const CAMPAIGN_TIKINGON_TYPES = [Categories[CATEGORY_CAMPAIGN.TIKINGON]];
