import { CategoriesContext } from '@/infras/category/constants';
import {
  CategoriesRequestData,
  CategoriesResponse,
  CategoriesState,
  Category,
  CategoryFormData,
  CategoryRequest,
} from '@/infras/category/typing';
import { createAsyncUseCase } from '@/utils/dva-model-creator/async';

export const { action: getCategoriesAction, hook: getCategoriesHook } = createAsyncUseCase<
  CategoriesRequestData,
  CategoriesResponse,
  CategoriesState
>(CategoriesContext, 'getCategories');

export const { action: getCategoryAction, hook: getCategoryHook } = createAsyncUseCase<
  CategoryRequest,
  { request: CategoryRequest; response: Category },
  CategoriesState
>(CategoriesContext, 'getCategory');

export const { action: createCategoryAction, hook: createCategoryHook } = createAsyncUseCase<
  CategoryFormData,
  Category,
  CategoriesState
>(CategoriesContext, 'createCategoryAction', {
  readableName: 'Tạo category mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateCategoryAction, hook: updateCategoryHook } = createAsyncUseCase<
  Category,
  Category,
  CategoriesState
>(CategoriesContext, 'updateCategoryAction', {
  readableName: 'Lưu thay đổi category',
  messageSettings: { initial: true, success: true },
});
