import { WidgetType } from '@/infras/widget/constants';
import BannerSettings from './BrandSettings';
import BrandView from './BrandView';
import BannerDraggable from './BrandDraggable';
import { CategoryBrandSettings as ICategoryBrandSettings, IBrandItemSettings } from './types';

const widget = {
  type: WidgetType.CATEGORY_BRAND,
  View: BrandView,
  Settings: BannerSettings,
  Draggable: BannerDraggable,
  default: {
    type: WidgetType.CATEGORY_BRAND,
    payload: {
      title: '',
      placement: 'under_sub_category',
      layout: 'flexbox',
      showMax: 8,
      items: [] as IBrandItemSettings[],
      backgroundColor: 'transparent',
    } as ICategoryBrandSettings,
  },
};

export default widget;
