import React, { FC } from 'react';
import { DraggableProps } from '..';
import { TextWidget } from './types';

export interface TextDraggableProps extends DraggableProps<TextWidget> {}

const TextDraggable: FC<TextDraggableProps> = ({ widget: { id } }) => {
  return <div>Draggable Text Widget: {id}</div>;
};

export default TextDraggable;
