import { ComponentPropsWithRef, FC } from 'react';
import styled, { StyledComponentBase } from 'styled-components';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  position,
  PositionProps,
  border,
  BorderProps,
  background,
  BackgroundProps,
  shadow,
  ShadowProps,
  compose,
} from 'styled-system';

export type BoxProps = ComponentPropsWithRef<StyledComponentBase<'div', any>> &
  ColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  PositionProps &
  BorderProps &
  BackgroundProps &
  ShadowProps;

const Box: FC<BoxProps> = styled('div')(
  compose(space, layout, color, flexbox, grid, position, border, background, shadow),
);

export default Box;
