import { Category, CategoryFieldsProps } from '@/infras/category/typing';
import { updateCategoryHook } from '@/infras/category/useCase';
import CategoryForm, { FormMode } from '@/pages/categories/components/CategoryForm';
import { Form } from 'antd';
import React, { FC } from 'react';

interface UpdateFormProps {
  category: Category;
  modalVisible: boolean;
  handleVisibleModal: (visible: boolean) => void;
}

const CreateForm: FC<UpdateFormProps> = ({ category, modalVisible, handleVisibleModal }) => {
  const [form] = Form.useForm();

  const {
    fetch,
    state: { status },
  } = updateCategoryHook();

  const onSubmit = (values: CategoryFieldsProps) => {
    return fetch({
      ...category,
      footerContent: values.footerContent || '',
      relevantLinks: values.relevantLinks,
      metaDescription: values.metaDescription,
      metaTitle: values.metaTitle,
      h1: values.h1,
    });
  };

  return (
    <CategoryForm
      form={form}
      category={{
        ...category,
        relevantLinks: category?.relevantLinks ? JSON.parse(category.relevantLinks as string) : {},
      }}
      visible={modalVisible}
      handleVisibleModal={handleVisibleModal}
      loading={status === 'requesting'}
      mode={FormMode.EDIT}
      onSubmit={onSubmit}
      title="Sửa Category"
    />
  );
};

export default CreateForm;
