export const formatMoney = (nStr: string) => {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1.$2`);
  }
  return x1 + x2;
};

export const addParamSrc = (url: string, id: string): string => {
  const hasParams = url.indexOf('?') !== -1;
  const regx = new RegExp('([?&])src=.*?(&|$)', 'i');
  const hasHttp = url.indexOf('http') !== -1;
  const hasDeepLink = url.indexOf('tikivn://') !== -1;

  if (hasDeepLink) {
    return url;
  }

  if (hasParams) {
    if (url.match(regx)) {
      return `${hasHttp ? '' : '/'}${url.replace(regx, `$1src=lp-${id}$2`)}`;
    }
    return `${hasHttp ? '' : '/'}${url}&src=lp-${id}`;
  }
  return `${hasHttp ? '' : '/'}${url}?src=lp-${id}`;
};

export const formatImageFileName = (name = '') => {
  const dotIndex = name.lastIndexOf('.');
  const ext = name.slice(dotIndex + 1);
  return name.slice(0, dotIndex + 1) + ext.toLowerCase();
};
