import { actionCreatorFactory, ActionCreator } from './actionCreatorFactory';
import { AsyncActionMeta } from './async';

export const createAction = <RES = any>(
  namespace: string,
  action: string,
  meta?: AsyncActionMeta,
) => {
  return actionCreatorFactory(namespace)<RES>(action, meta);
};

export const createAsyncAction = <RES = any, SUC = any, ERR = any>(
  namespace: string,
  actionName: string,
  meta: AsyncActionMeta,
): ActionCreator<RES | SUC | ERR | any>[] => {
  const action = createAction<RES>(namespace, `${actionName}_INIT`, meta);
  const actionSuccess = createAction<SUC>(namespace, `${actionName}_SUCCESS`, meta);
  const actionFailure = createAction<ERR>(namespace, `${actionName}_FAILURE`, meta);

  return [action, actionSuccess, actionFailure];
};
