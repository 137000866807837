/* eslint-disable react-hooks/rules-of-hooks */
import { ConnectState } from '@/models/connect';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'umi';
import { ActionCreator, VoidToOptional } from '.';
import { createAction, createAsyncAction } from './actions';

interface ActionType<REQ = any, RES = any> {
  execute: ActionCreator<REQ>;
  success: ActionCreator<RES>;
  fail: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UseCaseHook<REQ, RES, STATE> {
  fetch: (req?: REQ) => Promise<RES>;
  loading: boolean;
  state: STATE;
}
interface AsyncUseCase<REQ, RES, STATE> {
  action: ActionType<REQ, RES>;
  hook: () => UseCaseHook<REQ, RES, STATE>;
}

export interface AsyncActionMeta {
  readableName?: string;
  messageSettings?: Partial<messageSettings>;
}

interface messageSettings {
  initial: boolean;
  success: boolean;
}

const createUseCase = <REQ = any>(namespace: string, actionName: string, stateName?: string) => {
  const actionExecute = createAction<REQ>(namespace, actionName);
  const selector = (state: ConnectState) => state[namespace][stateName] || state[namespace];
  const dispatch = async (req: VoidToOptional<REQ>, dispatcher: any) => {
    await dispatcher(actionExecute(req));
  };
  const hook = <STATE = {}>() => {
    const dispatcher = useDispatch<REQ>();
    const modelState: STATE = useSelector(selector) || {};
    const loading = useSelector<ConnectState>((state) => state.loading.effects[actionExecute.type]);
    const fetch = useCallback((req?: any) => dispatch(req, dispatcher), [dispatcher]);
    return {
      fetch,
      loading,
      state: modelState,
    };
  };
  return {
    action: actionExecute,
    hook,
  };
};

const createAsyncUseCase = <REQ, RES, STATE = any>(
  namespace: string,
  actionName: string,
  meta: AsyncActionMeta = {},
): AsyncUseCase<REQ, RES, STATE> => {
  /**
   * Add metadata for async action
   * if messageSettings.initial = true, will show message (request = loading) with format: Đang ${readableName}...
   * if messageSettings.success = true, will show message (request = resolved) with format: Đã ${readableName} thành công
   * error case: will be handled in utils/request.ts
   */
  const { readableName, messageSettings } = meta;
  const finalmessageSettings: messageSettings = {
    initial: false,
    success: false,
    ...messageSettings,
  };
  if (readableName) {
    meta.messageSettings = finalmessageSettings;
  }

  const [actionExecute, actionSuccess, actionFail] = createAsyncAction<REQ, RES>(
    namespace,
    actionName,
    meta,
  );
  const selector = (state: ConnectState) => state[namespace];
  const dispatch = (req: REQ, dispatcher: any) => {
    return dispatcher(actionExecute(req));
  };
  const hook = (): UseCaseHook<REQ, RES, STATE> => {
    const dispatcher = useDispatch<RES>();
    const modelState: STATE = useSelector(selector) || {};
    const loading = useSelector<ConnectState, boolean>(
      (state) => state.loading.effects[actionExecute.type] || false,
    );
    const fetch = useCallback(
      (req?) => {
        return dispatch(req, dispatcher);
      },
      [dispatcher],
    );
    return {
      fetch,
      loading,
      state: modelState,
    };
  };
  return {
    action: {
      execute: actionExecute,
      success: actionSuccess,
      fail: actionFail,
    },
    hook,
  };
};

export { createUseCase, createAsyncUseCase };
