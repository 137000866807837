import {
  createNgonQuickLinkAction,
  deleteNgonQuickLinkAction,
  getNgonQuickLinkAction,
  getNgonQuickLinksAction,
  orderNgonQuickLinkAction,
  resetNgonQuickLinkRequestAction,
  updateNgonQuickLinkAction,
} from '@/infras/ngon-quick-link/useCase';
import { FILTER_KEY } from '@/pages/ngon-themes/components/ThemeFilter';
import { getCollection } from '@/services/collections';
import ngonQuickLinksServices from '@/services/ngonQuickLink';
import * as storeCollectionService from '@/services/store-collections';
import { getListMapData } from '@/utils';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { message } from 'antd';
import { ngonQuickLinkContext, ThemeCollectionTypeName } from '../infras/ngon-quick-link/constants';
import {
  NgonQuickLink,
  NgonQuickLinkFormData,
  NgonQuickLinkRequest,
  NgonQuickLinksRequestData,
  NgonQuickLinksResponse,
  NgonQuickLinkState,
} from '../infras/ngon-quick-link/typing';

const initState: NgonQuickLinkState = {
  ngonQuickLinkMap: {},
  ngonQuickLinkOrderList: [],
  ngonQuickLinkList: { data: [], total: 0 },
  request: {
    page: 1,
    limit: 10,
    filter: {
      key: FILTER_KEY.NAME,
      value: '',
    },
  },
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, ngonQuickLinkContext);

builder
  .immer(
    getNgonQuickLinksAction.success,
    (state: NgonQuickLinkState, { request, response }: NgonQuickLinksResponse) => {
      const { mapData: ngonQuickLinkMap, list: ngonQuickLinkList } = getListMapData(response.data);
      state.ngonQuickLinkMap = { ...state.ngonQuickLinkMap, ...ngonQuickLinkMap };
      state.ngonQuickLinkList = { data: ngonQuickLinkList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getNgonQuickLinksAction.execute, function* (
    request: NgonQuickLinksRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(ngonQuickLinksServices.getNgonQuickLinks, request);
      yield put(getNgonQuickLinksAction.success({ request, response }));
    } catch (error) {
      yield put(getNgonQuickLinksAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createNgonQuickLinkAction.execute, function* (
    payload: NgonQuickLinkFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(ngonQuickLinksServices.createNgonQuickLink, payload as any);
      yield put(createNgonQuickLinkAction.success(response));
      yield put.resolve(getNgonQuickLinksAction.execute({ path: payload.path }));
      return response;
    } catch (err) {
      yield put(createNgonQuickLinkAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createNgonQuickLinkAction.execute, (state: NgonQuickLinkState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createNgonQuickLinkAction.success, (state: NgonQuickLinkState) => {
    state.status = 'resolved';
    message.success('Chủ đề đã được tạo thành công');
    return state;
  })
  .immer(createNgonQuickLinkAction.fail, (state: NgonQuickLinkState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder.immer(resetNgonQuickLinkRequestAction, (state: NgonQuickLinkState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  .immer(
    updateNgonQuickLinkAction.success,
    (state: NgonQuickLinkState, ngonQuickLink: NgonQuickLink) => {
      const { id } = ngonQuickLink;
      state.ngonQuickLinkMap = { ...state.ngonQuickLinkMap, [id]: ngonQuickLink };

      return state;
    },
  )
  .takeLatest<any>(updateNgonQuickLinkAction.execute, function* (
    payload: NgonQuickLinkFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(ngonQuickLinksServices.updateNgonQuickLink, payload as any);
      yield put(updateNgonQuickLinkAction.success(resp));
      return resp;
    } catch (error) {
      yield put(updateNgonQuickLinkAction.fail(error));
      return error;
    }
  });

builder
  .immer(
    getNgonQuickLinkAction.success,
    (
      state: NgonQuickLinkState,
      { request, response }: { request: NgonQuickLinkRequest; response: NgonQuickLink },
    ) => {
      state.ngonQuickLinkMap[request.ngonQuickLinkId] = response;

      return state;
    },
  )
  .takeLatest<any>(getNgonQuickLinkAction.execute, function* (
    request: NgonQuickLinkRequest,
    { call, put, all }: EffectsCommandMap,
  ) {
    try {
      const ngonQuickLink = yield call(ngonQuickLinksServices.getNgonQuickLink, request);
      let collectionsData = [];
      try {
        collectionsData = yield all(
          ngonQuickLink?.collections?.reduce((res: any, collection: any) => {
            if (!collection) return res;
            try {
              if (collection.collectionType === ThemeCollectionTypeName.Store) {
                res = [
                  ...res,
                  {
                    ...call(storeCollectionService.getStoreCollection, {
                      storeCollectionId: collection.collectionId,
                    } as any),
                  },
                ];
              }
              if (collection.collectionType === ThemeCollectionTypeName.Product) {
                res = [
                  ...res,
                  {
                    ...call(getCollection, {
                      collectionId: collection.collectionId,
                    } as any),
                  },
                ];
              }
              return res;
            } catch (error) {
              return res;
            }
          }, []),
        );
      } catch (error) {}

      const response = {
        ...ngonQuickLink,
        collections: collectionsData.map((data: any, index: any) => {
          if (ngonQuickLink?.collections[index].collectionType === ThemeCollectionTypeName.Store) {
            return {
              ...data,
              ...ngonQuickLink?.collections[index],
              fileCodes: {
                file: { name: `${data?.name} store report.xlsx` },
                codes: data.storeIds.split(','),
              },
              codes: data.storeIds.split(','),
            };
          }

          return {
            ...data.meta_data,
            ...data.collection_meta_data,
            ...ngonQuickLink?.collections[index],
            fileCodes: {
              file: { name: `${data?.name} store report.xlsx` },
              codes: data.meta_data.skus,
            },
            codes: data.meta_data.skus,
            slug: data.collection_meta_data?.url || data.meta_data?.url,
          };
        }),
      };
      yield put(getNgonQuickLinkAction.success({ request, response }));
      return response;
    } catch (err) {
      console.log(err, 'err');
      yield put(getNgonQuickLinkAction.fail(err));
    }
  });

builder
  .takeLatest<any>(deleteNgonQuickLinkAction.execute, function* (
    payload,
    { call, put, select }: EffectsCommandMap,
  ) {
    try {
      const { id } = payload;
      yield call(ngonQuickLinksServices.deleteNgonQuickLink, id);
      // refetch after delete
      const state = yield select();
      const path = state?.ngonQuickLink?.request?.path;
      yield put.resolve(getNgonQuickLinksAction.execute({ path }));

      yield put(deleteNgonQuickLinkAction.success({ id }));
    } catch (err) {
      yield put(deleteNgonQuickLinkAction.fail(err));
    }
  })
  .immer(deleteNgonQuickLinkAction.success, (state, { id }) => {
    state.ngonQuickLinkList.data = state.ngonQuickLinkList.data.filter((x) => x != id);
    delete state.ngonQuickLinkMap[id];

    return state;
  });

builder.takeLatest<any>(orderNgonQuickLinkAction.execute, function* (
  payload,
  { call, put }: EffectsCommandMap,
) {
  try {
    yield call(ngonQuickLinksServices.orderNgonQuickLink, payload.data);

    yield put(orderNgonQuickLinkAction.success());
    yield put.resolve(getNgonQuickLinksAction.execute(payload?.request || {}));
  } catch (err) {
    yield put(orderNgonQuickLinkAction.fail(err));
  }
});

export default builder.build();
