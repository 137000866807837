import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { collectionPagesContext } from './constants';
import {
  WidgetCollectionPage,
  WidgetCollectionPageFormData,
  WidgetCollectionPageRequest,
  WidgetCollectionPagesRequestData,
  WidgetCollectionPagesResponse,
  WidgetCollectionPageState,
} from './typing';

export const {
  action: getWidgetCollectionPagesAction,
  hook: getWidgetCollectionPagesHook,
} = createAsyncUseCase<
  WidgetCollectionPagesRequestData,
  WidgetCollectionPagesResponse,
  WidgetCollectionPageState
>(collectionPagesContext, 'getWidgetCollectionPages');

export const {
  action: getWidgetCollectionPageAction,
  hook: getWidgetCollectionPageHook,
} = createAsyncUseCase<
  WidgetCollectionPageRequest,
  { request: WidgetCollectionPageRequest; response: WidgetCollectionPage },
  WidgetCollectionPageState
>(collectionPagesContext, 'getWidgetCollectionPage');

export const {
  action: createWidgetCollectionPageAction,
  hook: createWidgetCollectionPageHook,
} = createAsyncUseCase<
  WidgetCollectionPageFormData,
  WidgetCollectionPage,
  WidgetCollectionPageState
>(collectionPagesContext, 'createWidgetCollectionPageAction', {
  readableName: 'Tạo page Collection mới',
  messageSettings: { initial: true, success: true },
});

export const {
  action: updateWidgetCollectionPageAction,
  hook: updateWidgetCollectionPageHook,
} = createAsyncUseCase<
  WidgetCollectionPageFormData,
  WidgetCollectionPage,
  WidgetCollectionPageState
>(collectionPagesContext, 'updateWidgetCollectionPageAction', {
  readableName: 'Lưu thay đổi page Collection',
  messageSettings: { initial: true, success: true },
});

export const {
  action: changeStatusWidgetCollectionPageAction,
  hook: changeStatusWidgetCollectionPageHook,
} = createAsyncUseCase<
  WidgetCollectionPageFormData,
  { id: string; status: 0 | 1 },
  WidgetCollectionPageState
>(collectionPagesContext, 'changeStatusWidgetCollectionPageAction', {
  readableName: 'Lưu thay đổi trạng thái widget collection',
  messageSettings: { initial: true, success: true },
});

export const {
  action: changeIndexWidgetCollectionPageAction,
  hook: changeIndexWidgetCollectionPageHook,
} = createAsyncUseCase<
  WidgetCollectionPageFormData,
  WidgetCollectionPage,
  WidgetCollectionPageState
>(collectionPagesContext, 'changeIndexWidgetCollectionPageAction', {
  readableName: 'Lưu thay đổi vị trí widget collection',
  messageSettings: { initial: true, success: true },
});

export const {
  action: deleteWidgetCollectionPageAction,
  hook: deleteWidgetCollectionPageHook,
} = createAsyncUseCase<any, any>(collectionPagesContext, 'deleteWidgetCollectionPageAction', {
  readableName: 'Xóa widget collection',
  messageSettings: { initial: true, success: true },
});

export const {
  action: resetWidgetCollectionPageRequestAction,
  hook: resetWidgetCollectionPageRequestHook,
} = createUseCase(collectionPagesContext, 'resetRequest');
