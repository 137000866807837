/* eslint-disable @typescript-eslint/naming-convention */
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Input, Select } from 'antd';
import Box from '@/components/Box';
import { debounce } from 'lodash';

interface UserProps {
  filter?: { key: string; value: string };
  onChange?: (value: any) => void;
}
export enum FILTER_KEY {
  EMAIL = 'email',
}
const UserFilter: FunctionComponent<UserProps> = ({
  onChange = () => {},
  filter = {
    key: '',
    value: '',
  },
}) => {
  const { value } = filter;
  const [searchData, setSearchData] = useState(filter);

  const handleSearch = debounce(
    useCallback(
      (newFilter: unknown) => {
        onChange(newFilter);
      },
      [onChange],
    ),
    500,
  );

  const handleKeyChange = useCallback(
    (newKey: string) => {
      setSearchData({ key: newKey, value });
      if (value) {
        handleSearch({ key: newKey, value });
      }
    },
    [handleSearch, value],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setSearchData({ key: searchData.key, value: newValue });
      handleSearch({ key: searchData.key, value: newValue });
    },
    [handleSearch, searchData.key],
  );

  return (
    <Box p="20px" bg="white" mb="10px">
      <Input
        name="search"
        addonBefore={
          <Select value={searchData.key} style={{ width: 220 }} onChange={handleKeyChange}>
            <Select.Option value={FILTER_KEY.EMAIL}>Email</Select.Option>
          </Select>
        }
        value={searchData.value}
        onChange={handleInputChange}
      />
    </Box>
  );
};
export default UserFilter;
