import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { ngonDynamicTitleContext } from './constants';
import {
  NgonDynamicTitle,
  NgonDynamicTitleFormData,
  NgonDynamicTitleRequest,
  NgonDynamicTitlesRequestData,
  NgonDynamicTitlesResponse,
  NgonDynamicTitleState,
} from './typing';

export const {
  action: getNgonDynamicTitlesAction,
  hook: getNgonDynamicTitlesHook,
} = createAsyncUseCase<
  NgonDynamicTitlesRequestData,
  NgonDynamicTitlesResponse,
  NgonDynamicTitleState
>(ngonDynamicTitleContext, 'getNgonDynamicTitles');

export const {
  action: getNgonDynamicTitleAction,
  hook: getNgonDynamicTitleHook,
} = createAsyncUseCase<
  NgonDynamicTitleRequest,
  { request: NgonDynamicTitleRequest; response: NgonDynamicTitle },
  NgonDynamicTitleState
>(ngonDynamicTitleContext, 'getNgonDynamicTitle');

export const {
  action: createNgonDynamicTitleAction,
  hook: createNgonDynamicTitleHook,
} = createAsyncUseCase<NgonDynamicTitleFormData, NgonDynamicTitle, NgonDynamicTitleState>(
  ngonDynamicTitleContext,
  'createNgonDynamicTitleAction',
  {
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: updateNgonDynamicTitleAction,
  hook: updateNgonDynamicTitleHook,
} = createAsyncUseCase<NgonDynamicTitle, NgonDynamicTitle, NgonDynamicTitleState>(
  ngonDynamicTitleContext,
  'updateNgonDynamicTitleAction',
  {
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: deleteNgonDynamicTitleAction,
  hook: deleteNgonDynamicTitleHook,
} = createAsyncUseCase<{ id: string | number }, { id: string | number }, NgonDynamicTitleState>(
  ngonDynamicTitleContext,
  'deleteNgonDynamicTitleAction',
  {
    readableName: 'Xóa theme',
    messageSettings: { initial: true, success: true },
  },
);

export const {
  action: resetNgonDynamicTitleRequestAction,
  hook: resetNgonDynamicTitleRequestHook,
} = createUseCase<any>(ngonDynamicTitleContext, 'resetRequest');
