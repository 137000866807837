import { NgonThemeFormData, UpdateNgonThemeRequest } from '@/infras/ngon-theme/typing';
import { ListProps } from '@/typings/list';
import { isString } from 'lodash';

export const mapCreateDataNgonThemesReq = (request: NgonThemeFormData) => {
  return {
    ...request,
    collections: (request?.collections || []).map(({ id, ...data }) => data),
  };
};

export const mapDataToUpdateNgonThemeReq = (
  ngonTheme: NgonThemeFormData,
): UpdateNgonThemeRequest => {
  return {
    ...ngonTheme,
    metaImage: isString(ngonTheme.metaImage) ? ngonTheme.metaImage : ngonTheme.metaImage?.[0],
  };
};
export const mapApiDataToNgonThemesRes = (data: any): ListProps<any> => {
  return {
    data: data.items,
    total: data.meta.totalItems,
  };
};
