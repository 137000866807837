import {
  createNgonDynamicTitleAction,
  deleteNgonDynamicTitleAction,
  getNgonDynamicTitleAction,
  getNgonDynamicTitlesAction,
  resetNgonDynamicTitleRequestAction,
  updateNgonDynamicTitleAction,
} from '@/infras/ngon-dynamic-title/useCase';
import ngonDynamicTitlesServices from '@/services/ngonDynamicTitle';
import { getListMapData } from '@/utils';
import { DvaModelBuilder, EffectsCommandMap } from '@/utils/dva-model-creator';
import { message } from 'antd';
import { ngonDynamicTitleContext } from '../infras/ngon-dynamic-title/constants';
import {
  NgonDynamicTitle,
  NgonDynamicTitleFormData,
  NgonDynamicTitleRequest,
  NgonDynamicTitleState,
  NgonDynamicTitlesRequestData,
  NgonDynamicTitlesResponse,
} from '../infras/ngon-dynamic-title/typing';

const initState: NgonDynamicTitleState = {
  ngonDynamicTitleMap: {},
  ngonDynamicTitleOrderList: [],
  ngonDynamicTitleList: { data: [], total: 0 },
  request: {},
  status: 'initial',
};

const builder = new DvaModelBuilder(initState, ngonDynamicTitleContext);

builder
  .immer(
    getNgonDynamicTitlesAction.success,
    (state: NgonDynamicTitleState, { request, response }: NgonDynamicTitlesResponse) => {
      const { mapData: ngonDynamicTitleMap, list: ngonDynamicTitleList } = getListMapData(
        response.data,
      );
      state.ngonDynamicTitleMap = { ...state.ngonDynamicTitleMap, ...ngonDynamicTitleMap };
      state.ngonDynamicTitleList = { data: ngonDynamicTitleList, total: response.total };
      if (request) {
        state.request = { ...state.request, ...request };
      }
      return state;
    },
  )
  .takeLatest<any>(getNgonDynamicTitlesAction.execute, function* (
    request: NgonDynamicTitlesRequestData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(ngonDynamicTitlesServices.getNgonDynamicTitles, request);
      yield put(getNgonDynamicTitlesAction.success({ request, response }));
    } catch (error) {
      yield put(getNgonDynamicTitlesAction.fail(error));
    }
  });

builder
  .takeLatest<any>(createNgonDynamicTitleAction.execute, function* (
    payload: NgonDynamicTitleFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const response = yield call(ngonDynamicTitlesServices.createNgonDynamicTitle, payload as any);
      yield put(createNgonDynamicTitleAction.success(response));
      yield put.resolve(getNgonDynamicTitlesAction.execute());
      return response;
    } catch (err) {
      yield put(createNgonDynamicTitleAction.fail(err));
      return Promise.reject(err);
    }
  })
  .immer(createNgonDynamicTitleAction.execute, (state: NgonDynamicTitleState) => {
    state.status = 'requesting';
    state.error = null;
    return state;
  })
  .immer(createNgonDynamicTitleAction.success, (state: NgonDynamicTitleState) => {
    state.status = 'resolved';
    message.success('Chủ đề đã được tạo thành công');
    return state;
  })
  .immer(createNgonDynamicTitleAction.fail, (state: NgonDynamicTitleState, error: Error) => {
    state.status = 'rejected';
    state.error = error;
    return state;
  });

builder.immer(resetNgonDynamicTitleRequestAction, (state: NgonDynamicTitleState) => {
  state.status = 'initial';
  state.error = null;
  return state;
});

builder
  .immer(
    updateNgonDynamicTitleAction.success,
    (state: NgonDynamicTitleState, ngonDynamicTitle: NgonDynamicTitle) => {
      const { id } = ngonDynamicTitle;
      state.ngonDynamicTitleMap = { ...state.ngonDynamicTitleMap, [id]: ngonDynamicTitle };

      return state;
    },
  )
  .takeLatest<any>(updateNgonDynamicTitleAction.execute, function* (
    payload: NgonDynamicTitleFormData,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const resp = yield call(ngonDynamicTitlesServices.updateNgonDynamicTitle, payload as any);
      yield put(updateNgonDynamicTitleAction.success(resp));
      return resp;
    } catch (error) {
      yield put(updateNgonDynamicTitleAction.fail(error));
      return error;
    }
  });

builder
  .immer(
    getNgonDynamicTitleAction.success,
    (
      state: NgonDynamicTitleState,
      { request, response }: { request: NgonDynamicTitleRequest; response: NgonDynamicTitle },
    ) => {
      state.ngonDynamicTitleMap[request.ngonDynamicTitleId] = response;

      return state;
    },
  )
  .takeLatest<any>(getNgonDynamicTitleAction.execute, function* (
    request: NgonDynamicTitleRequest,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const ngonDynamicTitle = yield call(ngonDynamicTitlesServices.getNgonDynamicTitle, request);

      const response = {
        ...ngonDynamicTitle,
      };
      yield put(getNgonDynamicTitleAction.success({ request, response }));
      return response;
    } catch (err) {
      console.log(err, 'err');
      yield put(getNgonDynamicTitleAction.fail(err));
    }
  });

builder
  .takeLatest<any>(deleteNgonDynamicTitleAction.execute, function* (
    payload,
    { call, put }: EffectsCommandMap,
  ) {
    try {
      const { id } = payload;
      yield call(ngonDynamicTitlesServices.deleteNgonDynamicTitle, id);
      // refetch after delete

      yield put.resolve(getNgonDynamicTitlesAction.execute());

      yield put(deleteNgonDynamicTitleAction.success({ id }));
    } catch (err) {
      yield put(deleteNgonDynamicTitleAction.fail(err));
    }
  })
  .immer(deleteNgonDynamicTitleAction.success, (state, { id }) => {
    state.ngonDynamicTitleList.data = state.ngonDynamicTitleList.data.filter((x) => x != id);
    delete state.ngonDynamicTitleMap[id];

    return state;
  });

export default builder.build();
