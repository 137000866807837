import React, { FC } from 'react';
import { DraggableProps } from '..';
import { CmsRichTextWidget } from './types';

export interface RichTextDraggableProps extends DraggableProps<CmsRichTextWidget> {}

const RichTextDraggable: FC<RichTextDraggableProps> = ({ widget: { id } }) => {
  return <div>Draggable Cms RichText Widget: {id}</div>;
};

export default RichTextDraggable;
