/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import FilterBar from '@/components/FilterBar';

interface IThemeFilter {
  filter?: { key: string; value: string };
  onChange?: (value: any) => void;
}
export enum FILTER_KEY {
  NAME = 'name',
}

const filterMap = {
  [FILTER_KEY.NAME]: {
    key: FILTER_KEY.NAME,
    title: 'Tên chủ đề',
  },
};

const ThemeFilter = ({
  onChange = () => {},
  filter = {
    key: '',
    value: '',
  },
}: IThemeFilter) => {
  return <FilterBar currentFilter={filter} filterMap={filterMap} onChange={onChange} />;
};

export default ThemeFilter;
