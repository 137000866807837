import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from "react";
import {ViewProps} from "@/components/widgets";
import map from 'lodash/map';
import {CategoryCollectionWidget, ICollectionItemSettings, CollectionType} from "./types";
import {Image} from "antd";
import {CategoryCollectionView, GridWrapper, SeeMoreBtn, SliderWrapper, TitleWrapper} from "./CollectionView.styled";
import {MIN_ITEMS} from "@/components/widgets/category-collection/constants";
import { MdKeyboardArrowDown } from 'react-icons/md';

export interface CollectionViewProps extends ViewProps<CategoryCollectionWidget> {}

const CollectionView: FC<CollectionViewProps> = ({ widget: { payload } }) => {
  const [showSeeMore, setShowSeeMore] = useState(true);
  const { items, title, layout, showMax = MIN_ITEMS } = payload;
  const [shortItemList, setShortItemList] = useState<ICollectionItemSettings[]>(payload.items.filter((i, index) => index < showMax));

  useEffect(() => {
    setShortItemList(items.filter((i, index) => index < showMax));
    setShowSeeMore(items.length > showMax);
  }, [items, showMax])

  useLayoutEffect(() => {
    setShowSeeMore(true);
  }, [layout]);

  const renderItems = useCallback((cItems, needSubTitle = true) => {
    return map(
      cItems,
      ({
         clickUrl = '',
         url = '',
         title = '',
         uid = '',
         subTitle = ''
       }) => (
        <div className="banner" key={uid}>
          <Image
            src={url}
            style={{
              cursor: clickUrl ? 'pointer' : 'default',
              flexGrow: 1,
              borderRadius: '8px',
              width: '172px',
              height: '172px',
              objectFit: 'cover'
            }}
            alt={title}
          />
          <div className='title'>{title}</div>
          {needSubTitle && <div className='sub-title'>{subTitle}</div>}
        </div>
      )
    )
  }, []);

  return (
    <CategoryCollectionView>
      <TitleWrapper>
        {title}
      </TitleWrapper>
      {
        layout === CollectionType.GRID && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <GridWrapper>
              {showSeeMore ? renderItems(shortItemList) : renderItems(items)}
            </GridWrapper>
            {showSeeMore && <SeeMoreBtn onClick={() => setShowSeeMore(false)}>
              <span>Xem thêm</span>
              <MdKeyboardArrowDown size={24}/>
            </SeeMoreBtn>}
          </div>
        )
      }
      {
        layout === CollectionType.HORIZONTAL && (
          <SliderWrapper>
            {renderItems(shortItemList, false)}
          </SliderWrapper>
        )
      }
    </CategoryCollectionView>
  )
}
export default CollectionView;
