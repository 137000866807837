import React from "react";

const Copy = ({ ...props }) => {
  return (
    <img
      src="https://salt.tikicdn.com/ts/brickv2og/3f/0f/6b/aca0ff0a4974a9c4626f67786cf9cf02.png"
      width="20"
      height="20"
      {...props}
    />
  );
};

export default Copy;
