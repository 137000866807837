import React from "react";

const Delete = ({ ...props }) => {
  return (
    <img
      src="https://salt.tikicdn.com/ts/brickv2og/89/19/bb/d6822e44fd0e77df6bcda8a3797b2b7f.png"
      width="16"
      height="16"
      {...props}
    />
  );
};

export default Delete;
