import map from 'lodash/map';
import range from 'lodash/range';
import React from 'react';
import styled from 'styled-components';
import { ViewProps } from '..';
import FadeInImage from '../shared/FadeInImage.styled';
import { MIN_SHOW_MAX } from './constants';
import { CategoryBrandSettings, CategoryBrandWidget } from './types';

export interface BrandViewProps extends ViewProps<CategoryBrandWidget> {}

const TitleWrapper = styled.h3`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px;
  margin: 0;
`;

const Container = styled.div<Partial<CategoryBrandSettings>>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const ScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  align-items: center;
  flex-wrap: nowrap;
`;

const ItemWrapper = styled.div<Partial<CategoryBrandSettings>>`
  display: flex;
  flex: none;
  align-items: center;
`;
const ImageWrapper = styled.div`
  padding: 6px 8px;
  width: 97px;
  height: 65px;
  margin: 4px;
  border-radius: 8px;
  background-color: rgba(36, 36, 36, 0.05);
`;

const BrandView = ({ widget: { payload } }: BrandViewProps) => {
  const { items = [], showMax, backgroundColor, borderRadius, padding, title } = payload;
  const showingItems = items.slice(0, showMax);
  const nItemsInRow = 2 * MIN_SHOW_MAX;
  const nrows = Math.ceil(showingItems?.length / nItemsInRow);

  return (
    <Container padding={padding} backgroundColor={backgroundColor}>
      <TitleWrapper>{title}</TitleWrapper>
      <ScrollContainer>
        {range(nrows).map((row) => {
          return (
            <Row key={row}>
              {map(showingItems.slice(row * nItemsInRow, (row + 1) * nItemsInRow), (img) => {
                const {
                  clickUrl = '',
                  url = '',
                  title = '',
                  uid,
                  width,
                  height,
                  dominantColor,
                } = img;
                const ratio = !width || !height ? null : height / width;
                return (
                  <ItemWrapper key={uid} padding={padding}>
                    <ImageWrapper>
                      <FadeInImage
                        src={url}
                        alt={title}
                        imgStyle={{
                          borderRadius: borderRadius ? '8px' : 0,
                          cursor: clickUrl ? 'pointer' : 'unset',
                          maxWidth: 97,
                          maxHeight: 65,
                          objectFit: 'contain',
                        }}
                        ratio={ratio}
                        dominantColor={dominantColor}
                      />
                    </ImageWrapper>
                  </ItemWrapper>
                );
              })}
            </Row>
          );
        })}
      </ScrollContainer>
    </Container>
  );
};

export default BrandView;
