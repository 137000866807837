import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { rolesContext } from './constants';
import {
  Role,
  RoleFormData,
  RoleRequest,
  RolesRequestData,
  RolesResponse,
  RoleState,
} from './typing';

export const { action: getRolesAction, hook: getRolesHook } = createAsyncUseCase<
  RolesRequestData,
  RolesResponse,
  RoleState
>(rolesContext, 'getRoles');

export const { action: getRoleAction, hook: getRoleHook } = createAsyncUseCase<
  RoleRequest,
  { request: RoleRequest; response: Role },
  RoleState
>(rolesContext, 'getRole');

export const { action: createRoleAction, hook: createRoleHook } = createAsyncUseCase<
  RoleFormData,
  Role,
  RoleState
>(rolesContext, 'createRoleAction', {
  readableName: 'Tạo role mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updateRoleAction, hook: updateRoleHook } = createAsyncUseCase<
  Role,
  Role,
  RoleState
>(rolesContext, 'updateRoleAction', {
  readableName: 'Lưu thay đổi role',
  messageSettings: { initial: true, success: true },
});

export const { action: resetRoleRequestAction, hook: resetRoleRequestHook } = createUseCase<any>(
  rolesContext,
  'resetRequest',
);
