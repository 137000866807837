import Box from '@/components/Box';
import ImagesUpload from '@/components/ImagesUpload/ImagesUpload';
import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { findIndex, find } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { isURL } from '../../util';
import { MAX_ITEMS, MIN_ITEMS } from '../constants';
import { IBrandItemSettings } from '../types';

const UploadWrapper = styled.div`
  .ant-upload-drag {
    display: ${(prop: { disabled?: boolean }) => (prop.disabled ? 'none' : 'block')};
  }
`;

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const FileInfoSettingWrapper = styled.div`
  display: ${(prop: { disabled?: boolean }) => (prop.disabled ? 'none' : 'block')};
`;

const ButtonWrapper = styled.div`
  display: ${(prop: { disabled?: boolean }) => (prop.disabled ? 'none' : 'block')};
`;

interface Props {
  onClose?: () => void;
  form: FormInstance;
}

const BrandItemsSettings: FC<Props> = ({ onClose, form }) => {
  const [itemInfo, setItemInfo] = useState<IBrandItemSettings>();
  const [items, setItems] = useState<IBrandItemSettings[]>(form.getFieldValue('items'));

  const handleSelectItem = useCallback(
    (info: any) => {
      const found = find(items, (i) => i.uid === info.uid);
      setItemInfo(found);
    },
    [form, items],
  );

  const handleImageChange = useCallback(
    (fileList: any) => {
      const newImages: IBrandItemSettings[] = [];

      // Rearrange or upload new file
      fileList.forEach((f: any, idx: number) => {
        const originalImage: any = find(items, { uid: f.uid });
        const updatedImage: IBrandItemSettings = {
          ...f,
          index: idx + 1,
          url: f.url,
          clickUrl: originalImage?.clickUrl || '',
          title: originalImage?.title || '',
        };
        newImages.push(updatedImage);
      });
      const isAddingImage = newImages.length > items.length;

      setItems(newImages);
      form.setFieldsValue({
        items: newImages,
      });

      if (isAddingImage) {
        const originalImageUid = items.map((i) => i.uid);
        setItemInfo(newImages.find((i) => !originalImageUid.includes(i.uid)));
      } else {
        setItemInfo(newImages.find((i) => itemInfo?.uid === i.uid));
      }
    },
    [form, items, itemInfo],
  );

  const handleOnClose = useCallback(() => {
    form
      .validateFields()
      .then(() => onClose && onClose())
      .catch((err) => console.log('err', err));
  }, [form, onClose]);

  const handleClickEditItem = useCallback(
    (curImageUid: string) => {
      return itemInfo?.uid === curImageUid;
    },
    [itemInfo],
  );

  const handleChangeItemField = useCallback(
    (e) => {
      if (itemInfo) {
        const { name, value } = e.target;
        setItems((items) => {
          const newItems = [...items];
          const itemIndex = findIndex(newItems, itemInfo);
          const updatedItem: IBrandItemSettings = {
            ...newItems[itemIndex],
            [name]: value,
          };
          newItems[itemIndex] = updatedItem;
          setItemInfo(updatedItem);
          form.setFieldsValue({ items: newItems });
          return newItems;
        });
      }
    },
    [itemInfo, form],
  );

  return (
    <div>
      <UploadWrapper disabled={items.length >= MAX_ITEMS}>
        <StyledFormItem
          name="items"
          rules={[
            {
              validator: async (data, imagesData) => {
                if (imagesData.length < MIN_ITEMS) {
                  return Promise.reject(new Error(`Yêu cầu upload ít nhất ${MIN_ITEMS} hình ảnh`));
                }
                if (imagesData.every((image: any) => Object.keys(image).includes('title'))) {
                  const itemWithoutTitleIndex = findIndex(
                    imagesData,
                    (item: IBrandItemSettings) => !item.title,
                  );

                  if (itemWithoutTitleIndex > -1) {
                    setItemInfo(imagesData[itemWithoutTitleIndex]);
                    return Promise.reject(
                      new Error(
                        `Tiêu đề là bắt buộc cho hình ảnh thứ "${itemWithoutTitleIndex + 1}"`,
                      ),
                    );
                  }
                }
                if (imagesData.every((image: any) => Object.keys(image).includes('clickUrl'))) {
                  const itemWithoutUrlIndex = findIndex(
                    imagesData,
                    (item: IBrandItemSettings) => !isURL(item.clickUrl),
                  );
                  if (itemWithoutUrlIndex > -1) {
                    setItemInfo(imagesData[itemWithoutUrlIndex]);
                    return Promise.reject(
                      new Error(
                        `Đường dẫn không hợp lệ cho hình ảnh thứ "${itemWithoutUrlIndex + 1}"`,
                      ),
                    );
                  }
                }

                return Promise.resolve();
              },
            },
            {
              validator: async (data, imagesData) => {
                if (imagesData.length > MAX_ITEMS) {
                  return Promise.reject(new Error(`Yêu cầu upload tối đa ${MAX_ITEMS} hình ảnh`));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ImagesUpload
            onChange={handleImageChange}
            maxImage={MAX_ITEMS}
            onPreview={handleSelectItem}
            handleImageSelected={handleClickEditItem}
          />
        </StyledFormItem>
        <Box py="5px" mb="10px">
          <i>(Upload hình có kích thước đề xuất 172x115 với tỉ lệ 3:2, và tất cả hình phải có cùng kích thước)</i>
        </Box>

        {itemInfo && (
          <FileInfoSettingWrapper>
            <Box my="12px">
              <Input
                name="title"
                value={itemInfo.title}
                placeholder="Nhập vào tiêu đề cho hình ảnh"
                onChange={handleChangeItemField}
              />
            </Box>
            <Box my="12px">
              <Input
                placeholder="Nhập vào đường dẫn"
                value={itemInfo.clickUrl}
                name="clickUrl"
                onChange={handleChangeItemField}
              />
            </Box>
            {/* <Space>
            <Button onClick={handleSaveSetting}>Lưu Setting</Button>
          </Space> */}
          </FileInfoSettingWrapper>
        )}
        <ButtonWrapper>
          <Space>
            <Button onClick={handleOnClose}>Đóng</Button>
          </Space>
        </ButtonWrapper>
      </UploadWrapper>
    </div>
  );
};

export default BrandItemsSettings;
