import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { ngonThemesContext } from './constants';
import {
  NgonTheme,
  NgonThemeFormData,
  NgonThemeRequest,
  NgonThemesRequestData,
  NgonThemesResponse,
  NgonThemeState,
} from './typing';

export const { action: getNgonThemesAction, hook: getNgonThemesHook } = createAsyncUseCase<
  NgonThemesRequestData,
  NgonThemesResponse,
  NgonThemeState
>(ngonThemesContext, 'getNgonThemes');

export const { action: getNgonThemeAction, hook: getNgonThemeHook } = createAsyncUseCase<
  NgonThemeRequest,
  { request: NgonThemeRequest; response: NgonTheme },
  NgonThemeState
>(ngonThemesContext, 'getNgonTheme');

export const { action: createNgonThemeAction, hook: createNgonThemeHook } = createAsyncUseCase<
  NgonThemeFormData,
  NgonTheme,
  NgonThemeState
>(ngonThemesContext, 'createNgonThemeAction', {
  messageSettings: { initial: true, success: true },
});

export const { action: updateNgonThemeAction, hook: updateNgonThemeHook } = createAsyncUseCase<
  NgonTheme,
  NgonTheme,
  NgonThemeState
>(ngonThemesContext, 'updateNgonThemeAction', {
  messageSettings: { initial: true, success: true },
});

export const { action: deleteNgonThemeAction, hook: deleteNgonThemeHook } = createAsyncUseCase<
  { id: string | number },
  { id: string | number },
  NgonThemeState
>(ngonThemesContext, 'deleteNgonThemeAction', {
  readableName: 'Xóa theme',
  messageSettings: { initial: true, success: true },
});

export const { action: orderNgonThemeAction, hook: orderNgonThemeHook } = createAsyncUseCase<
  { data: any; request?: {} },
  NgonThemeState
>(ngonThemesContext, 'orderNgonThemeAction', {});

export const { action: getOrderNgonThemeAction, hook: getOrderNgonThemeHook } = createAsyncUseCase<
  { data: []; request: {} },
  { creative: []; promotion: [] },
  any
>(ngonThemesContext, 'getOrderNgonThemeAction', {});

export const {
  action: resetNgonThemeRequestAction,
  hook: resetNgonThemeRequestHook,
} = createUseCase<any>(ngonThemesContext, 'resetRequest');
