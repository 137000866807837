import { NgonQuickLinkFormData } from '@/infras/ngon-quick-link/typing';
import { updateNgonQuickLinkHook } from '@/infras/ngon-quick-link/useCase';
import { getUsersHook } from '@/infras/user/useCase';
import { Form } from 'antd';
import React, { useCallback } from 'react';
import NgonQuickLinkForm from './NgonQuickLinkForm';

interface UpdateFormProps {
  modalVisible: boolean;
  theme: NgonQuickLinkFormData;
  handleVisibleModal: (visible: boolean) => void;
}

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const { modalVisible, handleVisibleModal, theme } = props;
  const {
    state: { currentUser },
  } = getUsersHook();
  const { fetch } = updateNgonQuickLinkHook();

  const [form] = Form.useForm();

  const onSubmit = useCallback(
    (values: any) => {
      return fetch({
        ...theme,
        ...values,
        active: !!theme?.active,
        updatedBy: currentUser!.email,
        imageUrl: values?.imageUrl?.[0] || '',
      });
    },
    [theme, currentUser, fetch],
  );

  return (
    <NgonQuickLinkForm
      title="Chỉnh sửa theme"
      form={form}
      theme={{
        ...theme,
        imageUrl: [theme?.imageUrl],
      }}
      modalVisible={modalVisible}
      handleVisibleModal={handleVisibleModal}
      onSubmit={onSubmit}
      mode="edit"
    />
  );
};

export default UpdateForm;
