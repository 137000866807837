import { ConfigFormData, ConfigRequest } from '@/infras/config/typing';
import { apiRequest } from '@/utils/request';

async function getConfig(req: ConfigRequest): Promise<any> {
  const data: any = await apiRequest(`/configs/${req.id}`, {
  });
  return data;
}

async function createConfig(config: ConfigFormData) {
  const customHandle = true;
  const data = await apiRequest(
    `/configs`,
    {
      method: 'POST',
      data: config,
    },
    customHandle,
  );
  return data;
}

async function updateConfig(config: ConfigFormData) {
  return apiRequest(`/configs/${config.id}`, {
    method: 'PUT',
    data: config?.data,
  });
}

export default { getConfig, createConfig, updateConfig };
