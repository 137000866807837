import dev from './dev';
import uat from './uat';
import prod from './prod';
import test from './test';
import { RuntimeConfig } from './types';

const Configs: Record<typeof REACT_APP_ENV, RuntimeConfig> = {
  dev,
  uat,
  prod,
  test,
};

const Config = Configs[REACT_APP_ENV];

export default Config;
