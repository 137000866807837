import check from '@/components/Authorized/CheckPermissions';
import { NgonQuickLink } from '@/infras/ngon-quick-link/typing';
import { getNgonQuickLinksHook, orderNgonQuickLinkHook } from '@/infras/ngon-quick-link/useCase';
import { PermissionType } from '@/infras/permission/constants';

import { useFetchCurrent } from '@/models/oauth';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent, useCallback } from 'react';

interface StatusCollectionProps {
  theme: NgonQuickLink;
  index: number;
}

const CollectionPageMoveIndex: FunctionComponent<StatusCollectionProps> = ({ theme, index }) => {
  const { state: quickLinkState, fetch } = orderNgonQuickLinkHook();
  const {
    fetch: getNgonQuickLinkPages,
    state: { request },
  } = getNgonQuickLinksHook();
  const {
    state: { currentOauth },
  } = useFetchCurrent();
  const prevNgonQuickLinkId = quickLinkState?.ngonQuickLinkList?.data?.[index - 1];
  const nextNgonQuickLinkId = quickLinkState?.ngonQuickLinkList?.data?.[index + 1];

  const handleOk = useCallback(
    (updatedIndex) => async () => {
      if (updatedIndex > theme?.index) {
        //down
        // const nextNgonQuickLink = widgetCollectionState?.themeMap?.[nextNgonQuickLinkId];
        // if (nextNgonQuickLink) {

        //   await fetch({ ...nextNgonQuickLink, index: nextNgonQuickLink?.index?.toString() === updatedIndex?.toString() ? theme?.index : nextNgonQuickLink?.index, updatedBy: currentOauth.email });
        // }
        await fetch({
          data: { ...theme, index: updatedIndex, updatedBy: currentOauth.email },
          request,
        });
      }
      if (updatedIndex < theme?.index) {
        await fetch({
          data: { ...theme, index: updatedIndex, updatedBy: currentOauth.email },
          request,
        });
      }
      // getNgonQuickLinkPages(request);
    },
    [theme, getNgonQuickLinkPages, request, fetch, currentOauth.email],
  );

  const hasAuthorized = check([PermissionType.ALL, PermissionType.UPDATE_COLLECTION], true, false);

  return (
    <>
      {prevNgonQuickLinkId && (
        <Button
          shape="circle"
          icon={<UpOutlined />}
          onClick={handleOk(theme?.index - 1)}
          disabled={!hasAuthorized}
        />
      )}
      {nextNgonQuickLinkId && (
        <Button
          shape="circle"
          icon={<DownOutlined />}
          style={{ marginLeft: 5 }}
          onClick={handleOk(theme?.index + 1)}
          disabled={!hasAuthorized}
        />
      )}
    </>
  );
};
export default CollectionPageMoveIndex;
