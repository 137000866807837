import React, { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { createDndContext, DndProvider, useDrag, useDrop } from 'react-dnd';
import Box from '@/components/Box';
import { noop } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface DragAndDropProps {
  elementType: string;
  drop?: () => void;
  hover?: (item: any) => void;
  id: string;
}
const DragAndDrop: FunctionComponent<DragAndDropProps> = ({
  children,
  drop: dropProps = noop,
  hover: hoverProps = noop,
  elementType,
  id,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: elementType, id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isOver }, drop] = useDrop({
    accept: elementType,
    drop: dropProps,
    hover: hoverProps,
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
      };
    },
  });
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (widgetRef) {
      drag(widgetRef);
      drop(widgetRef);
    }
  }, [drag, drop, widgetRef]);

  return (
    <Box
      ref={widgetRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      bg={isOver ? 'pink' : 'white'}
      display="flex"
    >
      {children}
    </Box>
  );
};

const RNDContext = createDndContext(HTML5Backend);

export function DragAndDropProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  return <DndProvider manager={RNDContext.dragDropManager as any}>{children}</DndProvider>;
}

export default DragAndDrop;
