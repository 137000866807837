import Config from '@/config';
import Cookies from 'js-cookie';

const TOKEN_KEY = 'brick_token';
const REFRESH_TOKEN_KEY = 'brick_refresh_token';
const CANARY_KEY = 'TIKI_FE_CANARY';

export function saveUserToken(token: string, refreshToken: string) {
  Cookies.set(TOKEN_KEY, token, { domain: Config.BrickAppDomain });
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
    domain: Config.BrickAppDomain,
  });
}

export function getUserToken(): string {
  return Cookies.get(TOKEN_KEY) || '';
}

export function getRefreshToken(): string {
  return Cookies.get(REFRESH_TOKEN_KEY) || '';
}

export function saveNewToken(token: string) {
  Cookies.set(TOKEN_KEY, token, { domain: Config.BrickAppDomain });
}

export function removeUserToken() {
  Cookies.remove(TOKEN_KEY, { domain: Config.BrickAppDomain });
  Cookies.remove(REFRESH_TOKEN_KEY, { domain: Config.BrickAppDomain });
}

export function getCanaryCookie() {
  return Cookies.get(CANARY_KEY);
}

export function getForwardCanary() {
  const canaryCookie = getCanaryCookie();
  return canaryCookie ? `${CANARY_KEY}=${canaryCookie}` : undefined;
}

export default {
  saveUserToken,
  saveNewToken,
  getUserToken,
  getRefreshToken,
  removeUserToken,
  getForwardCanary,
  getCanaryCookie,
};
