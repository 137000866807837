export const ADMIN_LIST = [
  'hieu.chuchi@tiki.vn',
  'hoang.nguyen8@tiki.vn',
  'lam.nguyen5@tiki.vn',
  'quang.pham3@tiki.vn',
  'nghia.hodac@tiki.vn',
  'nhan.hoang2@tiki.vn',
  'hoang.nguyendang@tiki.vn',
];
export const permissionsContext = 'permissions';

export enum PermissionType {
  ALL = 'ALL',
  READ_CAMPAIGN = 'READ_CAMPAIGN',
  UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN',
  READ_COLLECTION = 'READ_COLLECTION',
  UPDATE_COLLECTION = 'UPDATE_COLLECTION',
  READ_CATEGORY = 'READ_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  TOGGLE_CATEGORY = 'TOGGLE_CATEGORY',
  READ_THEME = 'READ_THEME',
  UPDATE_THEME = 'UPDATE_THEME',
  MANAGE_THEME_PERMISSION = 'MANAGE_THEME_PERMISSION',
  MANAGE_CAMPAIGN_PERMISSION = 'MANAGE_CAMPAIGN_PERMISSION',
  MANAGE_COLLECTION_PERMISSION = 'MANAGE_COLLECTION_PERMISSION',
  MANAGE_CATEGORY_PERMISSION = 'MANAGE_CATEGORY_PERMISSION',
}

export const PermissionManageMapping = {
  [PermissionType.MANAGE_CAMPAIGN_PERMISSION]: {
    [PermissionType.MANAGE_CAMPAIGN_PERMISSION]: true,
    [PermissionType.READ_CAMPAIGN]: true,
    [PermissionType.UPDATE_CAMPAIGN]: true,
  },
  [PermissionType.MANAGE_COLLECTION_PERMISSION]: {
    [PermissionType.MANAGE_COLLECTION_PERMISSION]: true,
    [PermissionType.READ_COLLECTION]: true,
    [PermissionType.UPDATE_COLLECTION]: true,
  },
  [PermissionType.MANAGE_CATEGORY_PERMISSION]: {
    [PermissionType.MANAGE_CATEGORY_PERMISSION]: true,
    [PermissionType.READ_CATEGORY]: true,
    [PermissionType.UPDATE_CATEGORY]: true,
    [PermissionType.TOGGLE_CATEGORY]: true,
  },
  [PermissionType.MANAGE_THEME_PERMISSION]: {
    [PermissionType.MANAGE_THEME_PERMISSION]: true,
    [PermissionType.READ_THEME]: true,
    [PermissionType.UPDATE_THEME]: true,
  },
};
