import { createAsyncUseCase, createUseCase } from '@/utils/dva-model-creator/async';
import { permissionsContext } from './constants';
import {
  Permission,
  PermissionFormData,
  PermissionRequest,
  PermissionsRequestData,
  PermissionsResponse,
  PermissionState,
} from './typing';

export const { action: getPermissionsAction, hook: getPermissionsHook } = createAsyncUseCase<
  PermissionsRequestData,
  PermissionsResponse,
  PermissionState
>(permissionsContext, 'getPermissions');

export const { action: getPermissionAction, hook: getPermissionHook } = createAsyncUseCase<
  PermissionRequest,
  { request: PermissionRequest; response: Permission },
  PermissionState
>(permissionsContext, 'getPermission');

export const { action: createPermissionAction, hook: createPermissionHook } = createAsyncUseCase<
  PermissionFormData,
  Permission,
  PermissionState
>(permissionsContext, 'createPermissionAction', {
  readableName: 'Tạo permission mới',
  messageSettings: { initial: true, success: true },
});

export const { action: updatePermissionAction, hook: updatePermissionHook } = createAsyncUseCase<
  Permission,
  Permission,
  PermissionState
>(permissionsContext, 'updatePermissionAction', {
  readableName: 'Lưu thay đổi permission',
  messageSettings: { initial: true, success: true },
});

export const {
  action: resetPermissionRequestAction,
  hook: resetPermissionRequestHook,
} = createUseCase(permissionsContext, 'resetRequest');
