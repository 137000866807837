import { WidgetType } from '@/infras/widget/constants';
import { CategoryCollectionSettings as ICategoryCollectionSettings, ICollectionItemSettings } from './types';
import CollectionSettings from './CollectionSettings';
import CollectionDraggable from './CollectionDraggable';
import CollectionView from "./CollectionView";

const widget = {
  type: WidgetType.CATEGORY_SLIDER,
  View: CollectionView,
  Settings: CollectionSettings,
  Draggable: CollectionDraggable,
  default: {
    type: WidgetType.CATEGORY_SLIDER,
    payload: {
      title: '',
      placement: 'under_sub_category',
      layout: 'grid',
      items: [] as ICollectionItemSettings[],
      showMax: 4
    } as ICategoryCollectionSettings,
  },
};

export default widget;
