import Config from '@/config';
import { Campaign } from '@/infras/campaign/typing';

export const prefixCampaignUrl = `${Config.TikiFrontend}/`;

export const getCampaignUrl = (campaign: Campaign, pageId?: string, preview?: boolean) => {
  if (!campaign?.url) {
    return '';
  }
  return `${prefixCampaignUrl}${campaign?.url}${preview ? `?preview=1&page_id=${pageId}` : ''}`;
};
