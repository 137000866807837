import cookieService from '@/services/cookie';
import { oauthRequest } from '@/utils/request';
import jwtDecode from 'jwt-decode';
import gtagService from './gtag';
import { get } from 'lodash';

export async function postCheckAuthCode(code: string, callbackUrl: string): Promise<any> {
  return oauthRequest(`/token`, {
    method: 'POST',
    data: `redirect_uri=${encodeURIComponent(
      callbackUrl,
    )}&code=${code}&grant_type=authorization_code`,
  });
}

export async function postRefreshToken(callbackUrl: string): Promise<any> {
  const refreshToken = cookieService.getRefreshToken();

  return oauthRequest(`/token`, {
    method: 'POST',
    data: `redirect_uri=${encodeURIComponent(
      callbackUrl,
    )}&refresh_token=${refreshToken}&grant_type=refresh_token`,
  });
}

export async function parseCurrentToken(): Promise<any> {
  const token = cookieService.getUserToken();
  if (!token) throw new Error('Token is empty');
  const userInfo = jwtDecode(token);
  if (Date.now() > userInfo.exp * 1000) {
    const data = await postRefreshToken(window.location.href);
    const userInfo: any = jwtDecode(get(data, 'id_token', ''));

    return userInfo;
  }
  return userInfo;
}

export function logout() {
  cookieService.removeUserToken();
  gtagService.setUserId(null);
  window.location.reload();
}
