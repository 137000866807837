import styled from "styled-components";

export const CategoryCollectionView = styled.div`
  .title {
    font-size: 13px;
    font-weight: 500;
    padding: 4px;
  }
  .sub-title {
    font-size: 12px;
    font-weight: 400;
    padding: 2px;
  }
`

export const SliderWrapper = styled.div`
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  //padding: 0px 12px;
  margin: 0 12px;
  .banner {
    display: flex;
    width: 172px;
    flex-direction: column;
    text-decoration: none;
    color: rgb(36, 36, 36);
    align-items: center;
  }
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`

export const SeeMoreBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d5cb6;
  font-size: 15px;
  line-height: 20px;
  border: none;
  text-align: center;
  background-color: transparent;
  margin-bottom: 8px;
`
export const TitleWrapper = styled.h3`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px;
  margin: 0;
`;
